/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Contact Card

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.contact-card {
    position: relative;

    &:not(:first-of-type) {
        margin-top: 24px;
        @include media-breakpoint-up(lg) {
            margin-top: 48px;
        }

    }

    &--alt {
        margin-top: 20px;
        z-index: 1;
    }

    &.is-fixed {
        
    }

    &__inner {
        background: $white;
        border-radius: 30px;
        box-shadow: 0px 16px 32px 0px rgba($ebony, 0.15);
        padding: 35px 32px 32px;

        @include media-breakpoint-up(lg) {
            padding: 32px 32px 40px 32px;
        }
    }

    &__pin {
        cursor: default;
        position: absolute;
        top: 8px;
        right: 8px;
    }
    
    &__title {
        @include fontSize(16);
        @include lineHeight(26,16);
        margin-bottom: 16px;
        color: $ebony;
        font-weight: 500;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(30,20);
            margin-bottom: 13px;
            padding-right: 40px;
        }
    }
  
    p {
        @include fontSize(14);
        @include lineHeight(24,14);
        margin-bottom: 0px;
        color: $storm-gray;
        font-weight: 400;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(28,16);
        }
    }

    span {
        text-transform: uppercase;
    }

    a {
        color: $navy;

        &:hover {
            color: $ebony;
        }
    }
}