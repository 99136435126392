/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Layout - everything after header and before footer

- BOOTRSTRAP FLUID GRID FIX
- SITE WRAPPER
- HEADER
- FOOTER
- MAIN
- CONTENT HEAD
- CONTENT BODY
- SIDEBAR
- MASTER BLOCK

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/




/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
BOOTRSTRAP FLUID GRID FIX
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
@media (max-width: map-get($grid-breakpoints, "xl")) {
  .container {
    max-width: 100%;
  }
}


/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
SITE WRAPPER
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
.site-wrapper{
	//max-width: 2000px;
	width: 100%;
	margin: 0 auto;
	overflow: hidden;
	padding-top: 74px;

    @include media-breakpoint-up(lg) {
      padding-top: 84px;
    }
}



/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
FLUID CONTAINER OVERRIDES
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
