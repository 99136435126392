/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Process block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

.process-block {
    padding: 21px 0 80px;

    @include media-breakpoint-up (lg) {
        min-height: 590px;
        padding: 0 0 88px;
    }

    &__wrap {
        position: relative;
       
        @include media-breakpoint-up (lg) {
            display: flex;
        }
    }

    &__dial {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 39px;

        @include media-breakpoint-up (md) {
            margin-bottom: 54px;
        }

        @include media-breakpoint-up (lg) {
            display: block;
            width: 50%;
            padding: 35px 0;
            margin-bottom: 0;
        }
    }

    &__dial-content {
        position: relative;
        width: 209px;
        height: 209px;
      
        @include media-breakpoint-up (md) {
            width: 420px;
            height: 420px;
        }

        @include media-breakpoint-up (lg) {
            width: 520px;
            height: 520px;
            margin-left: 0px !important;
        }
    }

    &__dial-inner {
        position: relative;
        width: 209px;
        height: 209px;
        border-radius: 50%;
        border: 1px solid $light-gray; 

        @include media-breakpoint-up (md) {
            width: 420px;
            height: 420px;
        }

        @include media-breakpoint-up (lg) {
            width: 520px;
            height: 520px;
        }
    }

    &__media {
        position: absolute;
        display: block;
        width: 96px;
        height: 96px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up (lg) {
            width: 243px;
            height: 243px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__link {
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        border: 1px solid $light-gray;
        color: $navy;
        background: $white;
        border-radius: 50%;
        text-align: center;
        padding: 7px;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

        @include media-breakpoint-up (md) {
            width: 72px;
            height: 72px;
            padding: 15px;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            @include fontSize(14);
            @include lineHeight(24,14);
            font-weight: 500;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

            @include media-breakpoint-up (md) {
                width: 40px;
                height: 40px;
                @include fontSize(18);
                @include lineHeight(28,18);
            }
        }

        &.current,
        &:hover {
            box-shadow: 0 8px 16px 0 rgba(0, 66, 137, 0.15);
            border-color: $white;
            
            span {
                background: $navy;
                color: $white;
            }
        }

        &:first-child {
            left: 50%;
            top: 0%;
            transform: translate(-50%, -50%);
        }

        &:nth-child(2) {
            top: calc(25% - 44px);
            right: calc(25% - 48px);

            @include media-breakpoint-up (md) {
                top: calc(25% - 72px);
                right: calc(25% - 108px);
            }
        }

        &:nth-child(3) {
            left: 100%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:nth-child(4) {
            top: calc(75% - 0px);
            right: calc(25% - 44px);

            @include media-breakpoint-up (md) {
                right: calc(25% - 94px);
            }
        }

        &:nth-child(5) {
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%);
        }

        &:nth-child(6) {
            top: calc(75% - 0px);
            left: calc(25% - 44px);

            @include media-breakpoint-up (md) {
                left: calc(25% - 94px);
            }
        }

        &:nth-child(7) {
            left: 0%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__inner {
       position: relative;
        
       @include media-breakpoint-up (lg) {
            width:  50%;
            padding-left: 16.667%;
            overflow: hidden;
            max-height: 700px;
            height: auto;
            z-index: -1;
        }
    }

    &__content {
        height: 100%; 
    }

    &__item-content {
        position: relative;
        height: auto;
        min-height: 200px;
        text-align: center;
        display: none;
        opacity: 0;
        transition: all 0.8s cubic-bezier(.12,.71,.78,.6);
        
        @include media-breakpoint-up (lg) {
            text-align: left;
            padding-top: 150px;
            min-height: 590px;
            height: 590px;
        }
        
        &.is-active {
            opacity: 1;
            display: block;
        }
    }

    &__item-index {
        @include fontSize(12);
        @include lineHeight(22,12);
        font-weight: normal;
        color: $storm-gray;
        margin-bottom: 8px;

        @include media-breakpoint-up (lg) {
            position: absolute;
            left: -50px;
            top: 158px;
            @include fontSize(14);
            @include lineHeight(22,14);
            font-weight: 500;
            color: $navy;
        }
    }

    &__title {
        @include fontSize(28);
        @include lineHeight(38,28);
        font-weight: 600;
        color: $ebony;
        margin-bottom: 24px;

        @include media-breakpoint-up (lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
        }
    }

    &__abstract {

        @include media-breakpoint-up (lg) {
            width: 75%;
        }
    }

    &__abstract p {
        @include fontSize(16);
        @include lineHeight(28,16);
        font-weight: normal;
        color: $storm-gray;

        @include media-breakpoint-up (lg) {
            @include fontSize(18);
            @include lineHeight(30,18);
        }
    }

    &__btn-wrap {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 555px;
        right: 0px;
        z-index: 1;
    } 
}


@include media-breakpoint-up (lg) {
    .animate-in-top {
        animation-name: fade-in-top;
        animation-duration: 0.8s;
        animation-timing-function: linear;
    }
    .animate-in-bottom {
        animation-name: fade-in-bottom;
        animation-duration: 0.8s;
        animation-timing-function: linear;
    }
}
                
@keyframes fade-in-top {
	0% {
        transform: translateY(-50%); 
        opacity: 0;
	}
	100% {
        opacity: 1;
        transform: translateY(0%); 
	}
}

@keyframes fade-in-bottom {
	0% {
        transform: translateY(50%); 
        opacity: 0;
	}
	100% {
        transform: translateY(0%); 
        opacity: 1;
	}
}
@keyframes fade-out-top {
	0% {
        transform: translateY(0%); 
	}
	100% {
     
        transform: translateY(-50%); 
	}
}

@keyframes fade-out-bottom {
	0% {
		transform: translateY(0%); 
	}
	100% {
        transform: translateY(50%); 
	}
}




.process-block-v2 {
    padding: 21px 0 80px;

    @include media-breakpoint-up (lg) {
        min-height: 590px;
        padding: 0 0 88px;
        height: 100vh;
    }

    &__wrap {
        position: relative;
        margin-top: 80px;
       
        @include media-breakpoint-up (lg) {
            display: flex;
            margin-top: 120px;
        }
    }

    &__dial {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 39px;

        @include media-breakpoint-up (md) {
            margin-bottom: 54px;
        }

        @include media-breakpoint-up (lg) {
            display: block;
            width: 50%;
            padding: 35px 0;
            margin-bottom: 0;
        }
    }

    &__dial-content {
        position: relative;
        width: 209px;
        height: 209px;
      
        @include media-breakpoint-up (md) {
            width: 420px;
            height: 420px;
        }

        @include media-breakpoint-up (lg) {
            width: 520px;
            height: 520px;
            margin-left: 0px !important;
        }
    }

    &__dial-inner {
        position: relative;
        width: 209px;
        height: 209px;
        border-radius: 50%;
        border: 1px solid $light-gray; 

        @include media-breakpoint-up (md) {
            width: 420px;
            height: 420px;
        }

        @include media-breakpoint-up (lg) {
            width: 520px;
            height: 520px;
        }
    }

    &__media {
        position: absolute;
        display: block;
        width: 96px;
        height: 96px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up (lg) {
            width: 243px;
            height: 243px;
        }
    }

   &__media-item {
    	position: absolute;
    	top: 0;
    	left: 0;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
  		opacity: 0;
  		transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

  		&.current {
  			opacity: 1;
  		}
    }

    &__link {
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        border: 1px solid $light-gray;
        color: $navy;
        background: $white;
        border-radius: 50%;
        text-align: center;
        padding: 7px;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        // opacity: 0;

        @include media-breakpoint-up (md) {
            width: 72px;
            height: 72px;
            padding: 15px;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            @include fontSize(14);
            @include lineHeight(24,14);
            font-weight: 500;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

            @include media-breakpoint-up (md) {
                width: 40px;
                height: 40px;
                @include fontSize(18);
                @include lineHeight(28,18);
            }
        }

        &.current {
            box-shadow: 0 8px 16px 0 rgba(0, 66, 137, 0.15);
            border-color: $white;
            
            span {
                background: $navy;
                color: $white;
            }
        }

        &:first-child {
            left: 50%;
            top: 0%;
            transform: translate(-50%, -50%);
        }

        &:nth-child(2) {
            top: calc(25% - 44px);
            right: calc(25% - 48px);

            @include media-breakpoint-up (md) {
                top: calc(25% - 72px);
                right: calc(25% - 108px);
            }
        }

        &:nth-child(3) {
            left: 100%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &:nth-child(4) {
            top: calc(75% - 0px);
            right: calc(25% - 44px);

            @include media-breakpoint-up (md) {
                right: calc(25% - 94px);
            }
        }

        &:nth-child(5) {
            left: 50%;
            top: 100%;
            transform: translate(-50%, -50%);
        }

        &:nth-child(6) {
            top: calc(75% - 0px);
            left: calc(25% - 44px);

            @include media-breakpoint-up (md) {
                left: calc(25% - 94px);
            }
        }

        &:nth-child(7) {
            left: 0%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__inner {
       position: relative;
        
       @include media-breakpoint-up (lg) {
            width:  50%;
            // padding-left: 16.667%;
            padding-left: 14%;
            overflow: hidden;
            max-height: 700px;
            height: auto;
            z-index: -1;
        }
    }

    &__content {
        height: 100%;
        position: relative;
    }

    &__item-content {
        // position: relative;
        height: auto;
        min-height: 200px;
        text-align: center;
        // display: none;
        opacity: 0;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        
        @include media-breakpoint-up (lg) {
            text-align: left;
            padding-top: 150px;
            min-height: 590px;
            height: 590px;
        }

        &.current {
  			opacity: 1;
  		}
        
        &.is-active {
            opacity: 1;
            // display: block;
        }
    }

    &__item-index {
        @include fontSize(12);
        @include lineHeight(22,12);
        font-weight: normal;
        color: $storm-gray;
        margin-bottom: 8px;

        @include media-breakpoint-up (lg) {
            position: absolute;
            left: -50px;
            top: 158px;
            @include fontSize(14);
            @include lineHeight(22,14);
            font-weight: 500;
            color: $navy;
        }
    }

    &__title {
        @include fontSize(28);
        @include lineHeight(38,28);
        font-weight: 600;
        color: $ebony;
        margin-bottom: 24px;

        @include media-breakpoint-up (lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
        }
    }

    &__abstract {

        @include media-breakpoint-up (lg) {
            // width: 75%;
        }
    }

    &__abstract p {
        @include fontSize(16);
        @include lineHeight(28,16);
        font-weight: normal;
        color: $storm-gray;

        @include media-breakpoint-up (lg) {
            @include fontSize(18);
            @include lineHeight(30,18);
        }
    }

    &__btn-wrap {
        display: flex;
        justify-content: flex-end;
        position: absolute;
        top: 555px;
        right: 0px;
        z-index: 1;
    } 
}