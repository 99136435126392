/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Downlaod block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.download {
    display: block;
    padding: 76px 0 80px;
    background-color: $aqua-haze;
    box-shadow: inset 0 1px 0 0 $light-gray, inset 0 -1px 0 0 $light-gray;

    @include media-breakpoint-up(lg) {
        padding: 120px 0;
    }

    &__label {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 56px;
        }
    }

    &__wrap {
        position: relative;
        width: 100%;
        padding: 0 20px;
    }

    &__navigation {
        top: -4px;
        width: 90px;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__slider-container {
        overflow: visible;
    }
    
    &__inner {
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &__item {
        @include media-breakpoint-up(lg) {
           width: 25%;
           flex: 1 1 200px;
           margin-right: 39px;
          
            &:last-child {
                margin-right: 0;
            }

            &:only-child {
                .download-card__wrap {
                    min-height: unset;
                    padding: 42px 25px 33px;
                }
            }
        }
    }

    &__btn-wrap {
        display: flex;
        justify-content: center;
        margin-top: 40px;
    }
}