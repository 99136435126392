/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Products list

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.products-list {
    position: relative;

    &__heading {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0 40px;

        @include media-breakpoint-up(lg){
            padding: 48px 0 32px;
        }
    }

    &__products-num {
        @include fontSize(12);
        @include lineHeight(22,12);

        @include media-breakpoint-up(lg){
            @include fontSize(14);
            @include lineHeight(24,14);
        }
    }
}