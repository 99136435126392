

$all-hover-svg: (
  name: all-hover-svg,
  x: 0em,
  y: 0em,
  offset-x: 0em,
  offset-y: 0em,
  width: 4.2em,
  height: 3.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$all-svg: (
  name: all-svg,
  x: 0em,
  y: -3.2em,
  offset-x: 0em,
  offset-y: -3.2em,
  width: 4.2em,
  height: 3.1em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$app-store-svg: (
  name: app-store-svg,
  x: 0em,
  y: -6.3em,
  offset-x: 0em,
  offset-y: -6.3em,
  width: 11.4em,
  height: 4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$arrival-hover-svg: (
  name: arrival-hover-svg,
  x: 0em,
  y: -10.3em,
  offset-x: 0em,
  offset-y: -10.3em,
  width: 1.8em,
  height: 1.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$arrival-svg: (
  name: arrival-svg,
  x: 0em,
  y: -12.1em,
  offset-x: 0em,
  offset-y: -12.1em,
  width: 1.8em,
  height: 1.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$arrow-down-small-green-svg: (
  name: arrow-down-small-green-svg,
  x: 0em,
  y: -13.9em,
  offset-x: 0em,
  offset-y: -13.9em,
  width: 0.8em,
  height: 0.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$arrow-down-small-svg: (
  name: arrow-down-small-svg,
  x: 0em,
  y: -14.3em,
  offset-x: 0em,
  offset-y: -14.3em,
  width: 0.8em,
  height: 0.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$arrow-down-svg: (
  name: arrow-down-svg,
  x: 0em,
  y: -14.7em,
  offset-x: 0em,
  offset-y: -14.7em,
  width: 1.4em,
  height: 0.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$arrow-up-svg: (
  name: arrow-up-svg,
  x: 0em,
  y: -15.3em,
  offset-x: 0em,
  offset-y: -15.3em,
  width: 1.4em,
  height: 0.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$best-price-guarantee-svg: (
  name: best-price-guarantee-svg,
  x: 0em,
  y: -15.9em,
  offset-x: 0em,
  offset-y: -15.9em,
  width: 13.4em,
  height: 13.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$best-price-lg-svg: (
  name: best-price-lg-svg,
  x: 0em,
  y: -29.3em,
  offset-x: 0em,
  offset-y: -29.3em,
  width: 14em,
  height: 14em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$best-price-svg: (
  name: best-price-svg,
  x: 0em,
  y: -43.3em,
  offset-x: 0em,
  offset-y: -43.3em,
  width: 2em,
  height: 2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$calendar-svg: (
  name: calendar-svg,
  x: 0em,
  y: -45.3em,
  offset-x: 0em,
  offset-y: -45.3em,
  width: 0.9em,
  height: 0.9em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$call-us-svg: (
  name: call-us-svg,
  x: 0em,
  y: -46.2em,
  offset-x: 0em,
  offset-y: -46.2em,
  width: 2.1em,
  height: 2.1em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$checkbox-svg: (
  name: checkbox-svg,
  x: 0em,
  y: -48.3em,
  offset-x: 0em,
  offset-y: -48.3em,
  width: 1.5em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$checkbox-white-svg: (
  name: checkbox-white-svg,
  x: 0em,
  y: -49.5em,
  offset-x: 0em,
  offset-y: -49.5em,
  width: 2.3em,
  height: 1.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$close-svg: (
  name: close-svg,
  x: 0em,
  y: -51.3em,
  offset-x: 0em,
  offset-y: -51.3em,
  width: 1.2em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$cookie-policy-svg: (
  name: cookie-policy-svg,
  x: 0em,
  y: -52.5em,
  offset-x: 0em,
  offset-y: -52.5em,
  width: 3.2em,
  height: 3.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$datepicker-next-svg: (
  name: datepicker-next-svg,
  x: 0em,
  y: -55.7em,
  offset-x: 0em,
  offset-y: -55.7em,
  width: 0.6em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$datepicker-prev-svg: (
  name: datepicker-prev-svg,
  x: 0em,
  y: -56.9em,
  offset-x: 0em,
  offset-y: -56.9em,
  width: 0.6em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$departure-hover-svg: (
  name: departure-hover-svg,
  x: 0em,
  y: -58.1em,
  offset-x: 0em,
  offset-y: -58.1em,
  width: 1.8em,
  height: 1.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$departure-svg: (
  name: departure-svg,
  x: 0em,
  y: -59.9em,
  offset-x: 0em,
  offset-y: -59.9em,
  width: 1.8em,
  height: 1.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$earth-green-svg: (
  name: earth-green-svg,
  x: 0em,
  y: -61.7em,
  offset-x: 0em,
  offset-y: -61.7em,
  width: 1.8em,
  height: 1.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$earth-svg: (
  name: earth-svg,
  x: 0em,
  y: -63.5em,
  offset-x: 0em,
  offset-y: -63.5em,
  width: 1.8em,
  height: 1.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$exel-svg: (
  name: exel-svg,
  x: 0em,
  y: -65.3em,
  offset-x: 0em,
  offset-y: -65.3em,
  width: 1.2em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$facebook-svg: (
  name: facebook-svg,
  x: 0em,
  y: -66.7em,
  offset-x: 0em,
  offset-y: -66.7em,
  width: 1.2em,
  height: 2.1em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-arena-logo-svg: (
  name: foot-arena-logo-svg,
  x: 0em,
  y: -68.8em,
  offset-x: 0em,
  offset-y: -68.8em,
  width: 21em,
  height: 9em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-ac-svg: (
  name: foot-ico-ac-svg,
  x: 0em,
  y: -77.8em,
  offset-x: 0em,
  offset-y: -77.8em,
  width: 17.5em,
  height: 2.9em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-artotel-svg: (
  name: foot-ico-artotel-svg,
  x: 0em,
  y: -80.7em,
  offset-x: 0em,
  offset-y: -80.7em,
  width: 10.9em,
  height: 2.9em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-at-svg: (
  name: foot-ico-at-svg,
  x: 0em,
  y: -83.6em,
  offset-x: 0em,
  offset-y: -83.6em,
  width: 16.6em,
  height: 2.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-facebook-svg: (
  name: foot-ico-facebook-svg,
  x: 0em,
  y: -86.4em,
  offset-x: 0em,
  offset-y: -86.4em,
  width: 3.3em,
  height: 6.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-flickr-svg: (
  name: foot-ico-flickr-svg,
  x: 0em,
  y: -92.9em,
  offset-x: 0em,
  offset-y: -92.9em,
  width: 3.2em,
  height: 6.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-hrva-svg: (
  name: foot-ico-hrva-svg,
  x: 0em,
  y: -99.4em,
  offset-x: 0em,
  offset-y: -99.4em,
  width: 16.4em,
  height: 5.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-instagram-svg: (
  name: foot-ico-instagram-svg,
  x: 0em,
  y: -104.8em,
  offset-x: 0em,
  offset-y: -104.8em,
  width: 3.2em,
  height: 6.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-istrien-svg: (
  name: foot-ico-istrien-svg,
  x: 0em,
  y: -111.3em,
  offset-x: 0em,
  offset-y: -111.3em,
  width: 7.5em,
  height: 5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-linkedin-svg: (
  name: foot-ico-linkedin-svg,
  x: 0em,
  y: -116.3em,
  offset-x: 0em,
  offset-y: -116.3em,
  width: 3.2em,
  height: 6.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-parkplaza-svg: (
  name: foot-ico-parkplaza-svg,
  x: 0em,
  y: -122.8em,
  offset-x: 0em,
  offset-y: -122.8em,
  width: 9.2em,
  height: 7.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-pphe-svg: (
  name: foot-ico-pphe-svg,
  x: 0em,
  y: -130em,
  offset-x: 0em,
  offset-y: -130em,
  width: 13.4em,
  height: 5.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-twitter-svg: (
  name: foot-ico-twitter-svg,
  x: 0em,
  y: -135.4em,
  offset-x: 0em,
  offset-y: -135.4em,
  width: 3.2em,
  height: 6.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-ico-youtube-svg: (
  name: foot-ico-youtube-svg,
  x: 0em,
  y: -141.9em,
  offset-x: 0em,
  offset-y: -141.9em,
  width: 3.2em,
  height: 6.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$foot-logo-svg: (
  name: foot-logo-svg,
  x: 0em,
  y: -148.4em,
  offset-x: 0em,
  offset-y: -148.4em,
  width: 4.2em,
  height: 2.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$g-pin-1-svg: (
  name: g-pin-1-svg,
  x: 0em,
  y: -151.2em,
  offset-x: 0em,
  offset-y: -151.2em,
  width: 1.2em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$g-pin-1-xs-svg: (
  name: g-pin-1-xs-svg,
  x: 0em,
  y: -152.8em,
  offset-x: 0em,
  offset-y: -152.8em,
  width: 0.9em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$g-pin-2-svg: (
  name: g-pin-2-svg,
  x: 0em,
  y: -154em,
  offset-x: 0em,
  offset-y: -154em,
  width: 1.2em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$g-pin-2-xs-svg: (
  name: g-pin-2-xs-svg,
  x: 0em,
  y: -155.6em,
  offset-x: 0em,
  offset-y: -155.6em,
  width: 0.9em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$gall-svg: (
  name: gall-svg,
  x: 0em,
  y: -156.8em,
  offset-x: 0em,
  offset-y: -156.8em,
  width: 1.6em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$gallery-button-svg: (
  name: gallery-button-svg,
  x: 0em,
  y: -158.4em,
  offset-x: 0em,
  offset-y: -158.4em,
  width: 1.6em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$gallery-name-ico-svg: (
  name: gallery-name-ico-svg,
  x: 0em,
  y: -160em,
  offset-x: 0em,
  offset-y: -160em,
  width: 4.8em,
  height: 4.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$google-play-svg: (
  name: google-play-svg,
  x: 0em,
  y: -164.8em,
  offset-x: 0em,
  offset-y: -164.8em,
  width: 11.4em,
  height: 4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$google-plus-svg: (
  name: google-plus-svg,
  x: 0em,
  y: -168.8em,
  offset-x: 0em,
  offset-y: -168.8em,
  width: 2.2em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$head-logo-small-svg: (
  name: head-logo-small-svg,
  x: 0em,
  y: -170.4em,
  offset-x: 0em,
  offset-y: -170.4em,
  width: 4.2em,
  height: 2.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$head-logo-svg: (
  name: head-logo-svg,
  x: 0em,
  y: -173.2em,
  offset-x: 0em,
  offset-y: -173.2em,
  width: 16.6em,
  height: 2.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$jpg-svg: (
  name: jpg-svg,
  x: 0em,
  y: -176em,
  offset-x: 0em,
  offset-y: -176em,
  width: 1.2em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$kalendar-next-svg: (
  name: kalendar-next-svg,
  x: 0em,
  y: -177.4em,
  offset-x: 0em,
  offset-y: -177.4em,
  width: 2.8em,
  height: 2.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$kalendar-prev-svg: (
  name: kalendar-prev-svg,
  x: 0em,
  y: -180.2em,
  offset-x: 0em,
  offset-y: -180.2em,
  width: 2.8em,
  height: 2.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$location-svg: (
  name: location-svg,
  x: 0em,
  y: -183em,
  offset-x: 0em,
  offset-y: -183em,
  width: 0.9em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$mail-svg: (
  name: mail-svg,
  x: 0em,
  y: -184.2em,
  offset-x: 0em,
  offset-y: -184.2em,
  width: 1.6em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$map-svg: (
  name: map-svg,
  x: 0em,
  y: -185.4em,
  offset-x: 0em,
  offset-y: -185.4em,
  width: 1.8em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$marker-svg: (
  name: marker-svg,
  x: 0em,
  y: -187em,
  offset-x: 0em,
  offset-y: -187em,
  width: 1.6em,
  height: 2.1em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$mm-camp-svg: (
  name: mm-camp-svg,
  x: 0em,
  y: -189.1em,
  offset-x: 0em,
  offset-y: -189.1em,
  width: 1.8em,
  height: 2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$mobile-nav-more-svg: (
  name: mobile-nav-more-svg,
  x: 0em,
  y: -191.1em,
  offset-x: 0em,
  offset-y: -191.1em,
  width: 0.8em,
  height: 1em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$mobile-phones-svg: (
  name: mobile-phones-svg,
  x: 0em,
  y: -192.1em,
  offset-x: 0em,
  offset-y: -192.1em,
  width: 3.8em,
  height: 3.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$nivas-eagle-svg: (
  name: nivas-eagle-svg,
  x: 0em,
  y: -195.5em,
  offset-x: 0em,
  offset-y: -195.5em,
  width: 21.8em,
  height: 25em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$nivas-ico-hover-svg: (
  name: nivas-ico-hover-svg,
  x: 0em,
  y: -220.5em,
  offset-x: 0em,
  offset-y: -220.5em,
  width: 2em,
  height: 2.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$nivas-ico-svg: (
  name: nivas-ico-svg,
  x: 0em,
  y: -223em,
  offset-x: 0em,
  offset-y: -223em,
  width: 2em,
  height: 2.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$pdf-svg: (
  name: pdf-svg,
  x: 0em,
  y: -225.5em,
  offset-x: 0em,
  offset-y: -225.5em,
  width: 1.2em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$photoswipe-close-svg: (
  name: photoswipe-close-svg,
  x: 0em,
  y: -226.9em,
  offset-x: 0em,
  offset-y: -226.9em,
  width: 4.5em,
  height: 4.5em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$photoswipe-next-svg: (
  name: photoswipe-next-svg,
  x: 0em,
  y: -231.4em,
  offset-x: 0em,
  offset-y: -231.4em,
  width: 2.6em,
  height: 6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$photoswipe-prev-svg: (
  name: photoswipe-prev-svg,
  x: 0em,
  y: -237.4em,
  offset-x: 0em,
  offset-y: -237.4em,
  width: 2.6em,
  height: 6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$pin-green-svg: (
  name: pin-green-svg,
  x: 0em,
  y: -243.4em,
  offset-x: 0em,
  offset-y: -243.4em,
  width: 0.8em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$print-svg: (
  name: print-svg,
  x: 0em,
  y: -244.6em,
  offset-x: 0em,
  offset-y: -244.6em,
  width: 1.6em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$promo-code-svg: (
  name: promo-code-svg,
  x: 0em,
  y: -246.2em,
  offset-x: 0em,
  offset-y: -246.2em,
  width: 2em,
  height: 2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$scroll-cta-svg: (
  name: scroll-cta-svg,
  x: 0em,
  y: -248.2em,
  offset-x: 0em,
  offset-y: -248.2em,
  width: 2.6em,
  height: 1.2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$secure-lg-svg: (
  name: secure-lg-svg,
  x: 0em,
  y: -249.4em,
  offset-x: 0em,
  offset-y: -249.4em,
  width: 14em,
  height: 14em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$secure-svg: (
  name: secure-svg,
  x: 0em,
  y: -263.4em,
  offset-x: 0em,
  offset-y: -263.4em,
  width: 2em,
  height: 2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$slider-next-big-svg: (
  name: slider-next-big-svg,
  x: 0em,
  y: -265.4em,
  offset-x: 0em,
  offset-y: -265.4em,
  width: 3.2em,
  height: 5.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$slider-next-svg: (
  name: slider-next-svg,
  x: 0em,
  y: -271.2em,
  offset-x: 0em,
  offset-y: -271.2em,
  width: 1em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$slider-prev-big-svg: (
  name: slider-prev-big-svg,
  x: 0em,
  y: -272.6em,
  offset-x: 0em,
  offset-y: -272.6em,
  width: 3.2em,
  height: 5.8em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$slider-prev-svg: (
  name: slider-prev-svg,
  x: 0em,
  y: -278.4em,
  offset-x: 0em,
  offset-y: -278.4em,
  width: 1em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$time-2-svg: (
  name: time-2-svg,
  x: 0em,
  y: -279.8em,
  offset-x: 0em,
  offset-y: -279.8em,
  width: 0.9em,
  height: 0.9em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$time-svg: (
  name: time-svg,
  x: 0em,
  y: -280.7em,
  offset-x: 0em,
  offset-y: -280.7em,
  width: 0.9em,
  height: 0.9em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$twitter-svg: (
  name: twitter-svg,
  x: 0em,
  y: -281.6em,
  offset-x: 0em,
  offset-y: -281.6em,
  width: 2em,
  height: 1.6em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$video-button-svg: (
  name: video-button-svg,
  x: 0em,
  y: -283.2em,
  offset-x: 0em,
  offset-y: -283.2em,
  width: 1.2em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$why-info-lg-svg: (
  name: why-info-lg-svg,
  x: 0em,
  y: -284.6em,
  offset-x: 0em,
  offset-y: -284.6em,
  width: 14em,
  height: 14em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$why-info-svg: (
  name: why-info-svg,
  x: 0em,
  y: -298.6em,
  offset-x: 0em,
  offset-y: -298.6em,
  width: 2em,
  height: 2em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$word-svg: (
  name: word-svg,
  x: 0em,
  y: -300.6em,
  offset-x: 0em,
  offset-y: -300.6em,
  width: 1.2em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);


$zip-svg: (
  name: zip-svg,
  x: 0em,
  y: -302em,
  offset-x: 0em,
  offset-y: -302em,
  width: 1.2em,
  height: 1.4em,
  //total-width: 502px,
  //total-height: 446px,
  svg: '/css/scss/svg/svg-sprite.svg'
);





@mixin svg-sprite-width($svgsprite) {
width: map-get($svgsprite, 'width');
}

@mixin svg-sprite-height($svgsprite) {
height: map-get($svgsprite, 'height');
}

@mixin svg-sprite-position($svgsprite) {
background-position: map-get($svgsprite, 'offset-x') map-get($svgsprite, 'offset-y');
}

@mixin svg-sprite-image($svgsprite) {
background-image: url(map-get($svgsprite, 'svg'));
}

@mixin svg-sprite($svgsprite) {
font-size: 10px;
@include svg-sprite-image($svgsprite);
@include svg-sprite-position($svgsprite);
@include svg-sprite-width($svgsprite);
@include svg-sprite-height($svgsprite);
}






/*
// .icon {
//     font-size: 10px;
//     &:before {
//       content:' ';
//       vertical-align:middle;
//       display: inline-block;
//       background-image: url("../scss/svg/svg-sprite.svg");
//       background-repeat: no-repeat;
//       background-size: 21.8em 303.4em;

//       .no-svg & {
//         background-image: url("../svg-sprite.png");
//       }
//     }
// }


// 

// .icon.all-hover-svg {
//   &:before {
//       background-position: 0em 0em;
//       width: 4.2em;
//       height: 3.2em;
//     }
//   }

//   @mixin icon-all-hover-svg(){
//     @extend .all-hover-svg;
//   }
// 

// .icon.all-svg {
//   &:before {
//       background-position: 0em -3.2em;
//       width: 4.2em;
//       height: 3.1em;
//     }
//   }

//   @mixin icon-all-svg(){
//     @extend .all-svg;
//   }
// 

// .icon.app-store-svg {
//   &:before {
//       background-position: 0em -6.3em;
//       width: 11.4em;
//       height: 4em;
//     }
//   }

//   @mixin icon-app-store-svg(){
//     @extend .app-store-svg;
//   }
// 

// .icon.arrival-hover-svg {
//   &:before {
//       background-position: 0em -10.3em;
//       width: 1.8em;
//       height: 1.8em;
//     }
//   }

//   @mixin icon-arrival-hover-svg(){
//     @extend .arrival-hover-svg;
//   }
// 

// .icon.arrival-svg {
//   &:before {
//       background-position: 0em -12.1em;
//       width: 1.8em;
//       height: 1.8em;
//     }
//   }

//   @mixin icon-arrival-svg(){
//     @extend .arrival-svg;
//   }
// 

// .icon.arrow-down-small-green-svg {
//   &:before {
//       background-position: 0em -13.9em;
//       width: 0.8em;
//       height: 0.4em;
//     }
//   }

//   @mixin icon-arrow-down-small-green-svg(){
//     @extend .arrow-down-small-green-svg;
//   }
// 

// .icon.arrow-down-small-svg {
//   &:before {
//       background-position: 0em -14.3em;
//       width: 0.8em;
//       height: 0.4em;
//     }
//   }

//   @mixin icon-arrow-down-small-svg(){
//     @extend .arrow-down-small-svg;
//   }
// 

// .icon.arrow-down-svg {
//   &:before {
//       background-position: 0em -14.7em;
//       width: 1.4em;
//       height: 0.6em;
//     }
//   }

//   @mixin icon-arrow-down-svg(){
//     @extend .arrow-down-svg;
//   }
// 

// .icon.arrow-up-svg {
//   &:before {
//       background-position: 0em -15.3em;
//       width: 1.4em;
//       height: 0.6em;
//     }
//   }

//   @mixin icon-arrow-up-svg(){
//     @extend .arrow-up-svg;
//   }
// 

// .icon.best-price-guarantee-svg {
//   &:before {
//       background-position: 0em -15.9em;
//       width: 13.4em;
//       height: 13.4em;
//     }
//   }

//   @mixin icon-best-price-guarantee-svg(){
//     @extend .best-price-guarantee-svg;
//   }
// 

// .icon.best-price-lg-svg {
//   &:before {
//       background-position: 0em -29.3em;
//       width: 14em;
//       height: 14em;
//     }
//   }

//   @mixin icon-best-price-lg-svg(){
//     @extend .best-price-lg-svg;
//   }
// 

// .icon.best-price-svg {
//   &:before {
//       background-position: 0em -43.3em;
//       width: 2em;
//       height: 2em;
//     }
//   }

//   @mixin icon-best-price-svg(){
//     @extend .best-price-svg;
//   }
// 

// .icon.calendar-svg {
//   &:before {
//       background-position: 0em -45.3em;
//       width: 0.9em;
//       height: 0.9em;
//     }
//   }

//   @mixin icon-calendar-svg(){
//     @extend .calendar-svg;
//   }
// 

// .icon.call-us-svg {
//   &:before {
//       background-position: 0em -46.2em;
//       width: 2.1em;
//       height: 2.1em;
//     }
//   }

//   @mixin icon-call-us-svg(){
//     @extend .call-us-svg;
//   }
// 

// .icon.checkbox-svg {
//   &:before {
//       background-position: 0em -48.3em;
//       width: 1.5em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-checkbox-svg(){
//     @extend .checkbox-svg;
//   }
// 

// .icon.checkbox-white-svg {
//   &:before {
//       background-position: 0em -49.5em;
//       width: 2.3em;
//       height: 1.8em;
//     }
//   }

//   @mixin icon-checkbox-white-svg(){
//     @extend .checkbox-white-svg;
//   }
// 

// .icon.close-svg {
//   &:before {
//       background-position: 0em -51.3em;
//       width: 1.2em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-close-svg(){
//     @extend .close-svg;
//   }
// 

// .icon.cookie-policy-svg {
//   &:before {
//       background-position: 0em -52.5em;
//       width: 3.2em;
//       height: 3.2em;
//     }
//   }

//   @mixin icon-cookie-policy-svg(){
//     @extend .cookie-policy-svg;
//   }
// 

// .icon.datepicker-next-svg {
//   &:before {
//       background-position: 0em -55.7em;
//       width: 0.6em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-datepicker-next-svg(){
//     @extend .datepicker-next-svg;
//   }
// 

// .icon.datepicker-prev-svg {
//   &:before {
//       background-position: 0em -56.9em;
//       width: 0.6em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-datepicker-prev-svg(){
//     @extend .datepicker-prev-svg;
//   }
// 

// .icon.departure-hover-svg {
//   &:before {
//       background-position: 0em -58.1em;
//       width: 1.8em;
//       height: 1.8em;
//     }
//   }

//   @mixin icon-departure-hover-svg(){
//     @extend .departure-hover-svg;
//   }
// 

// .icon.departure-svg {
//   &:before {
//       background-position: 0em -59.9em;
//       width: 1.8em;
//       height: 1.8em;
//     }
//   }

//   @mixin icon-departure-svg(){
//     @extend .departure-svg;
//   }
// 

// .icon.earth-green-svg {
//   &:before {
//       background-position: 0em -61.7em;
//       width: 1.8em;
//       height: 1.8em;
//     }
//   }

//   @mixin icon-earth-green-svg(){
//     @extend .earth-green-svg;
//   }
// 

// .icon.earth-svg {
//   &:before {
//       background-position: 0em -63.5em;
//       width: 1.8em;
//       height: 1.8em;
//     }
//   }

//   @mixin icon-earth-svg(){
//     @extend .earth-svg;
//   }
// 

// .icon.exel-svg {
//   &:before {
//       background-position: 0em -65.3em;
//       width: 1.2em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-exel-svg(){
//     @extend .exel-svg;
//   }
// 

// .icon.facebook-svg {
//   &:before {
//       background-position: 0em -66.7em;
//       width: 1.2em;
//       height: 2.1em;
//     }
//   }

//   @mixin icon-facebook-svg(){
//     @extend .facebook-svg;
//   }
// 

// .icon.foot-arena-logo-svg {
//   &:before {
//       background-position: 0em -68.8em;
//       width: 21em;
//       height: 9em;
//     }
//   }

//   @mixin icon-foot-arena-logo-svg(){
//     @extend .foot-arena-logo-svg;
//   }
// 

// .icon.foot-ico-ac-svg {
//   &:before {
//       background-position: 0em -77.8em;
//       width: 17.5em;
//       height: 2.9em;
//     }
//   }

//   @mixin icon-foot-ico-ac-svg(){
//     @extend .foot-ico-ac-svg;
//   }
// 

// .icon.foot-ico-artotel-svg {
//   &:before {
//       background-position: 0em -80.7em;
//       width: 10.9em;
//       height: 2.9em;
//     }
//   }

//   @mixin icon-foot-ico-artotel-svg(){
//     @extend .foot-ico-artotel-svg;
//   }
// 

// .icon.foot-ico-at-svg {
//   &:before {
//       background-position: 0em -83.6em;
//       width: 16.6em;
//       height: 2.8em;
//     }
//   }

//   @mixin icon-foot-ico-at-svg(){
//     @extend .foot-ico-at-svg;
//   }
// 

// .icon.foot-ico-facebook-svg {
//   &:before {
//       background-position: 0em -86.4em;
//       width: 3.3em;
//       height: 6.5em;
//     }
//   }

//   @mixin icon-foot-ico-facebook-svg(){
//     @extend .foot-ico-facebook-svg;
//   }
// 

// .icon.foot-ico-flickr-svg {
//   &:before {
//       background-position: 0em -92.9em;
//       width: 3.2em;
//       height: 6.5em;
//     }
//   }

//   @mixin icon-foot-ico-flickr-svg(){
//     @extend .foot-ico-flickr-svg;
//   }
// 

// .icon.foot-ico-hrva-svg {
//   &:before {
//       background-position: 0em -99.4em;
//       width: 16.4em;
//       height: 5.4em;
//     }
//   }

//   @mixin icon-foot-ico-hrva-svg(){
//     @extend .foot-ico-hrva-svg;
//   }
// 

// .icon.foot-ico-instagram-svg {
//   &:before {
//       background-position: 0em -104.8em;
//       width: 3.2em;
//       height: 6.5em;
//     }
//   }

//   @mixin icon-foot-ico-instagram-svg(){
//     @extend .foot-ico-instagram-svg;
//   }
// 

// .icon.foot-ico-istrien-svg {
//   &:before {
//       background-position: 0em -111.3em;
//       width: 7.5em;
//       height: 5em;
//     }
//   }

//   @mixin icon-foot-ico-istrien-svg(){
//     @extend .foot-ico-istrien-svg;
//   }
// 

// .icon.foot-ico-linkedin-svg {
//   &:before {
//       background-position: 0em -116.3em;
//       width: 3.2em;
//       height: 6.5em;
//     }
//   }

//   @mixin icon-foot-ico-linkedin-svg(){
//     @extend .foot-ico-linkedin-svg;
//   }
// 

// .icon.foot-ico-parkplaza-svg {
//   &:before {
//       background-position: 0em -122.8em;
//       width: 9.2em;
//       height: 7.2em;
//     }
//   }

//   @mixin icon-foot-ico-parkplaza-svg(){
//     @extend .foot-ico-parkplaza-svg;
//   }
// 

// .icon.foot-ico-pphe-svg {
//   &:before {
//       background-position: 0em -130em;
//       width: 13.4em;
//       height: 5.4em;
//     }
//   }

//   @mixin icon-foot-ico-pphe-svg(){
//     @extend .foot-ico-pphe-svg;
//   }
// 

// .icon.foot-ico-twitter-svg {
//   &:before {
//       background-position: 0em -135.4em;
//       width: 3.2em;
//       height: 6.5em;
//     }
//   }

//   @mixin icon-foot-ico-twitter-svg(){
//     @extend .foot-ico-twitter-svg;
//   }
// 

// .icon.foot-ico-youtube-svg {
//   &:before {
//       background-position: 0em -141.9em;
//       width: 3.2em;
//       height: 6.5em;
//     }
//   }

//   @mixin icon-foot-ico-youtube-svg(){
//     @extend .foot-ico-youtube-svg;
//   }
// 

// .icon.foot-logo-svg {
//   &:before {
//       background-position: 0em -148.4em;
//       width: 4.2em;
//       height: 2.8em;
//     }
//   }

//   @mixin icon-foot-logo-svg(){
//     @extend .foot-logo-svg;
//   }
// 

// .icon.g-pin-1-svg {
//   &:before {
//       background-position: 0em -151.2em;
//       width: 1.2em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-g-pin-1-svg(){
//     @extend .g-pin-1-svg;
//   }
// 

// .icon.g-pin-1-xs-svg {
//   &:before {
//       background-position: 0em -152.8em;
//       width: 0.9em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-g-pin-1-xs-svg(){
//     @extend .g-pin-1-xs-svg;
//   }
// 

// .icon.g-pin-2-svg {
//   &:before {
//       background-position: 0em -154em;
//       width: 1.2em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-g-pin-2-svg(){
//     @extend .g-pin-2-svg;
//   }
// 

// .icon.g-pin-2-xs-svg {
//   &:before {
//       background-position: 0em -155.6em;
//       width: 0.9em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-g-pin-2-xs-svg(){
//     @extend .g-pin-2-xs-svg;
//   }
// 

// .icon.gall-svg {
//   &:before {
//       background-position: 0em -156.8em;
//       width: 1.6em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-gall-svg(){
//     @extend .gall-svg;
//   }
// 

// .icon.gallery-button-svg {
//   &:before {
//       background-position: 0em -158.4em;
//       width: 1.6em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-gallery-button-svg(){
//     @extend .gallery-button-svg;
//   }
// 

// .icon.gallery-name-ico-svg {
//   &:before {
//       background-position: 0em -160em;
//       width: 4.8em;
//       height: 4.8em;
//     }
//   }

//   @mixin icon-gallery-name-ico-svg(){
//     @extend .gallery-name-ico-svg;
//   }
// 

// .icon.google-play-svg {
//   &:before {
//       background-position: 0em -164.8em;
//       width: 11.4em;
//       height: 4em;
//     }
//   }

//   @mixin icon-google-play-svg(){
//     @extend .google-play-svg;
//   }
// 

// .icon.google-plus-svg {
//   &:before {
//       background-position: 0em -168.8em;
//       width: 2.2em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-google-plus-svg(){
//     @extend .google-plus-svg;
//   }
// 

// .icon.head-logo-small-svg {
//   &:before {
//       background-position: 0em -170.4em;
//       width: 4.2em;
//       height: 2.8em;
//     }
//   }

//   @mixin icon-head-logo-small-svg(){
//     @extend .head-logo-small-svg;
//   }
// 

// .icon.head-logo-svg {
//   &:before {
//       background-position: 0em -173.2em;
//       width: 16.6em;
//       height: 2.8em;
//     }
//   }

//   @mixin icon-head-logo-svg(){
//     @extend .head-logo-svg;
//   }
// 

// .icon.jpg-svg {
//   &:before {
//       background-position: 0em -176em;
//       width: 1.2em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-jpg-svg(){
//     @extend .jpg-svg;
//   }
// 

// .icon.kalendar-next-svg {
//   &:before {
//       background-position: 0em -177.4em;
//       width: 2.8em;
//       height: 2.8em;
//     }
//   }

//   @mixin icon-kalendar-next-svg(){
//     @extend .kalendar-next-svg;
//   }
// 

// .icon.kalendar-prev-svg {
//   &:before {
//       background-position: 0em -180.2em;
//       width: 2.8em;
//       height: 2.8em;
//     }
//   }

//   @mixin icon-kalendar-prev-svg(){
//     @extend .kalendar-prev-svg;
//   }
// 

// .icon.location-svg {
//   &:before {
//       background-position: 0em -183em;
//       width: 0.9em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-location-svg(){
//     @extend .location-svg;
//   }
// 

// .icon.mail-svg {
//   &:before {
//       background-position: 0em -184.2em;
//       width: 1.6em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-mail-svg(){
//     @extend .mail-svg;
//   }
// 

// .icon.map-svg {
//   &:before {
//       background-position: 0em -185.4em;
//       width: 1.8em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-map-svg(){
//     @extend .map-svg;
//   }
// 

// .icon.marker-svg {
//   &:before {
//       background-position: 0em -187em;
//       width: 1.6em;
//       height: 2.1em;
//     }
//   }

//   @mixin icon-marker-svg(){
//     @extend .marker-svg;
//   }
// 

// .icon.mm-camp-svg {
//   &:before {
//       background-position: 0em -189.1em;
//       width: 1.8em;
//       height: 2em;
//     }
//   }

//   @mixin icon-mm-camp-svg(){
//     @extend .mm-camp-svg;
//   }
// 

// .icon.mobile-nav-more-svg {
//   &:before {
//       background-position: 0em -191.1em;
//       width: 0.8em;
//       height: 1em;
//     }
//   }

//   @mixin icon-mobile-nav-more-svg(){
//     @extend .mobile-nav-more-svg;
//   }
// 

// .icon.mobile-phones-svg {
//   &:before {
//       background-position: 0em -192.1em;
//       width: 3.8em;
//       height: 3.4em;
//     }
//   }

//   @mixin icon-mobile-phones-svg(){
//     @extend .mobile-phones-svg;
//   }
// 

// .icon.nivas-eagle-svg {
//   &:before {
//       background-position: 0em -195.5em;
//       width: 21.8em;
//       height: 25em;
//     }
//   }

//   @mixin icon-nivas-eagle-svg(){
//     @extend .nivas-eagle-svg;
//   }
// 

// .icon.nivas-ico-hover-svg {
//   &:before {
//       background-position: 0em -220.5em;
//       width: 2em;
//       height: 2.5em;
//     }
//   }

//   @mixin icon-nivas-ico-hover-svg(){
//     @extend .nivas-ico-hover-svg;
//   }
// 

// .icon.nivas-ico-svg {
//   &:before {
//       background-position: 0em -223em;
//       width: 2em;
//       height: 2.5em;
//     }
//   }

//   @mixin icon-nivas-ico-svg(){
//     @extend .nivas-ico-svg;
//   }
// 

// .icon.pdf-svg {
//   &:before {
//       background-position: 0em -225.5em;
//       width: 1.2em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-pdf-svg(){
//     @extend .pdf-svg;
//   }
// 

// .icon.photoswipe-close-svg {
//   &:before {
//       background-position: 0em -226.9em;
//       width: 4.5em;
//       height: 4.5em;
//     }
//   }

//   @mixin icon-photoswipe-close-svg(){
//     @extend .photoswipe-close-svg;
//   }
// 

// .icon.photoswipe-next-svg {
//   &:before {
//       background-position: 0em -231.4em;
//       width: 2.6em;
//       height: 6em;
//     }
//   }

//   @mixin icon-photoswipe-next-svg(){
//     @extend .photoswipe-next-svg;
//   }
// 

// .icon.photoswipe-prev-svg {
//   &:before {
//       background-position: 0em -237.4em;
//       width: 2.6em;
//       height: 6em;
//     }
//   }

//   @mixin icon-photoswipe-prev-svg(){
//     @extend .photoswipe-prev-svg;
//   }
// 

// .icon.pin-green-svg {
//   &:before {
//       background-position: 0em -243.4em;
//       width: 0.8em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-pin-green-svg(){
//     @extend .pin-green-svg;
//   }
// 

// .icon.print-svg {
//   &:before {
//       background-position: 0em -244.6em;
//       width: 1.6em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-print-svg(){
//     @extend .print-svg;
//   }
// 

// .icon.promo-code-svg {
//   &:before {
//       background-position: 0em -246.2em;
//       width: 2em;
//       height: 2em;
//     }
//   }

//   @mixin icon-promo-code-svg(){
//     @extend .promo-code-svg;
//   }
// 

// .icon.scroll-cta-svg {
//   &:before {
//       background-position: 0em -248.2em;
//       width: 2.6em;
//       height: 1.2em;
//     }
//   }

//   @mixin icon-scroll-cta-svg(){
//     @extend .scroll-cta-svg;
//   }
// 

// .icon.secure-lg-svg {
//   &:before {
//       background-position: 0em -249.4em;
//       width: 14em;
//       height: 14em;
//     }
//   }

//   @mixin icon-secure-lg-svg(){
//     @extend .secure-lg-svg;
//   }
// 

// .icon.secure-svg {
//   &:before {
//       background-position: 0em -263.4em;
//       width: 2em;
//       height: 2em;
//     }
//   }

//   @mixin icon-secure-svg(){
//     @extend .secure-svg;
//   }
// 

// .icon.slider-next-big-svg {
//   &:before {
//       background-position: 0em -265.4em;
//       width: 3.2em;
//       height: 5.8em;
//     }
//   }

//   @mixin icon-slider-next-big-svg(){
//     @extend .slider-next-big-svg;
//   }
// 

// .icon.slider-next-svg {
//   &:before {
//       background-position: 0em -271.2em;
//       width: 1em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-slider-next-svg(){
//     @extend .slider-next-svg;
//   }
// 

// .icon.slider-prev-big-svg {
//   &:before {
//       background-position: 0em -272.6em;
//       width: 3.2em;
//       height: 5.8em;
//     }
//   }

//   @mixin icon-slider-prev-big-svg(){
//     @extend .slider-prev-big-svg;
//   }
// 

// .icon.slider-prev-svg {
//   &:before {
//       background-position: 0em -278.4em;
//       width: 1em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-slider-prev-svg(){
//     @extend .slider-prev-svg;
//   }
// 

// .icon.time-2-svg {
//   &:before {
//       background-position: 0em -279.8em;
//       width: 0.9em;
//       height: 0.9em;
//     }
//   }

//   @mixin icon-time-2-svg(){
//     @extend .time-2-svg;
//   }
// 

// .icon.time-svg {
//   &:before {
//       background-position: 0em -280.7em;
//       width: 0.9em;
//       height: 0.9em;
//     }
//   }

//   @mixin icon-time-svg(){
//     @extend .time-svg;
//   }
// 

// .icon.twitter-svg {
//   &:before {
//       background-position: 0em -281.6em;
//       width: 2em;
//       height: 1.6em;
//     }
//   }

//   @mixin icon-twitter-svg(){
//     @extend .twitter-svg;
//   }
// 

// .icon.video-button-svg {
//   &:before {
//       background-position: 0em -283.2em;
//       width: 1.2em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-video-button-svg(){
//     @extend .video-button-svg;
//   }
// 

// .icon.why-info-lg-svg {
//   &:before {
//       background-position: 0em -284.6em;
//       width: 14em;
//       height: 14em;
//     }
//   }

//   @mixin icon-why-info-lg-svg(){
//     @extend .why-info-lg-svg;
//   }
// 

// .icon.why-info-svg {
//   &:before {
//       background-position: 0em -298.6em;
//       width: 2em;
//       height: 2em;
//     }
//   }

//   @mixin icon-why-info-svg(){
//     @extend .why-info-svg;
//   }
// 

// .icon.word-svg {
//   &:before {
//       background-position: 0em -300.6em;
//       width: 1.2em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-word-svg(){
//     @extend .word-svg;
//   }
// 

// .icon.zip-svg {
//   &:before {
//       background-position: 0em -302em;
//       width: 1.2em;
//       height: 1.4em;
//     }
//   }

//   @mixin icon-zip-svg(){
//     @extend .zip-svg;
//   }
// 
*/

@mixin svg-sprite-width($svgsprite) {
    width: map-get($svgsprite, 'width');
  }
  
  @mixin svg-sprite-height($svgsprite) {
    height: map-get($svgsprite, 'height');
  }
  
  @mixin svg-sprite-position($svgsprite) {
    background-position: map-get($svgsprite, 'offset-x') map-get($svgsprite, 'offset-y');
  }
  
  @mixin svg-sprite-image($svgsprite) {
    background-image: url(map-get($svgsprite, 'svg'));
  }
  
  @mixin svg-sprite($svgsprite) {
    font-size: 10px;
    @include svg-sprite-image($svgsprite);
    @include svg-sprite-position($svgsprite);
    @include svg-sprite-width($svgsprite);
    @include svg-sprite-height($svgsprite);
  }
  