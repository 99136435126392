// Transitions

@mixin transition($transition...) {
    -webkit-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
@mixin transition-property($transition-property...) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
    -webkit-transition-delay: $transition-delay;
    transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
    -webkit-transition-timing-function: $timing-function;
    transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
    -webkit-transition: -webkit-transform $transition;
    -moz-transition: -moz-transform $transition;
    -o-transition: -o-transform $transition;
    transition: transform $transition;
}