.case-study {
    padding: 74px 0 81px;
    box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;
    background-color: $white;

    @include media-breakpoint-up(md) {
        padding: 120px 0;
    }

    &__label {
        margin-bottom: 38px;
    }

    &__item {
        padding: 42px 8px 8px;
        border-radius: 30px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        margin-bottom: 25px;
        background-color: $white;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        min-height: 212px;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            padding: 48px 24px 24px;
            margin-bottom: 32px;
            min-height: 281px;
        }
    }

    &__content {
        padding: 0 16px 32px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        @include media-breakpoint-up(md) {
            padding: 0 24px 24px;
        }
    }

    &__media-wrapper {
        width: 100%;
        max-width: 80px;
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            max-width: 117px;
            margin-top: 8px;
        }

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

    &__media {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        
        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: #ffffff;
            width: 18px;
            height: 9px;
            z-index: 1;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__abstract {
        margin-right: 15px;
        flex: 1;

        @include media-breakpoint-up(md) {
            margin-right: 30px;
        }
    }

    &__abstract h3 {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $ebony;
        font-weight: 500;
        margin: 0;

        @include media-breakpoint-up(md) {
            @include fontSize(24);
            @include lineHeight(34,24);
        }
    }

    &__btn-wrapper {
        display: flex;
        justify-content: flex-end;

        &--all {
            justify-content: center;
            margin-top: 16px;

            @include media-breakpoint-up(md) {
                justify-content: flex-end;
                margin: 0;
            }
        }
    }

    &__wrapper {
        &:hover {
            .case-study__btn {
                background: $navy;
                border: 1px solid rgb(209, 222, 237);
                box-shadow: 0px 8px 16px 0px rgba(0, 66, 137, 0.3);
    
                &:before {
                    opacity: 0;
                }
    
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}