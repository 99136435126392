/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Reviews block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.review {
    position: relative;
    background-color: $ebony;
    padding: 72px 0 55px 0;

    &:before {
        position: absolute;
        content: " ";
        display: block;
        bottom: 0px;
        right: 0px;
        width: 100%;
        height: 55px;
        background: url("/src/img/png/review-deco-m.png");
        background-size: cover;

        @include media-breakpoint-up(lg) {
            top: 0px;
            right: 0px;
            width: 160px;
            height: 100%;
            background: url("/src/img/png/review-deco-d1.png");
        }
    }

    @include media-breakpoint-up(lg) {
        padding: 124px 0 120px 0;
    }

    &__title {
        margin-bottom: 38px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 53px;
        }
    }

    &__navigation {
        position: absolute;
        display: inline-block;
        right: 20px;
        top: 0px;
        width: 200px;
        height: 24px;
        text-align: right;
    }

    &__item {

        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
        }
    }

    &__card {
        width: 100%;
        margin-bottom: 38px;
        background: $white;
        border-radius: 15px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        padding: 16px 24px 24px 24px;

        @include media-breakpoint-up(lg) {
           width: 25%;
        }
    }

    &__company {
        display: block;
        height: 52px;
        margin-bottom: 8px;

        img {
            height: 100%;
            display: flex;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
        }
    }

    &__author p {
        @include fontSize(14);
        @include lineHeight(24,14);
        font-weight: normal;
        color: $storm-gray;
        margin-bottom: 0px;

        span {
            display: block;
            font-weight: 500;
            color: $ebony;
        }
    }

    &__text {

        @include media-breakpoint-up(lg) {
            padding-left: 65px;
            width: 75%;
        }
    }

    &__text p {
        @include fontSize(18);
        @include lineHeight(28,18);
        font-weight: 400;
        color: $white;
        margin-bottom: 33px;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(34,20);
        }
    }
}



.custom-swiper__pagination-btn {
    width: 24px;
    height: 24px;
    outline: none;

   &:before {
       display: none;
   }
}
.swiper-pagination-custom {
    top: 0px;
}


.recenzije-block {
    &__text {
        margin-bottom: 50px;
        p {
            @include clearLast;
            @include fontSize(18);
            @include lineHeight(28,18);
            margin-bottom: 22px;

            @include media-breakpoint-up(lg) {
                @include fontSize(20);
                @include lineHeight(34,20);

            }
        }
    }
}
