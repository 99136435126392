.clients {
    padding: 48px 0;

    @include media-breakpoint-up(md) {
        padding: 58px 0;
    }

    &--code4wms {
    	padding: 48px 16px;

    	@include media-breakpoint-up(md) {
    		padding: 58px 16px;
    	}

    }


    &__label {
        font-weight: 500;
        margin-bottom: 15px;
        color: $ebony;

        &--code4wms {

        	&:before {
        		content: '';
	        	background-color: transparent;
	        	background-image: url('/src/img/svg/code4wms-logo-icon.svg');
	        	width: 24px;
	        	height: 24px;
	        	display: inline-block;
	        	position: absolute;
	        	top: -2px;
	        	left: -32px;
	        }
	    }

    }

    &__list {
        display: flex;
        margin-top: 46px;
        flex-wrap: wrap;
        align-items: center;
        gap: 20px;

        &:first-child {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            margin-top: 56px;
            justify-content: center;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__item {
        // flex-basis: 50%;
        flex-basis: calc(50% - 20px);
        // flex-grow: 1;
        text-align: center;
        margin-bottom: 40px;
        // padding: 0 20px;

        @include media-breakpoint-up(md) {
            flex-basis: 25%;
            flex-basis: calc(20% - 16px);
            margin-bottom: 0;
        }

        /* @include media-breakpoint-up(xl) {
            padding: 0 33px;
        } */

        img {
        	max-width: 100%;
        }
    }

    &__text p {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $ebony;
        //font-weight: 500;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
        }
    }

    &__icon {
        margin: 0 auto 13px;
    }
}
