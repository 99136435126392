/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Experts Card

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.experts-card {
    position: relative;
    width: 100%;

    @include media-breakpoint-up(lg) {
        &--alt {
            position: absolute;
            z-index: 10;
        }
    }

    &__media {
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 68px;
        height: 164px;
        background: url("/src/img/png/mobile-device.png") no-repeat top;
        background-size: contain;
        z-index: 1;
    }

    &__wrap {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        min-height: 368px;
        padding: 40px 24px 24px 24px;
        background: $ebony;
        border-radius: 30px;

        &:before {
            position: absolute;
            display: block;
            content: " ";
            top: -42px;
            right: -16px;
            background: url("/src/img/png/expert-card-deco.png") no-repeat top;
            width: 108px;
            height: 329px;
        }
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        margin-bottom: 24px;
        color: $white;
        font-weight: 500;
        width: 60%;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(30,20);
            margin-bottom: 13px;
            position: relative;
            z-index: 1;
        }

        &--code4wms {
        	width: 100%;

        	@include media-breakpoint-up(lg) {
        		@include fontSize(24);
        		@include lineHeight(34,24);
        	}

        	span {
        		color: #f5a623;
        	}
        }
    }
  
    &__abstract p {
        @include fontSize(16);
        @include lineHeight(28,16);
        margin-bottom: 68px;
        color: $storm-gray;
        font-weight: 400;
        padding-right: 34px;
    }

    &__btn {
        display: block;
        width: 100%;
        z-index: 2;
    }
}


.experts-card-big {

    @include media-breakpoint-up(lg) {
        width: 100%;
        overflow: visible;
        padding: 70px 0;
      
        &:before {
            display: none;
        }

        .experts-card__media {
            position: absolute;
            bottom: 0px;
            right: 38px;
            width: 396px;
            height: 548px;
            background: url("/src/img/png/experts-visual.png") no-repeat center;
            background-size: contain;
        }

        .experts-card__wrap {
            position: relative;
            display: block;
            min-height: 409px;
            padding: 72px 86px;
            overflow: hidden;

            &:before {
                position: absolute;
                display: block;
                content: " ";
                top: -10px;
                right: 10px;
                background: url("/src/img/png/experts-deco-d.png") no-repeat top;
                width: 592px;
                height: 208px; 
            }

            &:after {
                position: absolute;
                display: block;
                content: " ";
                bottom: -63px;
                left: 386px;
                background: url("/src/img/png/experts-deco-d1.png") no-repeat center;
                width: 937px;
                height: 172px;
                background-size: cover;
            }
        }

        .experts-card__title {
            @include fontSize(60);
            @include lineHeight(72,60);
            letter-spacing: -1px;
            font-weight: 600;
            width: auto;
            margin-bottom: 24px;
        }
    
        .experts-card__abstract p {
            @include fontSize(20);
            @include lineHeight(34,20);
            margin-bottom: 40px;
            width: 383px;
            padding-right: 0;
        }

        .experts-card__btn {
            display: inline-block;
            width: auto;
        }
    }
}