.partners-list {

    display: flex;
    flex-wrap: wrap;

    &__item {
        width: 100%;
        display: flex;
        margin-bottom: 50px;

        @include media-breakpoint-up(lg) {
            width: 50%;
            margin-bottom: 120px;
        }
    }

    &__text {
        width: 60%;

        h3 {
            @include clearLast;
            @include fontSize(20);
            @include lineHeight(27,20);
            margin-bottom: 11px;

            @include media-breakpoint-up(lg) {
                @include fontSize(24);
                @include lineHeight(29,24);

            }
        }
    }

    &__media {
        width: 40%;
        text-align: center;
    }

    &__img {
        width: auto;
        max-width: 80%;
        height: auto;
    }



}



.about-partners {

    padding: 50px 0 0;

    @include media-breakpoint-up(lg) {
        padding: 100px 0 0;
    }


    &__text {
        margin-bottom: 50px;
        p {
            @include clearLast;
            @include fontSize(18);
            @include lineHeight(28,18);
            margin-bottom: 22px;

            @include media-breakpoint-up(lg) {
                @include fontSize(20);
                @include lineHeight(34,20);

            }
        }
    }


    &__list {

    }




}
