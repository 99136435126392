/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

About block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.about-block {
    padding: 72px 20px 82px;
    background-color: $aqua-haze;

    @include media-breakpoint-up(lg) {
        padding: 120px 0;
    }

    &__wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 570px;
        width: 100%;

        @include media-breakpoint-up(md) {
            min-height: 570px;
        }
    }

    &__media {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        // clip-path: polygon(50% 0%, 50% 12%, 100% 12%, 100% 100%, 100% 100%, 0 100%, 0 0);

        @include media-breakpoint-up(md) {
            clip-path: polygon(50% 0%, 100% 0, 100% 79%, 72% 79%, 72% 100%, 0 100%, 0 0);
        }

        @include media-breakpoint-up(lg) {
            width: 83%;
        }

        &:after {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(8, 14, 28, 0.4);
        }
    }
    
    &__img {
        width: 100%;
        max-height: 570px;
        height: 100%;
        object-fit: cover;

        @include media-breakpoint-up (md) {
            max-height: unset;
            min-height: 570px;
        }
        
    }

    &__description {
        margin-bottom: 40px;
    }

    &__description p {
        @include fontSize(18);
        @include lineHeight(28,18);
        font-weight: normal;
        color: $white;

        @include media-breakpoint-up(lg) {
            @include fontSize(30);
            @include lineHeight(44,30);
            
        }
    }
}