// styleguide page only helpers

.styleguide-page {
	padding-top: 150px;
	padding-bottom: 50px;

	.section-title{
		@include fontSize(37);
		margin-bottom: 14px;
		padding-top: 40px;
		padding-bottom: 10px;
		margin-bottom: 33px;
		border-bottom: 2px dashed $black;
		display: block;
	}
}

.stylecolor-box {
	@include fontSize(17);
	padding: 7px;
	margin-bottom: 30px;
	color: $white;

	&--invert {
		color: $black;
	}
}
// styleguide page only helpers END




// GENERAL

::-moz-selection {
	background: $colorSecondary;
	color: $colorPrimary;
}
::selection {
	background: $colorSecondary;
	color: $colorPrimary;
}

html {
	font-size: 62.5%;
}

body {
	@include fontPrimary;
	color: $colorSecondary;
	background: $colorPrimary;
}

.container {
	position: relative;
}

h1, h2, h3, h4, h5, h6 {
	@include fontSecondary;
}


h1,
.h1,
.title {
	@include fontSize(32);
	@include lineHeight(46, 32);
	color: $colorSecondary;
	font-weight: 600;
	letter-spacing: -0.89px;
	margin-bottom: 30px;
	@include fontPrimary;

	@include media-breakpoint-up(lg){
		@include fontSize(60);
		@include lineHeight(74, 60);
		letter-spacing: -1px;
	}
}

h2,
.h2,
.sub-title {
	@include fontSize(28);
	@include lineHeight(38, 28);
	color: $colorSecondary;
	font-weight: 600;
	letter-spacing: 0px;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg){
		@include fontSize(40);
		@include lineHeight(50, 40);
	}
}

h3 {
	@include fontSize(24);
	@include lineHeight(34, 24);
	color: $colorSecondary;
	font-weight: 600;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg){
		@include fontSize(32);
		@include lineHeight(42, 32);
	}
}

h4 {
	@include fontSize(20);
	@include lineHeight(30, 20);
	color: $colorSecondary;
	font-weight: 500;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg){
		@include fontSize(24);
		@include lineHeight(34, 24);
	}
}

h5 {
	@include fontSize(16);
	@include lineHeight(26, 16);
	color: $colorSecondary;
	font-weight: 500;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg){
		@include fontSize(20);
		@include lineHeight(30, 20);
	}
}

h6 {
	@include fontSize(14);
	@include lineHeight(24, 14);
	color: $colorSecondary;
	font-weight: 500;
	margin-bottom: 30px;

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(26, 16);
	}
}


p {
	@include fontSize(14);
	@include lineHeight(24,14);
	margin-bottom: 30px;
	color: #676e81;
	font-weight: normal;

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(28,16);
		margin-bottom: 40px;
	}
}


strong {
	font-weight: 600;
}


a {
	text-decoration: none;
}

a:not([class]) {

	color: $navy;

	&:hover {
		color: $ebony;
	}

}


// Lists
ul:not([class]),
ol:not([class]) {
	@include fontSize(14);
	@include lineHeight(28,14);
	font-weight: normal;
	list-style-position: outside;
	list-style-type: none;
    padding-left: 0px;
    //margin-left: 20px;
	margin-bottom: 30px;
	color: rgb(37, 41, 53);

	li {
		padding-left: 30px;
		margin-bottom: 0px;
		position: relative;
	}

	a {
		color: inherit;
		&:hover{
			text-decoration: none;
		}
	}

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(28,16);
	}

}

ul:not([class]) {
	li:before {
		content: "•";
		position: absolute;
    	left: 10px;
		font-size: 14px;
	}

	@include media-breakpoint-up(lg){
		font-size: 16px;
	}
}


ol:not([class]) {
	counter-reset: list-counter;
	li {
		counter-increment: list-counter;
		position: relative;
		&::before {
			content: counter(list-counter) ". ";
			font-weight: 700;
			position: absolute;
    		left: 4px;

		}
	}
}

/*
blockquote {
	text-align: center;
	@include fontSize(22);
	@include lineHeight(32,22);
	font-weight: 500;
	position: relative;
	padding: 30px 0;

	@include media-breakpoint-up(lg) {
		@include fontSize(42);
		@include lineHeight(54,42);
	}

	&:before {
		content: "• •";
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%);
		@include fontSize(14);
		@include lineHeight(14,14);

	}

	&:after {
		content: "• •";
		display: block;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%);
		@include fontSize(14);
		@include lineHeight(14,14);

	}
}*/







// helper classes
.hidden {
	display: none;
}
.hidden--desktop {
	@include media-breakpoint-up(lg) {
		display: none !important;
	}
}
.hidden--mobile {
	@include media-breakpoint-down(md) {
		display: none !important;
	}
}


// workarounds

// 20% column
.col-1-5 {

	-webkit-box-flex: 0;
	-ms-flex: 0 0 100%;
	flex: 0 0 100%;
	max-width: 100%;

	@media(min-width: 767px){
		-webkit-box-flex: 0;
	    -ms-flex: 0 0 20%;
	    flex: 0 0 20%;
	    max-width: 20%;
	}
}

.underline {
	text-decoration: underline;
}

.section-label {
	@include fontSize(12);
	line-height: normal;
	text-transform: uppercase;
	margin-left: 24px;
	margin-bottom: 0;
	position: relative;
	font-weight: 500;
	color: $ebony;
	display: block;

	@include media-breakpoint-up(lg) {
		@include fontSize(14);
	}

	&:before {
		content: ' ';
		width: 12px;
		height: 12px;
		background: $ebony;
		position: absolute;
		left: -22px;
		top: 2px;

	}

	&--light {
		color: $white;

		&:before {
			background: $white;
		}
	}
}

.item-progress {
    color: $white;
    @include fontSize(12);
    @include lineHeight(24,12);
	font-weight: normal;
	margin: 0 20px;
	
	@include media-breakpoint-up(lg) {
		@include fontSize(14);
		@include lineHeight(24,14);
	}

    &--gray {
        color: $storm-gray;
    }
}




.swiper-nav {
	position: absolute;
    right: 0px;
    width: 124px;
    height: 24px;
    display: block;
}

.swiper-button-next,
.swiper-button-prev {
	color: transparent !important;
	top: 0 !important;
	margin-top: 0 !important;

	&:after {
		font-size: 0px !important;
		height: 24px ;
		width: 24px;
	}
}

.swiper-button-next {
	right: 8px !important;

	&:after {
		background: url("/src/img/svg/arrow-slider-right.svg") no-repeat center;
	}
}

.swiper-button-prev {
	left: 8px !important;
	&:after {
		background: url("/src/img/svg/arrow-slider-left.svg") no-repeat center;
	}
}

.share {
	position: relative;
	z-index: 10;
	
	&__btn {
		position: relative;

		&.is-open {
			background: $navy;
			border: none;
			box-shadow: 0px 8px 16px 0px rgba(0, 66, 137, 0.3);

			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	
	&__drop {
		position: absolute;
		border-radius: 30px;
		top: 0px;
		left: 0px;
		background: $white;
		box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
		display: none;
		z-index: -1;
		padding: 60px 50px 44px;
	}

	&__link {
		display: block;
		@include fontSize(16);
		@include lineHeight(24,16);
		font-weight: normal;
		color: $ebony;
		margin-bottom: 16px;
		
		&:last-child() {
			margin: 0;
		}

		&:hover {
			color: $navy;
		}
	}
}

$delay: 0;
$duration: 800ms;
$iterations: 1;


.text-reveal .reveal,
.text-reveal .reveal::after {
	animation-delay: var(--animation-delay, 2s);
	animation-iteration-count: var(--iterations, 1);
	animation-duration: var(--duration, 800ms);
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
}

.text-reveal .reveal{
	position: relative;
	white-space: nowrap;
	cursor: default;
	
	&::after {
		content: "";
		position: absolute;
		z-index: 999;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $white;
		pointer-events: none;
		width: 100%;
		height: 100%;
		animation-name: text-revealer;
	}
}

@keyframes text-revealer {

	100% {
		width: 0%;
		transform: translate(-25%, 0);
	}
}