/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Checkout block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.checkout-block {
    position: relative;

    @include media-breakpoint-up(lg) {
        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: $ebony;
            height: 100%;
            width: 25vw;
            right: 0;
            top: 0;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column-reverse;
        
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    &__form {

        @include media-breakpoint-up(lg) {
           width: 58.333%;
           padding-right: 8.333%;
        }
    }

    &__cart {
        position: relative;
        background: $ebony;
        padding: 15px 0 15px;

        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: $ebony;
            height: 100%;
            width: calc(100% + 40px);
            left: -20px;
            top: 0;
            z-index: -1;
        }

        @include media-breakpoint-up(lg) {
            width: 41.667%;
            padding: 122px 0 0  8.333%;

            &:before {
                display: none;
            }
        }
    }
}