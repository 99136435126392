.article-list {

    &__wrapper {
        display: flex;
       // flex-direction: row;
       flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 56px 0 0;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            flex-direction: row;
        }
        
        @include media-breakpoint-up(lg) {
            padding: 120px 0 0;
        }
    }

    &__item {
        margin-bottom: 56px;

        @include media-breakpoint-up (md) {
            flex-basis: 31.5%;
          //  margin-right: 30px;
            margin-right: 2.75%;
            margin-bottom: 88px;

            &:nth-child(3n+3) {
                margin-right: 0px;  
            }
        }

        &:last-of-type {
            margin-bottom: 48px;
        }
    }

    .article-card__media {

        @include media-breakpoint-up (md) {
            height: 210px;
            position: relative;
        }
    }
    .article-card__img {
        @include media-breakpoint-up (md) {
            position: absolute;
            object-fit: cover;
        }
    }
    &__btn-more {
        margin: 0 auto;
    }
}