/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Contact block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.contact-block {
    position: relative;
    
    @include media-breakpoint-up(lg) {
        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: $aqua-haze;
            height: 100%;
            width: 25vw;
            left: 0;
            top: 0;
        }
    }
   
    &__wrapper {
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &__heading {
        position: relative;
        background: $aqua-haze;
        
        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: $aqua-haze;
            height: 100%;
            width: calc(100% + 40px);
            left: -20px;
            top: 0;
        }

        @include media-breakpoint-up(lg) {
            width: 41.667%;
            padding-right: 8.333%; 

            &:before {
                display: none;
            }
        }
    }

    &__content {
       
        @include media-breakpoint-up(lg) {
            width: 58.333%;
        }
    }
    
    &__form {
      
        @include media-breakpoint-up(lg) {
            padding-left: 14.29%;
        }
    }
}