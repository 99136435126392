/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Products category block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.products-category {
    position: relative;
    overflow: hidden;
   
    @include media-breakpoint-up(lg) {

        &:before {
            display: block;
            position: absolute;
            content: " ";
            right: 0px;
            bottom: 0;
            width: 20vw;
            height: 100%;
            background-color: $aqua-haze; 
        }
    }
    
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
            max-height: 800px;
        }
    }

    &__block {
      
        @include media-breakpoint-up(lg) {
            box-shadow: none; 
        }
    }
    
    &__products-wrap {
       padding: 74px 0 24px 0;

        @include media-breakpoint-up(lg) {
           padding: 112px 0 142px 0;
        }
    }

    &__consumables {
        background-color: $aqua-haze;
        width: calc(100% + 40px);
        margin-left: -20px;
        padding: 74px 20px 0px;
        
        @include media-breakpoint-up(lg) {
            height: 100%;
            padding: 112px 0 20px 8.333%;
            box-shadow: inset 1px 0 0 0 $light-gray;
        }
    }

    &__media {
        position: relative;
        width: calc(100% + 40px);
        margin-left: -20px;
        height: 202px;
        margin-top: 8px;

        @include media-breakpoint-up(md) {
            height: 240px;
        }
     
        @include media-breakpoint-up(lg) {
            width: 40vw;
            min-width: 100%;
            position: absolute;
            left: -10px;
            bottom: 0px;
            margin-top: 0px;
            margin-left: 0px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__label {
        margin-bottom: 35px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 56px;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(lg) {
            flex-direction: column;
        }

        &--fixed-height {
            @include media-breakpoint-up(lg) {
               max-height: 400px;
            }
        }
    }

    &__item {
        width: 50%;
        text-align: center;
        margin-bottom: 56px;

        @include media-breakpoint-up(lg) {
            text-align: left;
            margin-bottom: 34px;
        }

        &--alt {
            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }
    }

    &__link {
        display: block;
        @include fontSize(16);
        @include lineHeight(26,16);
        font-weight: 500;
        color: $ebony;
        max-width: 600px;
        margin: 0 40px;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(30,20);
            margin: 0;
        }

        &:hover {
            color: $navy;
        }
    }

    &__item-media {
        width: 100%;
        height: 56px;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            display: inline-block;
            width: 70px;
            margin-bottom: 0px;
            margin-right: 24px;
        }
    }

    &__item-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}