/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Related block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.related {
    display: block;
    background: $white;
    padding: 72px 0;
    box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;

    @include media-breakpoint-up(lg) {
        padding: 121px 0;
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 46px;
        display: flex;
        justify-content: center;

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }
    }

    &__prev {
        display: block;
        width: 24px;
        height: 24px;
        @include img-svg(arrow-slider-left);
        background-repeat: no-repeat;
        background-position: center;
        appearance: none;
        outline: none;
        border: none;
        font: 0/0 a;
    }

    &__next {
        display: block;
        width: 24px;
        height: 24px;
        @include img-svg(arrow-slider-right);
        background-repeat: no-repeat;
        background-position: center;
        appearance: none;
        outline: none;
        border: none;
        font: 0/0 a;
    }

    .swiper-button-next, .swiper-button-prev {
        &:focus {
            outline: none;
        }
    }

    &__wrap {
        width: 100%;
        padding: 0 20px;
        position: relative;
    }

    &__inner {
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__navigation {
        top: -32px;
        @include media-breakpoint-up(lg) {
            //display: none;
        }
    }

    &__slider-container {
        overflow: visible;
    }

    &__item {
        height: auto;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
          // width: 25%;
        }
    }

    &__btn-wrap {
        display: flex;
        justify-content: center;
    }

    .product-card {
        margin-bottom: 0;

        &__wrap {
            box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);

            @include media-breakpoint-up(lg) {
                box-shadow: none;
            }
        }
    }
}
