.article-card {

    &__wrapper {
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
    }

    &__section-label {
        margin-bottom: 8px;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__media {
        margin-bottom: 16px;
        position: relative;

        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: $white; 
            width: 90px;
            height: 38px;
            z-index: 1;
            bottom: 0px;
            right: 0px;

            @include media-breakpoint-up(lg) {
                width: 108px;
                height: 45px;
            }
        }
    }

    &__title {
        @include fontSize(16);
        @include lineHeight(26,16);
        color: $ebony;
        font-weight: 500;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(30,20);
        }
    }

    &__label p {
        @include fontSize(12);
        @include lineHeight(22,12);
        color: $storm-gray;
        font-weight: normal;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            @include fontSize(14);
            @include lineHeight(24,14);
        }
    }

    &__wrapper {
        &:hover {
            .article-card__title {
                color: $navy;
                transition: all 0.25s;
            }
        }
    }
}

.article-card-small {
    border-radius: 15px;

    @include media-breakpoint-up(lg) {
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
    }
   
    .article-card__wrapper {
        position: relative;
        display: block;
        overflow: hidden;
        border-radius: 15px;
        width: 100%;
        background-color: $white;

        &:hover {
            .article-card__media {
                opacity: 1;
            }
            
            .article-card__title {
                color: $white;
            }

            .article-card__btn {
                background: $navy;
                border: none;
                box-shadow: 0px 8px 16px 0px rgba(0, 66, 137, 0.3);
    
                &:before {
                    opacity: 0;
                }
    
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    .article-card__media {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin-bottom: 0px;
        transition: all 0.25s cubic-bezier(.12,.71,.78,.6);

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(37, 41, 53, 0.5);
            z-index: 1;
        }
        
        @include media-breakpoint-up(lg) {
            opacity: 0;
        }
    }

    .article-card__img {
        object-fit: cover;
    }

    .article-card__inner {
        position: relative;
        z-index: 1;
        height: 186px;
    }

	.article-card__title {
        padding: 24px 16px 16px;
        z-index: 1;
        color: $white;
        transition: all 0.25s cubic-bezier(.12,.71,.78,.6);
      
        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
            color: $ebony;
        }
    }

	.article-card__btn {
        position: absolute;
        right: 8px;
        bottom: 8px;
        background: $navy;
        border: none;
        box-shadow: 0px 8px 16px 0px rgba(0, 66, 137, 0.3);
        transition: all 0.25s cubic-bezier(.12,.71,.78,.6);

        &:before {
            opacity: 0;
        }

        &:after {
            opacity: 1;
        }

        @include media-breakpoint-up(lg) {
            background: $white;
            border: 1px solid rgb(209, 222, 237);
            box-shadow: none;

            &:before {
                opacity: 1;
            }

            &:after {
                opacity: 0;
            }
        }
    }
}

.article-card-big {
    position: relative;

    &__inner {
        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    &__heading-wrapper {
        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 0;
        }
    }

    &__media-wrapper {
        width: 100%;
        position: relative;

        @include media-breakpoint-up(md) {
            height: 450px;
        }
       
        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;

            @include media-breakpoint-up(md) {
                display: none;
            }
        } 
    }

    &__media {
        margin-top: -50px;
        position: absolute;
        width: 100%;
        height: 100%;
        clip-path: polygon(0 12%, 33% 12%, 33% 0, 100% 0, 100% 100%, 0 100%);

        @include media-breakpoint-up(md) {
            position: relative;
            clip-path: polygon(50% 0%, 100% 0, 100% 79%, 72% 79%, 72% 100%, 0 100%, 0 0);
        }

        @include media-breakpoint-up(lg) {
            margin-top: -80px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__section-label {
        margin-top: -32px;
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            margin-top: 20px;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 40px;
        }
    }
    
    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
            @include fontSize(32);
            @include lineHeight(42,32);
            margin-bottom: 0;
        }
    }

    &__label p {
        @include fontSize(12);
        @include lineHeight(22,12);
        color: $storm-gray;
        font-weight: normal;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            @include fontSize(14);
            @include lineHeight(24,14);
            margin-bottom: 8px;
        }
    }

    &__label {
        &--hidden-mobile p {
            display: none;
    
            @include media-breakpoint-up(md) {
                display: block;
            }
        }

         &--hidden-desktop p {
    
            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &__wrapper {
        &:hover {
            .article-card-big__title {
                color: $navy;
                transition: all 0.25s;
            }
        }
    }
}