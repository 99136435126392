/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Forms

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/






::placeholder {
	color: $storm-gray;
}

.form-msg {
	display: block;
	padding: 12px 16px 13px 16px;
	@include fontSize(12);
	@include lineHeight(15,12);
	margin: 20px 0;
	border-radius: 15px;

	@include media-breakpoint-up(lg){
		@include fontSize(14);
		@include lineHeight(17,14);
		padding: 16px;
	}

	&--error {
		background-color: $pastel-pink;
		color: $cerise-red;
	}

	&--success {
		background-color: $areo;
		color: $emerald;
	}
}

.form-group {
	position: relative;
	margin-bottom: 32px;

	&--m-0 {
		margin: 0;
	}

	&__text {
		@include fontSize(16);
		@include lineHeight(28,16);
		color: $ebony;
		font-weight: 500;
		margin-bottom: 19px;
	}

	&__validation {
		display: flex;
		justify-content: flex-end;
		margin-top: 14px;
		//display: none;
		position: relative;
		//bottom: -31px;
		//right: 0px;

		@include media-breakpoint-up(lg){
			margin-top: 8px;
			//bottom: -31px;
		}
	}

	&__validation p { 
		padding: 8px;
		margin: 0; 
		box-shadow: 0px 4px 8px 0px rgba($smalt, 0.15);
		border-radius: 8px;
		@include fontSize(12);
		@include lineHeight(15,12);
		color: $cerise-red;
	}

	&.error {
		input,
		textarea {
			border-bottom: 2px solid $cerise-red !important;
		}
		input + label,
		textarea + label {
			color: $cerise-red !important;
		}

		input[type=checkbox] + label,
		input[type=radio] + label {
			color: $storm-gray !important;
			&:before {
				border:  2px solid $cerise-red;
			}
		}
	}
}


input {

	// $input-types array of type='text' like inputs
	$input-types: email, text, number, password, search, tel;

	// for each $input-type in $input-types
	@each $input-type in $input-types {

		&[type="#{$input-type}"] {
			width: 100%;
			height: 50px;
			@include fontSize(14);
			@include lineHeight(17,14);
			font-weight: 500;
			color: $ebony;
			border: none;
			outline: none;
			background: none;
			background-color: transparent;
			padding: 17px 0 16px;
			border-radius: 0;
			border-bottom: 2px solid $ebony;

			@include media-breakpoint-up(lg){
				height: 60px;
				@include fontSize(16);
				@include lineHeight(28,16);
				padding: 20px 0;
			}

			&:focus {
				border-color: $navy;
			}

			&:focus + label,
			&:not(:placeholder-shown) + label  {
				transform: translateY(-17px);
				@include fontSize(12);
				@include lineHeight(22,12);

				@include media-breakpoint-up(lg){
					transform: translateY(-20px);
				}
			}

			& + label {
				display: inline-block;
				position: absolute;
				@include fontSize(14);
				@include lineHeight(17,14);
				font-weight: 500;
				color: $storm-gray;
				top: 17px;
				left: 0;
				margin: 0;
				user-select: none;
				transition: transform .25s, opacity .25s ease-in-out;
				transform: scaleX(1);
				transform-origin: 0 0;

				@include media-breakpoint-up(lg){
					@include fontSize(16);
					@include lineHeight(20,16);
					font-weight: normal;
					top: 20px;
				}
			}
		}

	}

}

select {
	width: 100%;
	height: 50px;
	@include fontSize(14);
	@include lineHeight(17,14);
	font-weight: 500;
	color: $ebony;
	border: none;
	outline: none;
	background: none;
	background-color: transparent;
	padding: 17px 0 16px;
	border-radius: 0;
	border-bottom: 2px solid $ebony;

	@include media-breakpoint-up(lg){
		height: 60px;
		@include fontSize(16);
		@include lineHeight(28,16);
		padding: 20px 0;
	}

	&:focus {
		border-color: $navy;
	}

	&:focus + label {
		transform: translateY(-17px);
		@include fontSize(12);
		@include lineHeight(22,12);

		@include media-breakpoint-up(lg){
			transform: translateY(-20px);
		}
	}
}

select + label {
	display: inline-block;
	position: absolute;
	@include fontSize(14);
	@include lineHeight(17,14);
	font-weight: 500;
	color: $storm-gray;
	top: 17px;
	left: 0;
	margin: 0;
	user-select: none;
	transition: transform .25s, opacity .25s ease-in-out;
	transform: scaleX(1);
	transform-origin: 0 0;

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(20,16);
		font-weight: normal;
		top: 20px;
	}
}


textarea {
	width: 100%;
	height: 162px;
	@include fontSize(14);
	@include lineHeight(17,14);
	font-weight: 500;
	color: $ebony;
	border: none;
	outline: none;
	background: none;
	background-color: transparent;
	padding: 17px 0 16px;
	border-radius: 0;
	border-bottom: 2px solid $ebony;

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(28,16);
		padding: 20px 0;
	}

	&:focus {
		border-color: $navy;	
	}

	&:focus + label,
	&:not(:placeholder-shown) + label  {
		transform: translateY(-17px);
		@include fontSize(12);
		@include lineHeight(22,12);
	}
}

textarea + label {
	display: inline-block;
	position: absolute;
	@include fontSize(14);
	@include lineHeight(17,14);
	font-weight: 500;
	color: $storm-gray;
	top: 17px;
	left: 0;
	margin: 0;
	user-select: none;
	transition: transform .25s, opacity .25s ease-in-out;
	transform: scaleX(1);
	transform-origin: 0 0;

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(20,16);
		font-weight: normal;
		top: 20px;
	}
}

input[type="checkbox"] {
	display: none;
}

input[type=checkbox] + label {
	@include fontSize(12);
	@include lineHeight(22,12);
	color: $storm-gray;
	padding-left: 24px;
	display: block;
	position: relative;
	cursor: pointer;
	margin-bottom: 11px;

	&:before {
		position: absolute;
		content: '';
		display: block;
		top: 2px;
		left: 0;
		width: 16px;
		height: 16px;
		background-color: $white;
		border:  2px solid $ebony;
	}

	&:after {
		position: absolute;
		content: '';
		opacity: 0;
		left: 0px;
    	top: 2px;
		width: 16px;
		height: 16px;
		background-color: $navy;
		border: 8px solid $ebony;
		transition: all 0.25s ease-in-out;
	}

	a {
		color: $storm-gray;	
	}

	@include media-breakpoint-up(lg){
		@include fontSize(14);
		@include lineHeight(24,14);

		&:before {
			top: 4px;
		}
	
		&:after {
			top: 4px;
		}
	}
}

input[type=checkbox]:checked + label {
	
	&:before {
		border: 2px solid $navy;
	}

	&:after {
		opacity: 1;
		border: none;
		display: block;
	}
}

input[type="radio"] {
	display: none;
}

input[type=radio] + label {
	@include fontSize(12);
	@include lineHeight(22,12);
	color: $storm-gray;
	padding-left: 24px;
	display: block;
	position: relative;
	cursor: pointer;
	margin-bottom: 9px;

	&:before {
		position: absolute;
		content: '';
		display: block;
		top: 2px;
		left: 0;
		width: 16px;
		height: 16px;
		background-color: $white;
		border:  2px solid $ebony;
		border-radius: 50%;
	}

	&:after {
		position: absolute;
		content: '';
		opacity: 0;
		left: 4px;
    	top: 6px;
		width: 8px;
		height: 8px;
		background-color: $navy;
		border: 8px solid $ebony;
		transition: all 0.25s ease-in-out;
		border-radius: 50%;
	}

	a {
		color: $storm-gray;	
	}

	@include media-breakpoint-up(lg){
		@include fontSize(14);
		@include lineHeight(24,14);

		&:before {
			top: 4px;
		}
	
		&:after {
			top: 8px;
		}
	}
}

input[type=radio]:checked + label {
	
	&:before {
		border: 2px solid $navy;
	}

	&:after {
		opacity: 1;
		border: none;
		display: block;
	}
}