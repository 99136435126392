.benefits {
    padding-bottom: 48px;

    @include media-breakpoint-up(md) {
        padding-bottom: 120px;
    }

    &--bg-gray {
        box-shadow: inset 0 1px 0 0 $light-gray, inset 0 -1px 0 0 $light-gray;
        background-color: $aqua-haze;

        padding: 48px 0;

        @include media-breakpoint-up(md) {
            padding: 100px 0;
        }
    }

    &--code4wms {
    	background-image: url('/src/img/jpg/benefits-code4wms-bg.jpg');
    	background-repeat: no-repeat;
    	background-size: cover;
    	position: relative;
        overflow: hidden;
        padding: 40px 16px;

    	@include media-breakpoint-up(md) {
            padding: 116px 0 180px;
        }

        &:after {
        	display: block;
        	position: absolute;
        	content:" ";
        	right: 38px;
        	bottom: 0;
        	width: 230px;
        	height: 40px;
        	background: url("/src/img/svg/deco-m.svg") no-repeat center;
        	z-index: 1;
        	background-size: cover;

        	@include media-breakpoint-up(lg) {
        		right: 0px;
        		width: 100%;
        		height: 50px;
        		background: url("/src/img/svg/deco-d.svg") no-repeat center;
        		background-size: cover;
        	}
        }
    }


    &__label {
        font-weight: 500;
        margin-bottom: 15px;
        color: $ebony;

        &--code4wms {
        	color: #fff;

        	&:before {
        		content: '';
        		background-color: transparent;
	        	background-image: url('/src/img/svg/code4wms-logo-icon.svg');
	        	width: 24px;
	        	height: 24px;
	        	display: inline-block;
	        	position: absolute;
	        	top: -2px;
	        	left: -32px;
	        }
	    }

	    &--white {
	    	color: #fff;
	    }
    }

    &__list {
        display: flex;
        margin-top: 46px;
        flex-wrap: wrap;

        &:first-child {
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            margin-top: 56px;
            &:first-child {
                margin-top: 0;
            }
        }
    }

    &__item {
        flex-basis: 50%;
        flex-grow: 1;
        text-align: center;
        margin-bottom: 40px;
        padding: 0 20px;

        @include media-breakpoint-up(md) {
            flex-basis: 25%;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(xl) {
            padding: 0 33px;
        }
    }

    &__text p {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $ebony;
        //font-weight: 500;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
        }
    }

    &__icon {
        margin: 0 auto 13px;
    }
}
