/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Category Card

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.category-card {
    position: relative;
   
    &__wrap {
        background: $white;
        padding: 32px 24px 40px;
        border-radius: 15px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
    }

    &__label {
        display: block;
        margin-bottom: 24px;
    }

    &__list {
        list-style-position: outside;
        list-style-type: none;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    &__item {
        position: relative;
        @include fontSize(14);
        @include lineHeight(28,14);
        font-weight: normal;
        padding-left: 30px;

        &:before {
            content: "•";
            position: absolute;
            left: 10px;
            font-size: 16px;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        }

        @include media-breakpoint-up(lg){
            @include fontSize(16);
            @include lineHeight(28,16);
        }

        &:hover {
            &:before {
                color: $navy;  
            }
            .category-card__link {
                color: $navy;
            }
        }
    }

    &__link {
        color: $ebony;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
    }
}