/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Tables

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/







//tables
table:not([class]) {
	font-style: normal;
	font-weight: 400;
	border-collapse: collapse;
	position: relative;
	text-align: left;
	width: 100%;
	margin-bottom: 10px;
	padding-bottom: 10px;

	th,
	td {
		padding: 6px 20px 5px;
		vertical-align: top;
		white-space: nowrap;

		@include media-breakpoint-up(lg) {
			padding: 16px 24px;
		}
	}

	th,
	th a {
		font-style: normal;
		font-weight: normal;
		@include fontSize(12);
		@include lineHeight(22,12);
		background-color: $navy;
		color: $aqua-haze;
		// box-shadow: inset 0 -1px 0 0 $ebony;
		border: 1px solid #005dc0;
		border-bottom: 1px solid $ebony;

		@include media-breakpoint-up(lg) {
			@include fontSize(14);
			@include lineHeight(24,14);
		}
	}

	td,
	td a {
		font-style: normal;
		font-weight: 400;
		@include fontSize(12);
		@include lineHeight(22,12);
		color: $storm-gray;

		@include media-breakpoint-up(lg) {
			@include fontSize(14);
			@include lineHeight(24,14);
		}
	}

	td a,
	td strong,
	td b {
		font-style: normal;
		font-weight: 700;
	}

	td em,
	td i {
		font-style: normal;
		font-weight: 400;
	}

	tr td {
		// border-bottom: 1px solid $colorSecondary;
		// box-shadow: inset 0 -1px 0 0 $light-gray, inset 0 1px 0 0 $light-gray, inset -1px 0 0 0 $light-gray;
		@include transition(background-color 200ms ease-in-out, color 150ms ease-in-out);
		border: 1px solid $light-gray;
	}


	// tbody th,
	// tfoot th,
	// tbody th a,
	tbody td a,
	// tfoot th a,
	tfoot td a {
		font-style: normal;
		font-weight: 700;
		color: $colorSecondary;
	}

	thead tr th {
		border-bottom: 1px solid #005dc0;

		&:not(:last-child) {
			border-right: 1px solid $ebony;
		}
	}

}
