.box-icon {
    width: 75px;
    height: 75px;
    display: block;
    position: relative;

    &:before {
        display: block; 
        content: ' ';
        position: absolute;
    }

    &--user {

        &:before {
            @include img-svg(user-icon-n);
            width: 40px;
            height: 44px;
            top: 13px;
            left: 15px;
        }
    }

    &--engineers {

        &:before {
            @include img-svg(engineers-icon-n);
            width: 46px;
            height: 46px;
            top: 17px;
            left: 17px;
        }
    }

    &--reliability {

        &:before {
            @include img-svg(quality-icon-n);
            width: 38px;
            height: 46px;
            top: 12px;
            left: 16px;
        }
    }

    &--experience {

        &:before {
            @include img-svg(experience-icon-n);
            width: 44px;
            height: 48px;
            top: 16px;
            left: 18px;
        }
    }

    &--checkout-noitem {
        // @include img-svg(icon-border-box-blue);

        &:before {
            @include img-svg(cart-icon-blue);
            width: 46px;
            height: 30px;
            top: 23px;
            left: 10px;
        }
    }

    &--checkout-success {
        // @include img-svg(icon-border-box-dark-green);

        &:before {
            @include img-svg(cart-icon-blue);
            width: 46px;
            height: 30px;
            top: 23px;
            left: 10px;
        }
    }

    &--checkout-error {
        // @include img-svg(icon-border-box-dark-red);

        &:before {
            @include img-svg(cart-icon-error);
            width: 46px;
            height: 35px;
            top: 15px;
            left: 12px;
        }
    }

    &--expenses {

        &:before {
            @include img-svg(money-icon-n);
            width: 45px;
            height: 47px;
            top: 11px;
            left: 12px;
        }
    }

    &--quality {
        
        &:before {
            @include img-svg(star-icon-n);
            width: 46px;
            height: 46px;
            top: 17px;
            left: 17px;
        }
    }

    &--mistakes {

        &:before {
            @include img-svg(fingertip-icon-n);
            width: 40px;
            height: 40px;
            top: 15px;
            left: 15px;
        }
    }

    &--servis {

        &:before {
            @include img-svg(heart-icon-n);
            width: 42px;
            height: 42px;
            top: 19px;
            left: 19px;
        }
    }

    &--search {
        // @include img-svg(icon-border-box-dark-red);

        &:before {
            @include img-svg(search-icon-red);
            width: 37px;
            height: 37px;
            top: 17px;
            left: 17px;
        }
    }

    &--quality {

        &:before {
            @include img-svg(top-quality-icon);
            width: 46px;
            height: 46px;
            top: 14px;
            left: 14px;
        }
    }
    &--optimization {

        &:before {
            @include img-svg(optimization-icon);
            width: 46px;
            height: 42px;
            top: 14px;
            left: 14px;
        }
    }

    &--efficiency {

        &:before {
            @include img-svg(efficiency-icon);
            width: 47px;
            height: 40px;
            top: 14px;
            left: 14px;
        }
    }



    &--code4wms-less-spend {

    	&:before {
            @include img-svg(code4wms-less-spend-icon);
            width: 44px;
            height: 34px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--code4wms-warehouse-simple {

    	&:before {
            @include img-svg(code4wms-warehouse-simple-icon);
            width: 40px;
            height: 37px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--code4wms-warehouse-process {

    	&:before {
            @include img-svg(code4wms-warehouse-process-icon);
            width: 48px;
            height: 48px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--code4wms-optimal-space {

    	&:before {
            @include img-svg(code4wms-optimal-space-icon);
            width: 43px;
            height: 44px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--code4wms-commissioning {

    	&:before {
            @include img-svg(code4wms-commissioning-icon);
            width: 39px;
            height: 43px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--code4wms-automates-inventory {

    	&:before {
            @include img-svg(code4wms-automates-inventory-icon);
            width: 42px;
            height: 43px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--code4wms-efficiency {

    	&:before {
            @include img-svg(code4wms-efficiency-icon);
            width: 41px;
            height: 45px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--code4wms-productivity {

    	&:before {
            @include img-svg(code4wms-productivity-icon);
            width: 44px;
            height: 42px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }




}

// klasa za components.html

.icons-wrap {
    display: flex;
    justify-content: space-evenly;
    padding: 50px 0;
}