/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Product Card

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.product-card {
    position: relative;
    height: 100%;
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
    }

    &:hover {
        z-index: 10;
        
        @include media-breakpoint-up(lg){
            .product-card__to-cart {
                display: block;
                opacity: 1;
            }
        }
        .product-card__wrap {
            box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        }
       
        .product-card__name {
            color: $navy;
        }
    }

    &__to-cart {
        position: absolute;
        display: none;
        
        @include media-breakpoint-up(lg){
            display: block;
            opacity: 0;
            top: 8px;
            right: 8px;
            z-index: 10;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        }
    }

    &__wrap {
        display: block;
        width: 100%;
        height: 100%;
        padding: 16px 8px 24px;
        border-radius: 30px;
        text-align: center;
        
        @include media-breakpoint-up(lg){
            padding: 32px 34px 40px;
        }

        &:hover {
            box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
            .product-card__name {
                color: $navy;
            }
        }
    }

    &__media {
        position: relative;
        overflow: hidden;
        height: 136px;
        margin-bottom: 8px;

        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: $white; 
            width: 40px;
            height: 20px;
            z-index: 1;
        }
        
        @include media-breakpoint-up(lg){
            height: 210px;

            &:before {
    
                @include media-breakpoint-up(lg) {
                    width: 60px;
                    height: 24px;
                }
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__label {
        text-align: center;
        margin-bottom: 8px;
        color: $navy;
        text-transform: uppercase;
        @include fontSize(10);
        @include lineHeight(10,10);
        
        span {
            display: inline-block;
            background: $onahau;
            padding: 3px 12px 4px;
            border-radius: 9.5px;
        }
    }
    &__name {
        @include fontSize(14);
        @include lineHeight(24,14);
        font-weight: 500;
        color: $ebony;

        @include media-breakpoint-up(lg){
            @include fontSize(16);
            @include lineHeight(26,16);
        }
    }
}