/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Overlay block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: none;
   
    &.is-open {
        display: flex;
        justify-content: flex-end;
        background-color: rgba(8, 14, 28, 0.4);
    }

    &__inner {
        width: 100%;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        background: $white;
    }

    &__heading-inner {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 84px;
        right: 16px;
        z-index: 100;
    }

    &__heading {
        position: relative;
        box-shadow: inset 0 1px 0 0 $light-gray, inset 0 -1px 0 0 $light-gray;
        background-color: $aqua-haze;
        height: 74px;
        padding: 0 4px;

        &:before {
            display: block;
            position: absolute;
            content: " ";
            width: calc(100% + 40px);
            height: 100%;
            top: 0px;
            left: -20px;
            background: $aqua-haze;
            border-top: 1px solid $light-gray;
            border-bottom: 1px solid $light-gray;
        }

        @include media-breakpoint-up(lg) {
            height: 84px;

            &:before {
                display: block;
                position: absolute;
                content: " ";
                width: 100vw;
                left: 0px;
            }
        }
    }

    &__heading-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
    }

    &__btn {
        @include media-breakpoint-up(lg) {
            margin-right: 16px;
        }
    }

    &__content {
        position: relative;
        min-height: calc(100vh - 84px);
        height: 100%;
        padding: 60px 0 80px;

        &:before {
            display: block;
            position: absolute;
            content: " ";
            width: calc(100% + 40px);
            height: 100%;
            top: 0px;
            left: -20px;
            background: $white;
            z-index: -1;
        }

        @include media-breakpoint-up(lg) {
            padding: 80px 0 120px;

            &:before {
                width: 100vw;
                left: 0px;
            }
        }
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        font-weight: 600;
        color: $ebony;
        margin-bottom: 33px;

        @include media-breakpoint-up(lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
            margin-bottom: 56px;    
        }
        
        &--alt {

            @include media-breakpoint-up(lg) {
                margin-bottom: 60px;
            } 
        }
    }
}