/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

SITE NAVIGATION

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

SITE NAVIGATION

MAIN NAV STYLES
- Top level
- Sub level list
- Trifold layout modifier


HEADER NAV ONLY STYLES


FOOTER NAV ONLY STYLES


>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/





/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
MAIN NAV STYLES
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.main-nav {

	@include media-breakpoint-up(lg) {
		background: $white;
		padding: 0;
		display: flex;
		justify-content: space-between;
		width: 100%;
		min-height: 84px;
		height: auto;

		@include media-breakpoint-up(xl){
			background: transparent;
		}
	}

/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<
Top level list
>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/
	
	&__list {
		padding: 0;
		margin: 0;
		list-style-type: none;
		padding-top: 20px;
		background: $white;
		min-height: calc(100vh - 74px);

		@include media-breakpoint-up(lg){
			display: flex;
			width: 100%;
			padding: 0;
			background: none;
			min-height: auto;
		}

		@include media-breakpoint-up(xl){
			padding-right: 45px;
		}
	}

	&__space {
		display: flex;
		height: 20px;

		@include media-breakpoint-up(lg) {
			display: flex;
			flex-grow: 1;
		}
	}

	&__item {
		position: relative;
		flex-grow: 1;
		
		@include media-breakpoint-up(lg) {
			flex-grow: initial;
			display: flex;
			align-items: center;
		    padding: 0 24px;
			position: relative;
			position: static;
			transition: all .3s cubic-bezier(.57,1.08,.69,.8);
		}

		&.current {
			.main-nav__link {
				color: $navy;
			}
		}
	}

	&__item-drop {
		
		@include media-breakpoint-up(lg) {
		
			.main-nav__link {
				&:before {
					position: absolute;
					display: block;
					content: " ";
					width: 12px;
					height: 12px;
					right: -14px;
					transition: all .3s cubic-bezier(.57,1.08,.69,.8);
					background: url('/src/img/svg/icons_12px_arrow-nav-down-icon.svg') no-repeat center;
				}
	
				&:after {
					position: absolute;
					display: block;
					content: " ";
					width: 12px;
					height: 12px;
					right: -14px;
					transition: all .3s cubic-bezier(.57,1.08,.69,.8);
					background: url('/src/img/svg/icons_12px_arrow-nav-down-icon-blue.svg') no-repeat center;
					opacity: 0;
					display: none;
				}
			}

			&:hover {
				.main-nav__link {
					&:before {
						opacity: 0;
						display: none;
					}
		
					&:after {
						opacity: 1;
						display: block;
					}
				}
			}
		}	
	}

	&__link {
		display: block;
		padding: 12px 0;
		margin: 0 20px;
		@include fontSize(18);
		@include lineHeight(21,18);
		color: $ebony;
		font-weight: 500;
        transition: all .26s ease-in-out;
        text-decoration: none;


		&--alt {
			@include fontSize(15);
			@include lineHeight(19,15);
		}

		@include media-breakpoint-up(lg) {
			padding: 0;
			margin: 0;
			display: flex;
		    align-items: center;
		    height: 100%;
			position: relative;
			color: $storm-gray;

			.main-nav__item:hover & {
				color: $navy;
			}
		}
	}

	&__toggle {
		width: 24px;
		height: 50px;
		top: 0px;
		left: calc(100% - 60px);
		position: absolute;
		display: block;
		background: transparent;
		cursor: pointer;
		font: 0/0 a;
        border: none;
		outline: none;
		background: url('/src/img/svg/icons_12px_arrow-nav-right-icon.svg') no-repeat center;

		&:focus, 
		&:active {
            outline: none;
		}
		
		@include media-breakpoint-up(lg) {
			display: none;
		}
	}


/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<
Sub level list
>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

	&__submenu {
		display: none;
		padding: 0;
		position: fixed;
		top: 0px;
		width: 100%;
		z-index: 1;
		transform: translateX(100%);
		display: block;
		transition: all 0.3s ease;
		z-index: 100;

		&.is-open {
			transform: translateX(0);
		}

		@include media-breakpoint-up(lg) {
			display: none;
			position: absolute;
			min-width: 415px;
			min-height: 100%;
			top: 84px;
			left: 0%;
			padding: 0;
			max-width: 1980px;
			transform: translateX(0);
			background: $white;
			opacity: 0;
		}

		.main-nav__item:hover & {

			@include media-breakpoint-up(lg){
				display: block;
				width: 100%;
				opacity: 1;
			}
		}
	}

	&__block {
		background: $white;
		min-height: 100vh;
		
		@include media-breakpoint-up(lg){
			margin-left: 274px;
			min-height: 100%;
			//min-width: 244px;
			width: 100%;
			display: inline-block;
			position: relative;

			&:before {
				display: block;
				position: absolute;
				content:  " ";
				left: 0px;
				top: 0px;
				height: 1px;
				width: 100%;
				background: #d3d7dc;
			}
		}
	}

	&__sublist {
		list-style-type: none;
		padding: 0;
		position: relative;
		
		@include media-breakpoint-up(lg){
			padding: 0px;
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
			width: 244px;
			z-index: 1;
			background-color: $white;
		}
	}
	&__sublist-lv2 {
		
		@include media-breakpoint-up(lg){
			justify-content: flex-start;
			border-right: 1px solid #d3d7dc;
			height: 465px;
		}
	}

	/*za gumb natrag na mobu*/
	&__sublist-back {
		display: flex;
		justify-content: flex-end;
		height: 74px;
		background: $aqua-haze;
		padding-right: 25px;
	}
	/*za gumb natrag na proizvode */
	&__submenu-link {
		display: block;
		@include fontSize(14);
		@include lineHeight(17,14);
		color: $navy;
		font-weight: 500;
		text-decoration: underline;
		padding: 10px 0 ;
		margin: 13px 0 4px 0;
	}

	&__subitem {
		padding: 0 20px;
		position: relative;

		&--alt {
			@include fontSize(12);
			@include lineHeight(22,12);
			color: $storm-gray;
			font-weight: normal;

			@include media-breakpoint-up(lg){
				padding: 21px 0 4px 25px;
			}
		}
		
		@include media-breakpoint-up(lg){
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
			padding: 0 25px 0 25px;
			position: relative;	
		}
	}

	&__subitem-drop {
		

		&:hover {
		
		}

		@include media-breakpoint-up(lg){
			background: $white;

			&:before {
				position: absolute;
				display: block;
				content: " ";
				width: 12px;
				height: 12px;
				right: 27px;
				top: 50%;
				transform: translate(0px, -50%);
				transition: all .3s cubic-bezier(.57,1.08,.69,.8);
				background: url('/src/img/svg/icons_12px_arrow-nav-right-icon.svg') no-repeat center;
			}
	
			&:after {
				position: absolute;
				display: block;
				content: " ";
				width: 12px;
				height: 12px;
				right: 27px;
				top: 50%;
				transition: all .3s cubic-bezier(.57,1.08,.69,.8);
				background: url('/src/img/svg/icons_12px_arrow-nav-right-icon-blue.svg') no-repeat center;
				opacity: 0;
				display: none;
			}
			
			&:hover {
				&:before {
					opacity: 0;
					display: none;
				}
	
				&:after {
					opacity: 1;
					display: block;
				}
				> a {
					color: $navy;
				}
			}
		}
	}

	&__subitem-lv2 {
		@include media-breakpoint-up(lg){
			margin: 6px 0;
			&:first-child {
				margin-top: 20px;
			}

			&:hover {
				.main-nav__submenu-lv3 {
					transform: translateX(0px);
					opacity: 1;	
				}	
			}
		}
	}

	&__sublink {
		position: relative;
		display: block;
		@include fontSize(16);
		@include lineHeight(26,16);
		font-weight: 500;
		color: $ebony;
		padding: 8px 0;
		transition: all .26s ease-in-out;

		&:before {
			position: absolute;
			display: block;
			content: " ";
			width: 0%;
			height: 1px;
			bottom: 16px;
			left: 0px;
			transition: all .3s cubic-bezier(.57,1.08,.69,.8);
		}

		@include media-breakpoint-up(lg){
			padding: 16px 0;
			display: inline-block;

			&--lv2 {
				padding: 0px 0;
			}

			&--alt {
				@include fontSize(14);
				@include lineHeight(24,14);
				padding: 4px 0;
			}
		}

		.main-nav__subitem:not(.main-nav__subitem-drop):hover & {
			color: $navy;
		}
	}


/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<
Sub level list level 3
>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

	&__submenu-lv3 {
		display: none;
		padding: 0;
		position: fixed;
		top: 0px;
		left:0;
		width: 100%;
		z-index: 1;
		transform: translateX(100%);
		display: block;
		transition: all .3s cubic-bezier(.57,1.08,.69,.8);

		&.is-open {
			transform: translateX(0);	
		}
		
		@include media-breakpoint-up(lg){
			z-index: -1;
			top: 0px;
			left: 518px;
			transform: translateX(-269px);
			opacity: 0;
			min-width: 244px;
			height: 465px;
			box-shadow:  1px 0px 0 0 #d3d7dc;
			padding-top: 20px;
			width: 1px;
		}
	}

	&__block-lv3 {
		background: $white;
		min-height: 100vh;
		
		@include media-breakpoint-up(lg){
			min-height: 100%;
			min-width: 244px;
			display: inline-block;
		}
	}

	&__subitem-lv3 {

		@include media-breakpoint-up(lg){
			padding: 0 24px;

			&:hover {
				.main-nav__submenu-lv4 {
					display: block !important;
					transform: translateX(0px);
					margin-left: 1px;
					opacity: 1;
				}
			}
		}
	}


/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<
Sub level list level 4
>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/	

	&__submenu-lv4 {

		display: none;
		padding: 0;
		position: fixed;
		top: 0px;
		left:0;
		width: 100%;
		z-index: 1;
		transform: translateX(100%);
		display: block;
		transition: all .3s cubic-bezier(.57,1.08,.69,.8);

		&.is-open {
			transform: translateX(0);
		}
		
		@include media-breakpoint-up(lg){
			z-index: -1;
			top: 20px;
			left: 244px;
			height: auto;
			transform: translateX(-513px);
			opacity: 0;
			min-width: 244px;
		}
	}

	&__block-lv4 {
		background: $white;
		min-height: 100vh;
		
		@include media-breakpoint-up(lg){
			min-height: 100%;
			min-width: 244px;
			display: inline-block;
		}
	}

	&__subitem-lv4 {
		
		@include media-breakpoint-up(lg){
		
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			justify-content: center;
			padding: 0 25px 0 25px;
			position: relative;

			&:before {
				display:none;
			}
	
			&:after {
				display: none;
			}


			&:hover {
				&:after {
					display: none;
				}
				> a {
					color: $navy;
				}
			}
		}
	}	
}
@include media-breakpoint-up(lg){
	.main-nav--submenu-p {
		height: auto;
		min-height: auto;
		left: auto;

		&:before {
			content: " ";
			top: 0;
			left: -50vw;
			width: 100%;
			height: 100%;
			position: absolute;
			display: block;
			background: $white;
		}
		
		.main-nav__block {
			margin-left: 0px;
			min-height: 100%;
			width: 100%;
			display: inline-block;
			position: relative;
		}

		.main-nav__sublist {
			background: $white;

			&:before {
				content: " ";
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				display: block;
				background: $white;
			}
		}

		.main-nav__submenu-lv3 {
			left: 0px;
			transform: translateX(0px);
		}

		.main-nav__subitem-lv2:hover {
			.main-nav__submenu-lv3 {
				background: $white;
				opacity: 1;
				transform: translateX(244px);
			}
		}	
		.main-nav__submenu-lv3 {
			top: 1px;
			transition-delay: 0.3s;
			&:before {
				content: " ";
				top: 0;
				right: -1px;
				width: 1px;
				height: 100%;
				position: absolute;
				display: block;
				background: #d3d7dc;
				z-index: 10;
			}
		}
		.main-nav__submenu-lv4 {
			transition-delay: 0.3s;
		}

	}

	.main-nav--submenu-s {
		height: auto;
		min-height: auto;
		left: auto;
		width: auto !important;
		min-width: 189px;
		margin-left: -24px;
		
		.main-nav__block {
			margin-left: 0;
			width: 100%;
			padding: 16px 20px 32px 7px;
		}

		.main-nav__sublist {
			width: auto;
		}
		.main-nav__block{
			&:before {
				display: none;
			}
		
		}
		.main-nav__sublist-lv2 {
			height: auto;
			border: none;
		}
	}
}