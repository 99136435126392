.master-article {
    background-color: $aqua-haze;
    padding: 48px 0 32px;

    @include media-breakpoint-up(lg) {
        padding: 72px 0 112px;
    }

    &__intro {
        margin-bottom: 64px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 55px;
            margin-top: 24px;
        }
    }

    &__intro p {
        @include fontSize(20);
        @include lineHeight(30,20);
        color: $ebony;
        font-weight: normal;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            @include fontSize(30);
            @include lineHeight(44,30);
        }
    }

    &__table {
        overflow-x: auto;
        margin: 40px -20px 40px 0;

        @include media-breakpoint-up(md) {
            margin: 72px 0;
        }
    }

    &__content p {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }

    img:not([class]) {
        width: 100%;
        height: 100%;
        margin: 56px 0;
        display: block;

        @include media-breakpoint-up(lg) {
            margin: 72px 0;
        }
    }
/*
    &__quote {
        position: relative;
        border-radius: 30px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        background-color: $white;
        padding: 32px 24px 52px;
        margin: 40px 0;

        &:before {
            position: absolute;
            display: block;
            content: " ";
            background: $white; 
            width: 18px;
            height: 9px;
            z-index: 1;
        }

        @include media-breakpoint-up(lg) {
            margin: 40px 0 48px;
            padding: 32px 24px 40px 16px;
        }
    }

    &__quote-media {
        display: inline-block;
        width: 60px;
        height: 60px;

        @include media-breakpoint-up(md) {
            position: absolute;
            width: 80px;
            height: 80px;
        }
    }

    &__quote-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__quote-label {
        display: inline-block;
        vertical-align: top;
        padding-left: 16px;
        
        @include media-breakpoint-up(md){
            padding-left: 114px;
        }
    }

    &__quote-description {
        margin-top: 24px;
        @include media-breakpoint-up(md){
            padding-left: 114px;
            margin-top: 0;
        }
    }

    &__quote-label p {
        @include fontSize(12);
        color: $ebony;
        line-height: normal;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg){
            @include fontSize(14);
            margin-bottom: 8px;
        }
    }

    &__quote-description p {
        @include fontSize(18);
        @include lineHeight(28, 18);
        color: $ebony;
        font-weight: normal;
        margin: 0;

        @include media-breakpoint-up(lg){
            @include fontSize(20);
            @include lineHeight(34,20);
        }
    }*/

    &__btn-share {
        margin-bottom: 8px;
        
        @include media-breakpoint-up(lg) {
            margin-top: 24px;
        }
    }

    ul {
        color: $storm-gray;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }

    h2:not([class])  {
        @include fontSize(16);
        @include lineHeight(26, 16);
        color: $ebony;
        font-weight: 500;
        margin: 56px 0 8px;

        @include media-breakpoint-up(lg){
            @include fontSize(20);
            @include lineHeight(30,20);
            margin: 72px 0 16px;
        }
    }
}



.partners-block {
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0 24px;

    @include media-breakpoint-up(lg){

    }

    &__item {
        position: relative;
        width: 33.3334%;

        &:before {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: " ";
            position: absolute;
            z-index: 2;

            border-right: 1px solid $aqua-haze;
            border-bottom: 1px solid $aqua-haze;
        }

        &:nth-child(3n) {
            &:before {
                border-right: none;
            }
        }
        &:last-child() {
            &:before {
                border-right: none;
                border-bottom: none;
            }
        }
       


        @include media-breakpoint-up(lg){
            width: 25%;

            &:nth-child(3n) {
                &:before {
                    border-right: 1px solid $aqua-haze;
                }
            }
            &:nth-child(4n) {
                &:before {
                    border-right: none;
                }
            }

            &:nth-last-child(-n+4) {
                &:before {
                    border-bottom: none;
                }
            }
        }

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }  
    }

    &__inner {
        img {
            position: absolute;
            margin: 0px !important;
        }
    }
}

blockquote {
    position: relative;
    border-radius: 30px;
    box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
    background-color: $white;
    padding: 32px 24px 52px;
    margin: 40px 0;

    &:before {
        position: absolute;
        display: block;
        content: " ";
        background: $white; 
        width: 18px;
        height: 9px;
        z-index: 1;
    }

    @include media-breakpoint-up(lg) {
        margin: 40px 0 48px;
        padding: 32px 24px 40px 16px;
    }

    img {
        display: inline-block !important;
        width: 60px !important;
        height: 60px !important;
        margin: 0 !important;
        object-fit: cover;

        @include media-breakpoint-up(md) {
            position: absolute;
            width: 80px;
            height: 80px;
        } 
    }

    span {
        display: inline-block;
        vertical-align: top;
        padding-left: 16px;
        @include fontSize(12);
        color: $ebony;
        line-height: normal;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 8px;
    
        @include media-breakpoint-up(md){
            padding-left: 114px;
        }

        @include media-breakpoint-up(lg){
            @include fontSize(14);
            margin-bottom: 8px;
        }
    }

    p {
        @include fontSize(18);
        @include lineHeight(28, 18);
        color: $ebony;
        font-weight: normal;
        margin: 0;
        margin-top: 24px;
        margin-bottom: 0 !important;
        
        @include media-breakpoint-up(md){
            padding-left: 114px;
            margin-top: 0;
            @include fontSize(20);
            @include lineHeight(34,20);
            margin-bottom: 0 !important;
        }
    }
}