.about-intro {

    padding: 60px 0;
    @include media-breakpoint-up(lg) {
        padding: 120px 0;
    }

    &__text {
        p {
            @include clearLast;
            @include fontSize(18);
            @include lineHeight(28,18);
            margin-bottom: 22px;

            @include media-breakpoint-up(lg) {
                @include fontSize(20);
                @include lineHeight(34,20);

            }
        }
    }
}
