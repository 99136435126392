/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Expertsblock

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.experts-block {
    display: block;
    width: 100%;

    &--p-c {
        padding: 72px 0 88px;
    }

    &--p-l {
        padding: 72px 20px 94px;

        @include media-breakpoint-up(lg){
            padding: 104px 0px 144px;
        }
    }

    &--s-l {
        padding: 72px 20px 80px;
    }

    &--s {
        background: $aqua-haze;
        padding: 72px 20px 80px;

        @include media-breakpoint-up(lg){
            padding: 73px 0px 79px;
        }
    }

    &--b-l {
        padding: 72px 20px 88px;

        @include media-breakpoint-up(lg){
            padding: 120px 0px 144px;
        }
    }
}