/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Category Tabs

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.category-tabs {
    padding: 56px 0 32px 0;

    @include media-breakpoint-up(lg) {
        padding: 32px 0 46px 0;
    }

    &__wrap {
        overflow: visible;
    }

    &__list {
        display: flex;
        position: relative;
    }

    &__item {
        position: relative;
        margin: 0 8px;
        width: 153px;
        min-width: 153px;
        padding-top: 6px;

        @include media-breakpoint-up(lg) {
            width: 178px;
            min-width: 178px;
        }

        &:before {
            position: absolute;
            display: block;
            content: " ";
            top: 0px;
            left: 0px;
            width: 100%;
            height: 2px;
            background-color: $storm-gray;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        }

        &:hover {
            .category-tabs__link {
                color: $navy;
            }
        }

        &:first-child {
            padding-left: 0px;
        }

        &.current {

            .category-tabs__link {
                color: $ebony; 
            }

            &:before {
                background-color: $navy;
            }
        }

        &--ful-width {
            width: auto;
            min-width: auto;
        }

        &--alt {
            width: auto;
            min-width: max-content;
    
            @include media-breakpoint-up(lg) {
                width: 178px;
                min-width: 178px;
            }
        }
    }

    &__link {
        position: relative;
        @include fontSize(14);
        @include lineHeight(24,14);
        margin-bottom: 0px;
        color: $storm-gray;
        font-weight: 500;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        
        &--alt {
            @include media-breakpoint-up(lg) {
                @include fontSize(16);
                @include lineHeight(26,16);
            }
        }
    }
}

.category-tabs--h {
    .category-tabs__item {
        &.swiper-slide-active {
            .category-tabs__link {
                color: $ebony; 
            }

            &:before {
                background-color: $navy;
            }
        }
    }
}
.category-tabs--s {
    .category-tabs__item {
        &.swiper-slide-active {
            .category-tabs__link {
                color: $ebony; 
            }

            &:before {
                background-color: $navy;
            }
        }
    }
}
