.servis-intro {
    padding: 56px 0 72px;

    @include media-breakpoint-up(lg) {
        padding: 105px 0 60px;
    }

    &__media {
        margin: 0;
        height: 240px;
        position: relative;
        overflow: hidden; 
        
        @include media-breakpoint-up(md) {
            height: auto;
            margin: 0 6%;

        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__description {
        margin: 14px 0 32px;

        @include media-breakpoint-up(lg) {
            margin: 15px 0 40px;
        }
    }

    &__description p {
        @include fontSize(18);
        @include lineHeight(28,18);

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(34,20);
        }
        
    }
}