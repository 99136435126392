/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Article block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.article-block {
    box-shadow: inset 0 1px 0 0 #d7dbe6;

    &:last-child {
        box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;
    }

    &--light {
        background: $aqua-haze;
    }

    &__wrap {
        padding: 72px 0 80px 0;

        @include media-breakpoint-up(lg){
            padding: 120px 0;
        }
    }

    &__media-wrap {
        @include media-breakpoint-up(lg){
            max-width: 280px;
        }
    }

    &__media {
        position: relative;
        display: block;
        height: 180px;
        width: 100%;
        background-color: $light-gray;
       // clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 10%, 25% 10%);

        @include media-breakpoint-up(lg){
            height: 333px;
        }

        &--alt {
            clip-path: none;
            
            &:after {
                position: absolute;
                display: block;
                content: " ";
                background: $white;
                width: 90px;
                height: 38px;
                z-index: 1;
                bottom: 0px;
                right: 0px;

                @include media-breakpoint-up(lg){
                    width: 108px;
                    height: 45px;
                }
            }
        }
        &--alt-light {
           
            &:after {
                background: $aqua-haze; 
            }
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        margin: 32px 0 24px 0;

        @include media-breakpoint-up(lg){
            @include fontSize(32);
            @include lineHeight(42,32);
            margin: 0 0 32px 0;
        }
    }

    &__link {
        color: $ebony;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

        &:hover {
            color: $navy;
        }
    }

    &__abstract p {
        @include fontSize(16);
        @include lineHeight(28,16);
        margin-bottom: 37px;

        @include media-breakpoint-up(lg){
            @include fontSize(18);
            @include lineHeight(30,18);
            margin-bottom: 40px;
            padding-right: 20px;
        }
    }

    &__btn {
        width: 100%;
        margin-top: 33px;

        @include media-breakpoint-up(lg){
            width: auto;
            margin-top: 0px;
        }
    }
}


.square-img {
    width: 100%;
    height: 100%;
    position: relative;
   
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &__media {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 10%, 25% 10%);
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}