/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Hamburger button

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

.hamburger {
	-webkit-appearance: none !important;
	background: none;
	border: none;
	outline: none;
	position: relative;
	cursor: pointer;
	z-index: 1000;
	transition: all .25s cubic-bezier(.57,1.08,.69,.8);
	padding: 0;
	width: 32px;
	height: 74px;
	margin-left: 20px;

	.hamburger-inner {
		display: block;
		width: 32px;
		height: 50px;
		background: url("/src/img/svg/hambi-icon.svg") no-repeat center;
		transition: all .1s linear;
	}

	&__label {
		@include fontSize(14);
		@include lineHeight(24,14);
		position: absolute;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height: 1px; width: 1px;
		margin: -1px; padding: 0; border: 0;
	}

	&__label-close {
		display: none;
	}

	&:hover{
		.hamburger-inner {
			background: url("/src/img/svg/hambi-icon-blue.svg") no-repeat center;
		}
	}

	&.is-active {

		.hamburger-inner {
			background: url("/src/img/svg/close-icon-dark.svg") no-repeat center !important;
		}

		.hamburger__label {
			display: none;
		}

		.hamburger__label-close {
			display: block;
			position: absolute;
			overflow: hidden;
			clip: rect(0 0 0 0);
			height: 1px; width: 1px;
			margin: -1px; padding: 0; border: 0;
		}

		&:hover{
			.hamburger-inner {
				background: url("/src/img/svg/close-icon.svg") no-repeat center;
			}
		}
	}

	&:active, &:focus {
		outline: none;
	}
}
.site-header--light:not(.is-offset) {
	.hamburger-inner {
		background: url("/src/img/svg/hambi-icon-white.svg") no-repeat center;
	}
}
