/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Tusmo block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/



.tusmo-block {
    padding: 72px 0 80px;

    @include media-breakpoint-up(lg) {
        padding: 121px 0 144px;
    }

    &__wrap {
        position: relative;
        min-height: 648px;
        display: flex;
        align-items: center;
        width: 100%;

        @include media-breakpoint-up(lg) {
            min-height: 905px;
        }
    }
    &__grid-wrap {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        overflow: hidden;
        padding: 0 20px;

        @include media-breakpoint-up(lg) {
            padding: 0;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 66.667% 33.333%;
        grid-template-rows: 50% 50%;
        grid-template-areas:
        "topleft topright"
        "bottomleft bottomright";
        min-height: 100%;

        @include media-breakpoint-up(lg) {
            grid-template-columns: 58.333% 41.667%;
        }
    }
    &__grid-item {        
        
        &--top-left {
            grid-area: topleft;
        }

        &--top-right  {
            grid-area: topright;
            justify-self: end;
        }

        &--bottom-left{
            grid-area: bottomleft;
            align-self: end;
        }

        &--bottom-right {
            grid-area: bottomright;
            justify-self: end;
            align-self: end;
        }
    }

    &__item-fraction {
        background: url("/src/img/jpg/bg-tusmo.webp");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        
        &--top-left {
            position: relative;
            width: 137px;
            height: 101px;

            &:before {
                position: absolute;
                display: block;
                content: " ";
                right: 0px;
                bottom: 0px;
                width: 90px;
                height: 50px;
                background: $white;
            }

            @include media-breakpoint-up(lg) {
                width: 522px;
                height: 386px;
    
                &:before {
                    width: 360px;
                    height: 150px;
                }
            }
        }

        &--top-right {
            position: relative;
            width: 46px;
            height: 45px;
            margin-right: 27px;
            background-position: 46% 12%;

            @include media-breakpoint-up(lg) {
                width: 175px;
                height: 175px;
                margin-right: 105px;
            }
        }

        &--bottom-left {
            position: relative;
            width: 147px;
            height: 49px;
            margin-left: 26px;

            @include media-breakpoint-up(lg) {
                width: 557px;
                height: 186px;
                margin-left: 104px;
            }
        }

        &--bottom-right {
            position: relative;
            width: 100px;
            height: 129px;

            &:before {
                position: absolute;
                display: block;
                content: " ";
                right: 0px;
                bottom: 0px;
                width: 20px;
                height: 50px;
                background: $white;
            }
            &:after {
                position: absolute;
                display: block;
                content: " ";
                left: 0px;
                top: 0px;
                width: 30px;
                height: 48px;
                background: $white;
            }

            @include media-breakpoint-up(lg) {
                width: 384px;
                height: 483px;
    
                &:before {
                    width: 150px;
                    height: 150px;
                }
                &:after {
                    width: 228px;
                    height: 227px;
                }
            }
        }
    }

    &__grid-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: none;
    }

    &__inner {
        text-align: center;
    }

    &__title {
        @include fontSize(32);
        @include lineHeight(46,32);
        margin-bottom: 24px;
        color: $ebony;
        letter-spacing: -0.89px;
       
        @include media-breakpoint-up(lg) {
            @include fontSize(60);
            @include lineHeight(72,60);
            letter-spacing: -1px;
            margin-bottom: 30px;
        }
    }

    &__abstract p {
        @include fontSize(18);
        @include lineHeight(28,18);
        color: $storm-gray;
        font-weight: 400;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(34,20);
            margin-bottom: 37px;
        }
    }
}