/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Video block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.video-block {
    background: $white;
    padding: 72px 0 80px;
    box-shadow: inset 0 1px 0 0 $light-gray, inset 0 -1px 0 0 $light-gray;

    @include media-breakpoint-up(lg) {
        padding: 102px 0 90px;
    }
    
    &__media {
        width: 100%;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            height: 331px;
            margin-bottom: 0px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__title {
        @include fontSize(28);
        @include lineHeight(38,28);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
            margin-bottom: 32px;
            margin-top: 18px;
        }

    }

    &__description p {
        @include fontSize(18);
        @include lineHeight(28,18);
        color: $ebony;
        font-weight: normal;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(34,20);
        }
    }

    &__btn {

    }
}
