/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Faq

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.faq {
    position: relative;
    padding-bottom: 22px;
   
    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        margin-bottom: 16px;
        color: $ebony;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            @include fontSize(32);
            @include lineHeight(42,32);
        }
    }

    &__wrap {
        padding-bottom: 56px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 64px;
        }
    }

    &__item {
        position: relative;
        box-shadow: inset 0 -1px 0 0 #d7dbe6;

        &:first-child {
            box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;
        }

        &:before {
            position: absolute;
            display: block;
            content: " ";
            top: 16px;
            right: 16px;
            width: 24px;
            height: 24px;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
            background: url('/src/img/svg/icons_24px_plus-icon-blue.svg') no-repeat center;
        }

        &.is-open {
            background-color: $aqua-haze;
            
            &:before {
                transform: rotate(45deg);
            }
        }
    }

    &__item-label {
        display: block;
        position: relative;
        @include fontSize(14);
        @include lineHeight(24,14);
        font-weight: 500;
        color: $ebony;
        padding: 17px 16px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        margin-right: 25px;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
        }

        &:hover {
            color: $navy
        }
    }

    &__panel {
        display: none;
        padding: 0 16px 2px 16px;
    }

    &__panel p {
        @include fontSize(16);
        @include lineHeight(28,16);
        margin-bottom: 22px;
        font-weight: normal;
        color: $storm-gray;
    }
}

.faq-dark {
    background: $ebony;

    &:before {
        position: absolute;
        display: block;
        content: " ";
        background: $ebony;
        height: 100%;
        width: calc(100% + 40px);
        left: -20px;
        top: 0;
    }

    @include media-breakpoint-up(lg) {
        padding-left: 14.29%;

        &:before {
            width: 60vw;
            left: 0;
            top: 0;
        }
    }

    .faq__wrap {
        padding: 72px 0 66px 0;
    }

    .faq__item {
        box-shadow: inset 0 -1px 0 0 #6c7284;
       
        &:first-child {
            box-shadow: inset 0 1px 0 0 #6c7284, inset 0 -1px 0 0 #6c7284;
        }

        &:before {
            background: url('/src/img/svg/icons_24px_plus-icon-white.svg') no-repeat center;
        }

        &.is-open {
            background-color: $ebony;
        
        }
    }

    .faq__item-label {
        color: $white;
        @include fontSize(16);
        @include lineHeight(26,16);
        margin-bottom: 0px;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(30,20);
        }

        &:hover {
            color: $light-gray;
        }
    }

    .faq__panel p {
        @include fontSize(12);
        @include lineHeight(22,12);
        padding-top: 2px;

        @include media-breakpoint-up(lg) {
            @include fontSize(14);
            @include lineHeight(24,14);
        }

        a {
            color: $storm-gray;

            &:hover {
                color: $storm-gray;
                text-decoration: underline;
            }
        }
    }
}