/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Sidebar

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/



.sidebar {
    position: relative;
    margin: 40px 0 24px;
    height: 50px;
    z-index: 100;

    @include media-breakpoint-up(lg) {
        height: auto;
    }

    &.is-open {
        height: 100%;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);

        .sidebar__current-item {
            background: $white;
            color: $navy;
            border-radius: 0px;

            &:before {
                background: url("/src/img/svg/icons_12px_arrow-nav-up-icon-blue.svg") no-repeat center;
            }
        }
    }
  
    &__current-item {
        position: relative;
        padding: 14px 16px 12px 16px;
        cursor: pointer;
        background: $aqua-haze;
        border-radius: 15px;
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $ebony;
        font-weight: 500;

        &:before {
            width: 24px;
            height: 50px;
            top: 0px;
            right: 16px;
            position: absolute;
            display: block;
            background: transparent;
            cursor: pointer;
            content: " ";
            background: url("/src/img/svg/icons_12px_arrow-nav-down-icon.svg") no-repeat center;
        }

        @include media-breakpoint-up(lg) {
          /*  padding: 10px 16px;
            cursor: default;
            background: none;
            border-radius: 0px;
            @include fontSize(16);
            @include lineHeight(28,16);
            color: $navy;
            font-weight: normal;*/
            display: none;

            &:before {
                display: none;
            }
		}
    }

    &__list {
        display: none;
        position: absolute;
        width: 100%;
        z-index: 1;
        background: $white;
        border-radius: 0 0 15px 15px;
        padding: 30px 20px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        
        @include media-breakpoint-up(lg) {
            position: relative;
            display: block;
            background: none;
            margin-top: 0px;
            border-radius: 0px;
            box-shadow: none;
            padding: 0;
		}
    }

    &__item {
        position: relative;

        &.is-open {
            box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;
            background: $aqua-haze;

            .sidebar__link {
                color: $navy;
            }

            .sidebar__toggle {
                background: url("/src/img/svg/icons_12px_arrow-nav-up-icon-blue.svg") no-repeat center;
            }
        }

        &--alt {
            margin-top: 46px;
            padding-top: 16px;
            border-top: 1px solid $light-gray;
        }
    }

    &__link {
        display: block;
        padding: 10px 16px;
        @include fontSize(16);
        @include lineHeight(28,16);
		color: $storm-gray;
		font-weight: 400;
        transition: all .25s ease-in-out;
        text-decoration: none; 

        &:hover {
            color: $navy;
        }
    }

    &__label {
        @include fontSize(12);
        @include lineHeight(22,12);
        color: $storm-gray;
        padding: 0px 16px;
    }

    &__toggle {
        width: 24px;
        height: 50px;
        top: 0px;
        right: 16px;
        position: absolute;
        display: block;
        background: transparent;
        cursor: pointer;
        font: 0/0 a;
        border: none;
        outline: none;
        background: url("/src/img/svg/icons_12px_arrow-nav-down-icon.svg") no-repeat center;

        &:focus, 
		&:active {
            outline: none;
        }
        
        &--lv2 {
            width: 24px;
            height: 24px;
            top: 0px;
            right: 16px;
            position: absolute;
            display: block;
            background: transparent;
            cursor: pointer;
            font: 0/0 a;
            border: none;
            outline: none;
            background: url("/src/img/svg/icons_12px_arrow-nav-down-icon.svg") no-repeat center;
    
            &:focus, 
            &:active {
                outline: none;
            }
        }
    }


    &__submenu {
        display: none;
        padding: 0 0px 20px 0px; 

        &--lv3 {
            display: none;
            background: $white;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 rgba(0, 66, 137, 0.15);
            padding: 16px  0 16px;
            margin: 0 8px 10px;
        }
    }

    &__sublist {
      
    }

    &__subitem {
        position: relative;    

        &.is-open {
            background: $aqua-haze;

            .sidebar__submenu-link {
                color: $navy;
            }

            .sidebar__toggle--lv2 {
                background: url("/src/img/svg/icons_12px_arrow-nav-up-icon-blue.svg") no-repeat center;
            }
        }
    }

    &__submenu-link {
        display: block;
        @include fontSize(14);
        @include lineHeight(24,14);
		color: $storm-gray;
        font-weight: normal;
        padding: 0 16px 8px;
        transition: all .25s ease-in-out;

        &:hover {
            color: $navy;
        }
    }

    .current {
        color: $navy !important;
    }
}