.support-block {
    position:relative;
    background-color: $ebony;
    padding: 73px 0 55px;

    @include media-breakpoint-up (md) {
        padding: 120px 0;
    }

    &:before {
        content: " ";
        background: url(/src/img/svg/combined-shape.svg) no-repeat;
        display: block;
        width: 100%;
        height: 55px;
        position: absolute;
        left: 0;
        bottom: 0;
        overflow: hidden;


        @include media-breakpoint-up(lg) {
            content: " ";
            background: url(/src/img/png/deco.png) no-repeat top;
            display: block;
            width: 172px;
            height: calc(100% + 90px);
            position: absolute;
            left: unset;
            top: -90px;
            right: -12px;
            bottom: 0;
            z-index: 1;
            background-size: cover;
        }
    }

    &:after {
        content: " ";
        position: absolute;
        right: 0;
        top: -42px;
        overflow: hidden;
        width: 30%;
        height: 100px;
        background-color: $ebony;

        @include media-breakpoint-up(md) {
            width: 178px;
            top: -62px;
        }
    }

    &__media {
        position: relative;
        margin-bottom: 16px;
        height: 152px;

        @include media-breakpoint-up(md) {
            height: 220px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
        }
    }

    &__img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
        clip-path: polygon(46% 0, 100% 0, 100% 79%, 72% 79%, 72% 100%, 0 100%, 0 0);
    }

    &__card {
        background-color: $white;
        border-radius: 30px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        padding: 24px 24px 54px;
        margin-bottom: 24px;
        z-index: 1;
        position: relative;
        min-height: 406px;

        @include media-breakpoint-up(md) {
            max-width: 456px;
            width: 100%;
            margin: 0 auto;
        }

        @include media-breakpoint-up(lg) {
            padding: 32px;
            margin-bottom: 0;
            min-height: 510px;
        }
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
        }
    }

    &__description {
        margin-bottom: 16px;
    }

    &__description p {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $storm-gray;
        font-weight: normal;

        @include media-breakpoint-up(md) {
            @include fontSize(16);
            @include lineHeight(28,16);
        }
    }

    &__card-btn {
       /* display: flex;
        justify-content: flex-end;*/
        position: absolute;
        bottom: 24px;
        right: 24px;

        @include media-breakpoint-up(lg) {
            bottom: 40px;
            right: 40px;
        }
    }

    &__btn {
        margin: 0 -16px -16px 0;

        @include media-breakpoint-up(lg) {
            margin: 0 -8px -8px 0;
        }
    }

    &__card-wrapper {
        &:hover {
            .support-block__btn {
                background: $navy;
                border: 1px solid rgb(209, 222, 237);
                box-shadow: 0px 8px 16px 0px rgba(0, 66, 137, 0.3);
    
                &:before {
                    opacity: 0;
                }
    
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}