/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

SITE HEADER

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.site-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    transition: all 0.3s ease-in;

    @include media-breakpoint-up(lg) {

        &:before {
            display: block;
            position: absolute;
            content: " ";
            display: block;
            width: calc(50% - 1980px / 2);
            height: 84px;
            right: 0px;
            top: 0px;
            background: $ebony;
            z-index: -1;
        }
    }

    &__container {
        max-width: 1980px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        margin: 0 auto;
        height: 74px;
        background: $white;

        @include media-breakpoint-up(lg) {
            height: 84px;
            flex-wrap: nowrap;
            justify-content: flex-start;
        }
    }

    &__hamburger {

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__logo {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        top: 0px;
        height: 100%;
        width: 132px;
        display: block;
        background: url('/src/img/svg/logo-desktop.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: center;

        @include media-breakpoint-up(lg) {
            position: relative;
            display: flex;
            flex-shrink: 0;
            width: 176px;
            transform: translateX(0%);
            left: 0px;
            margin: 0 0px 0 10px;
        }

        @include media-breakpoint-up(xl) {
            margin: 0 64px 0 34px;
        }
    }

    &__logo-title {
        font: 0/0 a;
    }

    &__nav {
        display: none;
        order: 4;

        @include media-breakpoint-up(lg) {
            display: flex;
            order: 1;
        }
    }

    &__lang {

        order: 2;
        display: none;


        @include media-breakpoint-up(lg) {
            display: block;
            order: 2;

        }
    }

    &__search {
        order: 3;
        display: none;

        @include media-breakpoint-up(lg) {
           order: 3;
           display: block;
        }
    }

    &__cart {
        width: 64px;
        height: 74px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
        order: 1;

        @include media-breakpoint-up(lg) {
            background: $ebony;
            height: 84px;
            position: relative;
            order: 4;
        }
    }

    &__cart-icon {
        @include media-breakpoint-up(lg) {
            path {
                fill: $white;
            }
        }
    }

    &__cart-ammount {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: $yellow;
        color: $ebony;
        @include fontSize(8);
		@include lineHeight(14,8);
        font-weight: normal;
        text-align: center;
        position: absolute;
        top: 22px;
        right: 23px;

        @include media-breakpoint-up(lg) {
            top: 26px;
            right: 23px;
        }
    }
}

.site-header--light:not(.is-offset) {
    position: fixed;

    .site-header__container {
        transition: all 0.3s ease-in;
        background: none;
    }

    .site-header__logo {
        background: url("/src/img/svg/logo-desktop-inverted.svg") no-repeat center;
    }

    .site-header__nav {
        background: transparent !important;
    }

    .site-header__cart-icon {

        path {
            fill: $white;
        }
    }

    .main-nav__link {
        &:before {
            background: url('/src/img/svg/icons_12px_arrow-nav-down-icon-white.svg') no-repeat center;
        }
        @include media-breakpoint-up(lg) {
            color: $white !important;
        }
    }

    &:hover {
        .main-nav__link {
            &:before {
                background: url('/src/img/svg/icons_12px_arrow-nav-down-icon.svg') no-repeat center;
            }
        }

        @include media-breakpoint-up(lg) {

            .site-header__container {
                background: white !important;
            }
            .site-header__logo {
                background: url("/src/img/svg/logo-desktop.svg") no-repeat center;
            }
            .main-nav__link {
                color: $ebony !important;
            }
        }
    }
}

.site-header {
    &.is-fixed {

    }
    &.is-pinned {
        transform: translateX(0) translateY(0);
    }

    &.is-not-pinned {
       transform: translateY(-100%);
    }

    &.is-visited {

        &:after {
            display: block;
            position: absolute;
            content: " ";
            width: 100vw;
            height: 100vh;
            right: 0px;
            top: 0px;
            z-index: -1;
            background: rgba(8, 14, 28, 0.4);
            animation-name: slow-visibility;
			animation-duration: 0.3s;
			animation-timing-function: ease-in-out;
        }
    }

    &.is-active {
        .site-header__container {
            background: $aqua-haze;
        }

        .site-header__logo,
        .site-header__cart {
            display: none;
        }

        .site-header__lang {
            display: block;
        }
        .site-header__search {
            display: block;
            width: 100%;
        }

        .site-header__nav {
            display: block;
            width: 100%;
        }
    }
}

.desktop-search {

    &__activator {
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            border: none;
            height: 84px;
            font: 0/0 a;
            background: $ebony;
            padding: 0 10px;
            cursor: pointer;
        }
    }
    &__form {
        position: relative;
        padding: 16px 20px;
        background: $aqua-haze;

        @include media-breakpoint-up(lg) {
            display: none;
            width: 100%;
            left: 0;
            top: 84px;
            max-width: 2000px;
            padding: 12px 0;
            text-align: center;

            &.is-open {
                display: block;
                position: absolute;
                width: 100%;
                background: $white;
                box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15), inset 0 1px 0 0 $aqua-haze;
            }
        }
    }
    &__input {
        @include media-breakpoint-up(lg) {
            width: 40% !important;
        }
    }
    &__close {
        display: none;

        &.is-open {
            display: block;
            position: absolute;
            right: 16px;
            top: 93px;

            &:before {
                top: 50%;
                left: 50%;
                width: 18px;
                transform: translate(-50%, -50%);
            }
        }
    }
    &__submit {
        font: 0/0 a;
        position: absolute;
        right: 20px;
        border: none;
        bottom: 20px;
        width: 24px;
        height: 50px;
        cursor: pointer;
        background: transparent;

        @include media-breakpoint-up(lg) {
            display: block;
            right: calc(30% + 10px);
        }
    }
}
