/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Faq block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.faq-block {
    padding: 72px 0 0 0;

    @include media-breakpoint-up(lg) {
        padding: 120px 0 0px;
    }

    &__wrapper {
        
        @include media-breakpoint-up(lg) {
            display: flex;
        }
    }

    &__side {
        position: relative;

        @include media-breakpoint-up(lg) {
           width: 33.33333%;
           height: 100%;

           &.is-fixed {

           }
        }
    }

    &__label {
        margin-bottom: 38px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 48px;
        }
    }

    &__content {
        @include media-breakpoint-up(lg) {
            width: 66.66667%;
            padding-top: 82px;
        }
    }
}

.sidemenu {
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    transition: transform 250ms ease-in-out;
    transform: translateY(100%);
    
    &.active{
		transform: translateY(0);
		
		@include media-breakpoint-up(lg) {
			transform: none;
		}
	}
    
    @include media-breakpoint-up(lg) {
        position: relative;
        transform: translateY(0);
    } 

    &__tab {
        height: 55px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        padding: 0 20px;
        cursor: pointer;
        border-top: 1px solid $light-gray;
        z-index: 100;
        background: $white;

        &:after {
            display: block;
            position: relative;
            content: " ";
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid $light-gray;
            background-color: $white;
            background-image: url("/src/img/svg/icons_12px_arrow-nav-up-icon-blue.svg");
            background-repeat: no-repeat;
            background-position: center;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        }

        &:hover {
            .sidemenu__title {
                color: $navy
            }

            &:after {
                background-color: $navy;
                border: none;
                background-image: url("/src/img/svg/icons_12px_arrow-nav-up-icon-white.svg");
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &.is-active {

			&:after {
                background-color: $navy;
                background-image: url("/src/img/svg/icons_12px_arrow-nav-down-icon-white.svg");
                background-repeat: no-repeat;
                background-position: center;
                box-shadow: 0 8px 16px 0 rgba(0, 66, 137, 0.3);
			} 
			
			.sidemenu__title {
                color: $navy
			}
		}
   
        @include media-breakpoint-up(lg) {
            display: none;
        } 
    }

    &__title {
        @include fontSize(16);
        @include lineHeight(28,16);
        font-weight: 500;
        letter-spacing: normal;
        color: $ebony;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
    }

    &__wrap {
        display: none;
        position: absolute;
        bottom: 55px;
        width: 100%;
        background-color: $white;
        border-top: 1px solid $light-gray;
        padding: 0 20px;

        @include media-breakpoint-up(lg) {
            position: relative;
            display: block;
            bottom: 0;
            border-top: none;
            padding: 0;
        } 
    }

    &__list {
        padding: 23px 0 32px;
    }

    &__link {
        display: block;
        position: relative;
        @include fontSize(16);
        @include lineHeight(28,16);
        color: $storm-gray;
        font-weight: normal;
        padding: 8px 0;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

        &:before {
            position: relative;
            content: " ";
            width: 6px;
            height: 6px;
            background: none;
            display: inline-block;
            vertical-align: middle;
            margin-right: 0px;
            opacity: 0;
            transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
        }

        &.current,
        &:hover {
            color: $navy;

            &:before {
                opacity: 1;
                background: $ebony;
                margin-right: 6px;
            }
        }
    }
}