/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Lang menu

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/



.lang-menu {

    align-items: baseline;
    transition: all .3s cubic-bezier(.57,1.08,.69,.8);


    @include media-breakpoint-up(lg) {
        position: relative;
    }

    &.is-open {
        .lang-menu__button {
            background: $navy;

            @include media-breakpoint-up(lg) {
                background: $ebony;
            }

        }

        .lang-menu__drop {
            display: block;
            background: $ebony;
            width: 100%;
            position: absolute;
            top: 74px;
            left: 0px;
            z-index: 100;
        }

        .lang-menu__current {
            &:after {
                transform:  rotate(180deg);
                background: url("/src/img/svg/icons_12px_arrow-nav-down-icon-white.svg") no-repeat center;
            }
        }

        @include media-breakpoint-up(lg) {
            .lang-menu__drop {
                top: 84px;
                width: 166px;
                left: 10px;
            }
        }
    }

    &__button {
        display: block;
        @include fontSize(14);
        @include lineHeight(18,14);
        height: 100%;
        position: absolute;
        padding: 0 22px 0 24px;
        background: none;
        border: none;
        outline: none;
        background: $ebony;
        right: 0;
        width: 80px;

        @include media-breakpoint-up(lg) {
            position: relative;
            width: auto;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 10px;
        }

        &:hover,
        &:focus,
        &:active {
            outline: none;
            background: $navy;
        }
    }

    &__current {
        color: $white;
        display: block;
        @include fontSize(12);
        @include lineHeight(15,12);
        font-weight: normal;
        text-transform: uppercase;
        text-align: left;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            margin-left: 8px;
            transform-origin: center center;
            width: 9px;
            height: 15px;
            background: url("/src/img/svg/icons_12px_arrow-nav-down-icon-gray.svg") no-repeat center;
            transition: all .3s cubic-bezier(.57,1.08,.69,.8);
        }

        @include media-breakpoint-up(xl) {
            .lang-menu:hover & {
                &:after {
                    transform:  rotate(180deg);
                    background: url("/src/img/svg/icons_12px_arrow-nav-down-icon-white.svg") no-repeat center;
                }
            }
        }
    }

    &__drop {
        display: none;
        padding: 32px 0px 40px 0px;
        background: $ebony;
        min-width: 135px;
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: none;
            padding: 24px 0px 32px 0px;

            .lang-menu:hover & {
                display: block;
                position: absolute;
                top: 84px;
                width: 166px;
                left: 10px;
            }
        }
    }

    &__lang-list {
        list-style-type: none;
        padding: 0;
        margin: 0;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &__lang-link {
        display: block;
        color: $storm-gray;
        padding: 8px 20px;
        @include fontSize(14);
        @include lineHeight(24,14);

        @include media-breakpoint-up(lg) {
            padding: 8px 25px;
        }



        &:hover {
            color: $white;
        }

        &.is-current {
            pointer-events: none;
            color: $white;
        }
    }

    &__code-short {
        display: none;
    }

    &__code-long {
        display: block;
    }
}
