.solution-article {
    padding: 72px 0 78px;
    box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;

    @include media-breakpoint-up(lg) {
        padding: 120px 0 136px;
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        color: $colorSecondary;
        font-weight: 600;
        margin-bottom: 32px;

        @include media-breakpoint-up(lg) {
            @include fontSize(32);
            @include lineHeight(42,32);
            margin-bottom: 62px;
        }
    }

    &__media {
        position: relative;
        margin-bottom: 40px;
        width: 100%;

        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        clip-path: polygon(28% 0, 100% 0, 100% 60%, 100% 100%, 0 100%, 0 12%, -1% 12%);
    }

    &__abstract {
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 48px;
        }
    }

    &__abstract p {
        @include fontSize(20);
        @include lineHeight(30,20);
        color: $colorSecondary;
        font-weight: normal;

        @include media-breakpoint-up(lg) {
            @include fontSize(24);
            @include lineHeight(40,24);
        }
    }

    &__description p {
        @include fontSize(16);
        @include lineHeight(28,16);
        color: $storm-gray;
        font-weight: normal;

        @include media-breakpoint-up(lg) {
            @include fontSize(18);
            @include lineHeight(30,18);
        }
    }
}