/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

BUTTONS

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

/*
<<<<<<<<<<<<<<<<<<<<<
General
>>>>>>>>>>>>>>>>>>>>>
*/
button, [type="submit"], [type="reset"]{
	-webkit-appearence: none;
}

/*
<<<<<<<<<<<<<<<<<<<<<
Button
>>>>>>>>>>>>>>>>>>>>>
*/

.btn {
	position: relative;
    font-style: normal;
    font-weight: 500;
    display: inline-block;
    min-width: 20px;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
	border: none;
	transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
}


/*
<<<<<<<<<<<<<<<<<<<<<
Button primary
>>>>>>>>>>>>>>>>>>>>>
*/
.btn-primary {
	display: inline-block;
	@include fontSize(14);
	@include lineHeight(17,14);
	background: $ebony;
	color: $white;
	padding: 18px 32px 17px;
	min-height: 52px;
	box-shadow: 0px 8px 16px 0px rgba($ebony, 0.3);

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(20,16);
	}

	&:hover {
		background: $yellow;
		box-shadow: 0px 8px 16px 0px rgba($yellow, 0.5);
	}

	&--cart {
		&:after {
			content: " ";
			display: inline-block;
			width: 24px;
			height: 19px;
			margin-left: 8px;
			background: url('/src/img/svg/add-to-cart-icon.svg') no-repeat;	
			vertical-align: sub;
		}
	}
}


/*
<<<<<<<<<<<<<<<<<<<<<
Button secondary
>>>>>>>>>>>>>>>>>>>>>
*/

.btn-secondary {

	display: inline-block;
	@include fontSize(14);
	@include lineHeight(17,14);
	background: $navy;
	color: $white;
	padding: 18px 32px 17px;
	min-height: 52px;
	box-shadow: 0px 8px 16px 0px rgba($navy, 0.3);

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(20,16);
	}

	&:hover {
		background: $yellow;
		box-shadow: 0px 8px 16px 0px rgba($yellow, 0.5);
	}
}


/*
<<<<<<<<<<<<<<<<<<<<<
Button round
>>>>>>>>>>>>>>>>>>>>>
*/

.btn-round {
	position: relative;
	display: flex;
	align-items: center;
    justify-content: center;
	font-size: 0;
	width: 52px;
	height: 52px;
	border-radius: 50%;
	background: $white;
	border: 1px solid rgb(209, 222, 237);
	transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

	@include media-breakpoint-up(lg){
		width: 60px;
		height: 60px;
	}

	&:before {
		position: relative;
		display: block;
		content: " ";
		top: 0px;
		left: 0px;
		width: 18px;
		height: 20px;
		transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
		opacity: 1;
	}

	&:after {
		position: absolute;
		display: block;
		content: " ";
		top: 50%;
		right: 50%;
		transform: translate(50%, -50%);
		width: 18px;
		height: 20px;
		transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
		opacity: 0;
	}

	&:hover {
		background: $navy;
		border: 1px solid rgb(209, 222, 237);
		box-shadow: 0px 8px 16px 0px rgba(0, 66, 137, 0.3);

		&:before {
			opacity: 0;
		}

		&:after {
			opacity: 1;
		}
	}

	&--download {
		&:before {
			background: url('/src/img/svg/icons_24px_download-icon.svg') no-repeat center;
		}
	
		&:after {
			background: url('/src/img/svg/icons_24px_download-icon-white.svg') no-repeat center;	
		}
	}

	&--location {
		&:before {
			background: url('/src/img/svg/icons_24px_pin-icon.svg') no-repeat center;
			width: 16px;;
			height: 23px;
		}
	
		&:after {
			background: url('/src/img/svg/icons_24px_pin-icon-white.svg') no-repeat center;	
		}
	}

	&--next {
		&:before {
			background: url('/src/img/svg/right-arrow-icon.svg') no-repeat center;
		}
	
		&:after {
			background: url('/src/img/svg/right-arrow-icon-white.svg') no-repeat center;	
		}
	}

	&--to-cart {
		border: none;
		background: $ebony;
		box-shadow: 0 8px 16px 0 rgba(37, 41, 53, 0.3);
		
		&:after {
			opacity: 1;
			width: 24px;
			height: 19px;
			background: url('/src/img/svg/icons_24px_addtocart-icon.svg') no-repeat center;
		}
	
		&:before {
			display: none;	
		}

		&:hover {
			background: $yellow;
			border: none;
			box-shadow: 0 8px 16px 0 rgba(249, 177, 34, 0.5);

			&:after {
				opacity: 1;
			}
		
			&:before {
				display: none;	
			}
		}
	}

	&--close {
		font: 0/0 a;
		&:before {
			width: 21px;
			height: 21px;
			background: url('/src/img/svg/close-icon.svg') no-repeat center;
		}
	
		&:after {
			width: 21px;
			height: 21px;
			background: url('/src/img/svg/close-icon-white.svg') no-repeat center;	
		}
	}

	&--share {
		&:before {
			background: url('/src/img/svg/share-icon-blue.svg') no-repeat center;
			width: 19px;
    		height: 22px;
		}
	
		&:after {
			background: url('/src/img/svg/share-icon-white.svg') no-repeat center;
			width: 19px;
    		height: 22px;	
		}
	}

	&--expand {
		&:before {
			background: url('/src/img/svg/expand-icon-blue.svg') no-repeat center;
			width: 19px;
    		height: 22px;
		}
	
		&:after {
			background: url('/src/img/svg/expand-icon-white.svg') no-repeat center;
			width: 19px;
    		height: 22px;	
		}
	}
}

/*
<<<<<<<<<<<<<<<<<<<<<
Button link
>>>>>>>>>>>>>>>>>>>>>
*/

.btn-link {
	position: relative;
	display: flex;
	align-items: center;
	@include fontSize(14);
	@include lineHeight(17,14);
	color: $ebony;
	font-weight: 500;
	letter-spacing: 0px;

	&__more-text {
		transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
		opacity: 1;
	}
	&__less-text {
		transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
		opacity: 0;
		position: absolute;
	}

	span:not([class]) {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		height: 30px;
		width: 30px;
		border: 1px solid rgb(209, 222, 237);
		border-radius: 17px;
		margin-left: 8px;
		transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

		&:before {
			position: relative;
			display: inline-block;
			content: " ";
			width: 8px;
			height: 10px;
			background: url('/src/img/svg/icons_12px_arrow-right-icon-blue.svg') no-repeat center;
			transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
			opacity: 1;
		}

		&:after{
			position: absolute;
			display: inline-block;
			content: " ";
			top: 50%;
			right: 50%;
			transform: translate(50%, -50%);
			width: 8px;
			height: 10px;
			background: url('/src/img/svg/icons_12px_arrow-right-icon-white.svg') no-repeat center;
			transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
			opacity: 0;
		}
	}

	&:hover {
		span:not([class]) {
			border: 1px solid rgb(209, 222, 237);
			background: $navy;
			border: 1px solid rgb(209, 222, 237);
			box-shadow: 0px 8px 16px 0px rgba(0, 66, 137, 0.3);

			&:before {
				opacity: 0;
			}
	
			&:after {
				opacity: 1;
			}
		}
	}

	/*&--more {
		span:not([class]) {
			&:before {
				background: url('/src/img/svg/plus-icon-blue.svg') no-repeat center;
			}

			&:after {
				background: url('/src/img/svg/plus-icon-white.svg') no-repeat center;
			}
		}
	}*/

	@include media-breakpoint-up(lg){
		@include fontSize(16);
		@include lineHeight(20,16);

		span:not([class]) {
			height: 34px;
			width: 34px;
		}
	}

	&--all {
		span:not([class]) {
			&:before {
				width: 12px;
				height: 10px;
				background: url('/src/img/svg/prikazisve-icon.svg') no-repeat center;
			}

			&:after{
				width: 12px;
				height: 10px;
				background: url('/src/img/svg/prikazisve-icon-white.svg') no-repeat center;
			}
		}
	}
	&--more {
		span:not([class]) {
			&:before {
				width: 10px;
				height: 10px;
				background: url('/src/img/svg/plus-icon.svg') no-repeat center;
			}

			&:after{
				width: 10px;
				height: 10px;
				background: url('/src/img/svg/plus-icon-white.svg') no-repeat center;
			}
		}
	}
	&--scroll {
		span:not([class]) {
			&:before {
				transform: rotate(90deg);
			}

			&:after{
				transform: translate(50%, -50%) rotate(90deg);
			}
		}
	}

	&--down {
		span:not([class]) {
			&:before {
				width: 10px;
				height: 6px;
				background: url('/src/img/svg/icons_12px_arrow-down-icon-blue.svg') no-repeat center;
			}

			&:after{
				width: 10px;
				height: 6px;
				background: url('/src/img/svg/icons_12px_arrow-nav-down-icon-white.svg') no-repeat center;
			}
		}
	}
}

/*
<<<<<<<<<<<<<<<<<<<<<
Button more
>>>>>>>>>>>>>>>>>>>>>
*/

.btn-more {
	position: relative;
	display: flex;
	align-items: center;
	@include fontSize(14);
	@include lineHeight(17,14);
	color: $storm-gray;
	font-weight: 500;

	&--white {
		color: $white;
	}

	span {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		height: 30px;
		width: 30px;
		border-radius: 17px;
		margin-right: 8px;
		background: $navy;
		box-shadow: 0px 8px 16px 0px rgba($navy, 0.3);
		transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

		&:before {
			position: relative;
			display: inline-block;
			content: " ";
			width: 8px;
			height: 10px;
			background: url('/src/img/svg/icons_12px_arrow-right-icon-white.svg') no-repeat center;
			transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
		}
	}

	&:hover {
	
		span {
			background: $yellow;
			box-shadow: 0px 8px 16px 0px rgba($yellow, 0.3);
		}	
	}

	&--back {
		span {
	
			&:before {
				background: url('/src/img/svg/icons_12px_arrow-left-icon-white.svg') no-repeat center;
			}
		}
	}
}
