.solution-block {

    &__wrapper {
        position: relative;
        padding: 56px 0 202px;

        @include media-breakpoint-up(md) {
            padding: 96px 0px 123px;
        }
    }

    &__label {
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 53px;
        }
    }

    &__content {

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    &__media {
        position: absolute;
        height: 246px;
        left: 50%;
        margin-left: -220px;
        bottom: -85px;

        @include media-breakpoint-up(md) {
            width: 40%;
            height: auto;
            left: auto;
            margin: 0;
            right: 0;
            top: 60px;
            bottom: auto;
            // top: 20px;

        }
        @include media-breakpoint-up(lg) {
            top: 60px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__description p {
        @include fontSize(20);
        @include lineHeight(30,20);
        color: $ebony;
        font-weight: normal;

        @include media-breakpoint-up(lg) {
            @include fontSize(24);
            @include lineHeight(40,24);
        }
    }

    &--alt {
        padding: 56px 0 20px;

        @include media-breakpoint-up(lg) {
            padding: 64px 0 36px;
        }

        .solution-block__media {
            position: static;
            width: auto;
            height: auto;
            margin-bottom: 20px;
            margin-left: unset;

            @include media-breakpoint-up(lg) {
                margin-bottom: 34px;
            }
        }

        .solution-block__img-logo {
            width: 98px;
            height: 40px;

            @include media-breakpoint-up(lg) {
                width: 110px;
                height: 43px;
            }
        }
        .solution-block__content {
            width: 100%;
            text-align: center;
            margin-bottom: 40px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 70px;
            }
        }
        .solution-block__label {
            display: inline-block;
            margin-bottom: 28px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 48px;
            }
        }
        .solution-block__media-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
        }
    }

}