/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Error block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/



.error-block {
    padding: 150px 0 200px;
    background-color: $aqua-haze;

    &__content {
        height: 100%;
        display: flex;
    }

    &__wrapper {
        margin: auto;
        width: 66.666%;

        @include media-breakpoint-up(lg) {
            width: 100%
        }
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        margin-bottom: 24px;

        @include media-breakpoint-up(md) {
            @include fontSize(40);
            @include lineHeight(50,40);
        }
    }

    &__text {
        @include fontSize(14);
        @include lineHeight(24,14);

        @include media-breakpoint-up(md) {
            @include fontSize(18);
            @include lineHeight(30,18);
            margin-bottom: 0;
        }

        @include media-breakpoint-up(lg) {
            width: 60%;
        }
    }

    &__img {
        position: relative;
        margin-bottom: 30px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    &__bg-img {
        margin: 0 -5% -5%;
    }

    &__404-img {
        position: absolute;
        width: 58%;
        left: 22%;
        top: 27%;
    }  

    &__redline-img {
        position: absolute;
        width: 80%;
        left: 10%; 
        top: 46%;
    }
}