/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Intro image

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.intro-image {
    position: relative;
    overflow: hidden;

    &:after {
        display: block;
        position: absolute;
        content:" ";
        right: 38px;
        bottom: 0;
        width: 230px;
        height: 40px;
        background: url("/src/img/svg/deco-m.svg") no-repeat center;
        z-index: 1;
        background-size: cover;

        @include media-breakpoint-up(lg) {
            right: 0px;
            width: 100%;
            height: 50px;
            background: url("/src/img/svg/deco-d.svg") no-repeat center;
            background-size: cover;
        }
    }

    &--alt {
        height: calc(100vh - 74px);

        @include media-breakpoint-up(lg) {
            height: calc(100vh - 83px);
        } 
    }

    &--top {
        margin-top: -74px;
       
        @include media-breakpoint-up(lg) {
            margin-top: -84px;
        } 
    }
    
    &__media {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: $ebony;
		background-size: cover;
		background-position: center;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
            background-color: rgba(8, 14, 28, 0.4);
			z-index: 1;
        }
    }

    &__img {
        position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;	
    }

    &__video {
        position: absolute;
		object-fit: cover;
		width: 100%;
		height: 100%;	
    }

    &__inner {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        &--alt {
            justify-content: flex-end;
            text-align: left;
            align-items: flex-start;
            padding-bottom: 120px;
            min-height: calc(100vh - 74px); 
    
            @include media-breakpoint-up(lg) { 
                padding-bottom: 171px;
                min-height: calc(100vh - 83px);
            }
        }

        &--code4wms {
            justify-content: center;
            text-align: left;
            align-items: flex-start;
            padding-bottom: 120px;
            min-height: calc(100vh - 74px); 
    
            @include media-breakpoint-up(lg) { 
                padding-bottom: 171px;
                min-height: calc(100vh - 83px);
            }
        }
    }

    &__inner-wrap {
        min-height: calc(100vh - 74px); 
        display: flex;
        align-items: flex-end;
        
        @include media-breakpoint-up(lg) { 
            padding-bottom: 142px;
            min-height: calc(100vh - 83px);
        }
    }

    &__title h2 {
        @include fontSize(32);
        @include lineHeight(46,32);
        font-weight: 600;     
        letter-spacing: -0.89px;
        color: $white;
        margin-bottom: 82px;
        margin-top: 140px;

        @include media-breakpoint-up(lg) { 
            @include fontSize(60);
            @include lineHeight(74,60);   
            letter-spacing: -1px;
            margin-bottom: 0px;
            margin-top: 0px;
        }
    }

    &__subtitle p {
        position: relative;
        @include fontSize(16);
        @include lineHeight(26,16);
        font-weight: 400;     
        color: $white;
        margin-bottom: 96px;
        padding: 0 40px;
        
        &:before {
            position: absolute;
            display: block;
            content: " ";
            width: 8px;
            height: 109px;
            top: 6px;
            left: 0px;
            background: url('/src/img/svg/barcode.svg') no-repeat center;
        }

        @include media-breakpoint-up(lg) { 
            padding-top: 110px;
            @include fontSize(20);
            @include lineHeight(34,20);   
            margin-left: 20px;
            margin-bottom: 0px;

            &:before {
                top: 116px;
            }
        }
    }

    &__text p {
        @include fontSize(20);
        @include lineHeight(30,20);
        color: $white;
        margin-bottom: 15px;
        font-weight: 400;

        @include media-breakpoint-up(lg) {
            @include fontSize(30);
            @include lineHeight(44,30);
            margin-bottom: 0px;
        }
        
        &--alt {
            margin-bottom: 11px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 32px;
            }
        }
    }

    &__text-alt h1,
    &__text-alt h2 {
        @include fontSize(28);
        @include lineHeight(38,28);
        color: $white;
        margin-bottom: 0px;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
        }
    }

    &__btn {
        margin-bottom: 13px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }
}