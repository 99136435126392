.search {
    &__results {
        padding-bottom: 88px;

        @include media-breakpoint-up(lg) {
            padding-bottom: 144px;
        }
    }
    
    &__empty {
        padding: 80px 0 180px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            padding: 130px 0 270px;
        }
    }

    &__icon {
        margin: 0 auto;
    }

    &__abstract p {
        @include fontSize(16);
	    @include lineHeight(26,16);
        color: $ebony; 
        font-weight: 500;
        max-width: 200px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            @include fontSize(20);
	        @include lineHeight(30,20);
            max-width: unset;
        }
    }
}