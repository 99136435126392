/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Features block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.features {
    
    &__label {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 54px;
        }
    }

    &__card {
        padding: 24px 16px 40px;
        border-radius: 15px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        background-color: $white;
        margin-bottom: 80px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 120px;
            width: calc(100% + 100px);
            margin-left: -50px;
            padding: 68px 51px 91px;
            border-radius: 30px;
        }
    }

    &__inner {
        display: flex;
        flex-wrap: wrap;
        
        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    &__media {
        display: none;
        
        @include media-breakpoint-up(lg) {
            display: block;
            width: 25%;
            overflow: hidden;
            position: relative;
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__items {
        position: relative;
        width: 100%;
        
        @include media-breakpoint-up(lg) {
            width: 25%;
            padding-top: 4px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: space-between;

            &:before {
                position: absolute;
                display: block;
                content: " ";
                top: 16px;
                left: 36px;
                width: 1px;
                height: calc(100% - 28px);
                background: $light-gray;
            }
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;

        &:last-child {
            .features__item-btn {
                margin-bottom: 0;
                
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0px;
                }
            }
        }
    }

    &__item-heading {
        width: 100%;
        position: relative;
    }

    &__item-btn {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &.current {
            div {
                color: $white;
                border: solid 8px $white;
                background-color: $navy; 
                box-shadow: 0 8px 16px 0 rgba(0, 66, 137, 0.15);
            } 
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0px;

            &.current {
                div {
                    margin: 0 16px 0px 0px; 
                    width: 72px;
                    height: 72px;
                    border: solid 16px $white;
                } 
            }
        }
    }

    &__item-btn-index {
        display: flex;
        align-items: center;
        justify-content: center;
        @include fontSize(12);
        @include lineHeight(40,12);
        color: $navy;
        width: 50px;
        height: 50px;
        margin-right: 16px;
        font-weight: 500;
        border-radius: 50%;
        text-align: center;
        border: solid 1px #d1deed;
        background-color: $white;

        @include media-breakpoint-up(lg) {
           margin: 11px 27px 11px 11px;
        }
    }

    &__item-title {
        @include fontSize(14);
        @include lineHeight(24,14);
        font-weight: 500;
        color: $ebony;
    }

    &__item-content {
        display: none;
        position: relative;
        padding: 25px 8px 0;
        
        &.is-open {
            display: block;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 100%;
            padding-left: 25%;

            display: none;
        }
    }

    &__item-index {
        @include fontSize(12);
        @include lineHeight(22,12);
        font-weight: normal;
        color: $storm-gray;
        position: absolute;
        top: 25px;
        left: 8px;

        @include media-breakpoint-up(lg) {
            left: -32px;
            top: 6px;
        }
    }

    &__item-title-alt {
        padding-left: 30px;
        @include fontSize(24);
        @include lineHeight(34,24);
        font-weight: 600;
        color: $ebony;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
            margin-bottom: 24px;
            padding-left: 0;
        }
    }

    &__item-abstract p {
        @include fontSize(14);
        @include lineHeight(24,14);
        font-weight: normal;
        color: $storm-gray;
        margin-bottom: 40px;
    }

    &__items-content {
        display: none;

        @include media-breakpoint-up(lg) {
           display: block;
           position: relative;
           width: 50%;
        }
    }
}