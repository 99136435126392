.tech-specification {
    padding: 72px 0 80px;
    box-shadow: inset 0 1px 0 0 $light-gray, inset 0 -1px 0 0 $light-gray;
    background-color: $white;

    @include media-breakpoint-up(md) {
        padding: 120px 0 120px;
    }

    &--alt {
        box-shadow: none;
        padding: 0;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        background-color: $white;
        border-top: 1px solid #d1deed;
        position: relative;
        
        &:before {
            content: ' ';
            width: 72px;
            height: 1px;
            background: $navy;
            position: absolute;
            left: 0;
            top: -1px;
        }
    }

    &__item-label {
        padding: 19px 0 9px;
        flex-basis: 100%;

        @include media-breakpoint-up(md) {
            flex-basis:33.33%;
            padding: 16px 0;
        }
    }

    &__item-label p,
    &__item-label h2 {
        @include fontSize(16);
        @include lineHeight(26,16);
        color: $ebony;
        font-weight: 500;

        @include media-breakpoint-up(lg) {
            @include fontSize(24);
            @include lineHeight(34,24);
            margin-bottom: 0;
        }
    }

    &__item-description {
        padding-bottom: 16px;
        flex-basis: 100%;

        @include media-breakpoint-up(md) {
            flex-basis:66.66%;
            padding: 21px 0 24px;
        }
    }

    &__item-description p {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $storm-gray;
        font-weight: normal;
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(28,16);
        }
    }

    &__list {
        flex-basis: 100%;
        padding: 32px 0 18px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @include media-breakpoint-up(md) {
            flex-basis:66.66%;
            padding: 37px 0 24px;
        }
    }

    &__list-item {
        text-align: center;
        flex-basis: 50%;
        margin-bottom: 22px;

        @include media-breakpoint-up(md) {
            flex-basis: auto;
        }
    }

    &__list-icon {
        display: inline-block;
        margin-bottom: 9px;

        &--android {
            width:40px;
            height: 50px;
            @include img-svg(android-icon);
            background-repeat: no-repeat;
        }

        &--ios {
            width:42px;
            height: 50px;
            @include img-svg(ios-icon);
            background-repeat: no-repeat;
        }

        &--windows {
            width:42px;
            height: 50px;
            @include img-svg(windows-icon);
            background-repeat: no-repeat;
        }

        &--wifi {
            width:50px;
            height: 50px;
            @include img-svg(wifi-icon);
            background-repeat: no-repeat;
        }
    }

    &__list-label {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $storm-gray;
        font-weight: normal;
        margin-bottom: 0px;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(28,16);
        }
    }

    &__btn {
        margin: 0 auto ;
        display: block;
        width: fit-content;

        @include media-breakpoint-up(md) {
            margin-top: 24px;
        }
    }

    &__table {
        padding-top: 24px;
        overflow-x: auto;
        margin-right: -20px;
        margin-bottom: 40px;
        

        @include media-breakpoint-up(md) {
            padding-top: 16px;
            margin-right: 0;
            margin-bottom: 48px;
        }

        table {
            margin-top: 0;
        }

        th {
            width: 25%;
        }
    }
}