/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Contact Form

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.contact-form {
    padding: 40px 0 80px;

    @include media-breakpoint-up(lg) {
        padding: 80px 0 70px;
    }

    &__title {
        @include fontSize(28);
        @include lineHeight(38,28);
        font-weight: 600;
        margin-bottom: 16px;
        color: $ebony;

        @include media-breakpoint-up(lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
            margin-bottom: 0px;
        }

        &--alt {
            @include fontSize(20);
            @include lineHeight(30,20);
            font-weight: 500;

            @include media-breakpoint-up(lg) {
                @include fontSize(24);
                @include lineHeight(34,24);
                margin-bottom: 56px;
            }
        }
    }

    &__footer {

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
        }
       
        label {
            margin-bottom: 16 !important;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0 !important;
            }
        }

        p {
            @include fontSize(14);
            @include lineHeight(24,14);
            padding-right: 30px;
        }
    }

    &__btn-wrap {
        margin-top: 40px;
        
        @include media-breakpoint-up(lg) {
            margin-top: 0px;
        }
    }

    &__btn {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: auto;
        }
    }
}

.contact-form-service {
    padding: 72px 0 80px;
    box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;

    @include media-breakpoint-up(lg) {
        padding: 120px 0 120px;
    }
}

.contact-form-support {
    padding: 0px 0 0px;

    @include media-breakpoint-up(lg) {
        padding: 0px 0 0px;
    }
}