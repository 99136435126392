/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Pagination

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.product-gallery {

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: row;
    }

    &__media {
        position: relative;
        margin-bottom: 16px;
        height: 321px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            flex-grow: 1;
            margin-bottom: 0px;
            height: 487px;
        }
    }

    &__media-icon {
        position: absolute;
        border-radius: 50%;
        top: 50%; right: 50%;
        transform: translate(50%,-50%);
    }

    &__media-link {
        display: none;
        height: 100%;
        text-align: center;
        position: relative;

        &.is-open {
            display: block;
        }
    }

    &__media-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
       
        &--alt {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }       
    }

    &__thumbnails {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @include media-breakpoint-up(md) {
            flex-direction: column;
            justify-content: flex-start;
            padding-left: 35px;
        }
    }

    &__thumb-link {
        width: 70px;
        height: 70px;
        box-shadow: inset 1px 1px 0 0 $light-gray, inset -1px -1px 0 0 $light-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontSize(16);
        @include lineHeight(28,16);
        color: $storm-gray;
        transition: all 250ms ease-in;

        @include media-breakpoint-up(md) {
            
        }

        &:hover {
            box-shadow: inset 2px 2px 0 0 $ebony, inset -2px -2px 0 0  $ebony;
        }

        &:not(:last-child) {
            margin-right: 8px;

            @include media-breakpoint-up(md) {
                margin-right: 0px;
                margin-bottom: 8px;
            }
        }
    }

    &__thumb-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        padding: 2px;
    }
}