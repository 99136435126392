/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Hero block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/



.hero {
    position: relative;
    min-height: 158px;
    padding: 56px 0;
    background: $aqua-haze;

    @include media-breakpoint-up(lg) {
        min-height: 308px;
        padding: 120px 0 114px 0;
    }

    &__inner-service {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;

        @include media-breakpoint-up(lg) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__title {
        @include fontSize(32);
        @include lineHeight(46,32);
        margin-bottom: 0px;
        color: $ebony;
        letter-spacing: -0.89px;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            @include fontSize(60);
            @include lineHeight(64,60);
            letter-spacing: -1px;
        }

        &--center {
            text-align: center;
        }
    }

    &__sidebox {
        @include media-breakpoint-up(lg) {
            position: absolute;
            width: 100%;
            z-index: 10;
        }

        &.hidden--mobile {
            @include media-breakpoint-up(lg) {
                width: auto;
                margin-right: 10px;
            } 
        }
    }

    &__deco {
        display: block;
        position: absolute;
        right: 58px;
        bottom: 0;
        width: 230px;
        height: 40px;
        background: url("/src/img/svg/deco-m.svg") no-repeat center;
        z-index: 1;
        background-size: cover;

        @include media-breakpoint-up(lg) {
            right: 0px;
            bottom: 0;
            width: 680px;
            height: 50px;
            background: url("/src/img/svg/deco-m.svg") no-repeat center;
        }

        &--dark {
            background: url("/src/img/svg/deco-m-dark.svg") no-repeat center;

            @include media-breakpoint-up(lg) {
                width: 100%;
                background: url("/src/img/svg/deco-d-dark.svg") no-repeat center;
            }
        }
    } 

    &__filter-label {
        @include fontSize(14);
        @include lineHeight(24,14);
        margin-bottom: 8px;
        color: $ebony;
        font-weight: 500;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
        }
    }

    &__filter {
        @include media-breakpoint-up(lg) {
            max-width: 350px;
            padding-right: 40px;
        }
    }

    &__sticky-contact,
    &__sticky-contact--products {
        opacity: 1;
        position: fixed;
        top: 82px;
        right: 0;
        z-index: 100;

        @include media-breakpoint-up(lg) {
            opacity: 0;
            transition: all 0.5s ease-in-out;
            top: 0;
            right: 0;
            transform: translateY(-92px);
            position: fixed;
    
            &.is-fixed {
                opacity: 1;
    
                z-index: 10;
                right: 0;
                // top: 100px;
                transform: translateY(92px);
            }
        }
    }
}

.hero-servis {
    padding: 56px 0 0 0;

    @include media-breakpoint-up(lg) {
        padding: 96px 0 0 0;
    }
    
    .hero__title {
        margin-bottom: 0px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 16px;
        }
    }

    .hero__sidebox {
        margin-bottom: -30px;
    }
}

.hero-news {
    padding: 56px 0 104px 0;

    @include media-breakpoint-up(lg) {
        padding: 120px 0 41px 0;
    }
    
    .hero__title {
        text-align: center;
        margin-bottom: 48px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 80px;
        }
    }
}

.hero-products {
    padding: 56px 0 40px;
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 82px 0 88px;
    }

    .hero__inner {
        position: relative;
        height: 100%;

        @include media-breakpoint-up(lg) {
            padding-top: 38px;
        }
    }

    .hero__btn {
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }

    .hero__title {
        margin-bottom: 32px;
        
        @include media-breakpoint-up(lg) {
            margin-bottom: 0px;
        }
    }   
}

.hero-contact {
    padding-bottom: 40px;

    @include media-breakpoint-up(lg) {
        padding-top: 64px;
    }
    
    .hero__title {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }
    }
}

.hero-slider {
    padding: 32px 0 40px 0;

    @include media-breakpoint-up(lg) {
        padding: 0px 0 40px 0;
    }

    &__label {
        display: block;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 24px;
        }
    } 

    &__slider {
        position: relative;
        margin-bottom: 16px;
    }

    &__slider-container {
        overflow: visible;

        @include media-breakpoint-up(lg) {
         overflow: hidden;
        }
    }

    &__slider-slide {
        width: 100%;
    }

    &__navigation {
        right: 24px;
        top: -50px;
    }

    &__card {
        position: relative;
        display: flex;
        background-color: $white;
        padding: 8px 16px 8px 8px;
        border-radius: 15px;
        box-shadow: 0 8px 32px 0 rgba(0, 66, 137, 0.15);

        @include media-breakpoint-up(lg) {
            padding: 24px;
            box-shadow: none;
        }
    }

    &__media {
        margin-right: 16px;
        width: 90px;
        height: 90px;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__title h3 {
        @include fontSize(14);
        @include lineHeight(24,14);
        margin-bottom: 0px;
        color: $ebony;
        font-weight: 500;
        padding-top: 8px;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
            padding-top: 0px;
        } 
    }

    &__link-wrap {
        display: flex;
        justify-content: flex-end;
    }
}

.hero-single {
    position: relative;
    background: $white;
    padding: 56px 0 80px;


    @include media-breakpoint-up(lg) {
        padding: 55px 0 134px;

        &:before {
            position: absolute;
            display: block;
            content: " ";
            width: 100%;
            height: 50px;
            background: $aqua-haze;
            bottom: 0; 
        }
    } 

    &__inner {
        @include media-breakpoint-up(lg) {
            padding-top: 103px;
        }
    }

    &__link {
        margin-bottom: 20px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }

    &__label p {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $storm-gray;
        font-weight: normal;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
            @include fontSize(14);
            @include lineHeight(24,14);
        }
    }

    &__title {
        @include fontSize(28);
        @include lineHeight(38,28);
        margin-bottom: 40px;
        color: $ebony;
        font-weight: 600;

        @include media-breakpoint-up(lg) {
            @include fontSize(40);
            @include lineHeight(50,40);
        }
    }
    &__btn {
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0px;
        }
    }
}

.hero-search {
    padding: 56px 0 96px;
    min-height: unset;

    @include media-breakpoint-up(md) {
    padding: 56px 0;

    }

    &__description {
        color: $navy;
        font-weight: 600;
    }

    &__form {
        position: relative;
    }

    &__submit {
        font: 0/0 a;
        position: absolute;
        right: 8px;
        border: none;
        bottom: 20px;
    }

    &__description,
    .hero__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        margin-bottom: 0;

        @include media-breakpoint-up(lg) {
            @include fontSize(32);
            @include lineHeight(42,32);
        }
    }

    .hero__inner {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }
}