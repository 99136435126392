/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Custom variables, mixins and classes:

- VARIABLES
-- Colors
- MIXINS
-- Gradient mixin
-- Clear last child
-- Clearfix
--- mixin clearfix
--- class clearfix
-- Mobile negative margin
-- Desktop negative margin
- CLASS
-- Text-alignement
-- Position
-- Only mobile display
-- Only desktop display

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/



/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
VARIABLES
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

/* CSS variables */
:root {

	/*
	<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
	Colors
	>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
	*/
	//--color-primary: #001832;
	//--color-secondary: #c6191b;
	//--color-brownish-grey: #6e6e6e;
	//--color-pale-grey: #f2f6fa;
	//--color-cod-gray: #1e1e1e;
	//--base-header-height: 66px;

}


/* Sass variables */
/* <<<< Colors >>>> */


$colorPrimary: #ffffff;
$colorSecondary: #252935;

$white: #ffffff;
$black: #000000;

$smalt: #004289;
$yellow:#f9b122;
$navy: #005dc0;
$ebony: #252935;
$storm-gray: #676e81;
$emerald: #4bc072;
$cerise-red: #dd2457;
$colonial-white: #ffe8ba;
$onahau: #cbe4ff;
$areo: #b5fbcc;
$pastel-pink: #ffcad8;
$aqua-haze: #f2f5f8;
$alto: #d9d9d9;
$overlay: #080e1c;
$light-gray: #d7dbe6;


/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
MIXINS
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

@mixin img-svg($name) {
	$url:"/src/img/svg/#{$name}.svg";
	background: url($url);
}

@mixin img-png($name) {
	$url:"/src/img/png/#{$name}.png";
	background: url($url);
}

@mixin img-jpg($name) {
	$url:"/src/img/jpg/#{$name}.jpg";
	background: url($url);
}




/* <<<< Gradient mixin >>>> */

@mixin linear-gradient($deg, $start-color, $stop-color) {
	background: -webkit-linear-gradient($deg, $start-color, $stop-color);
	background: -o-linear-gradient($deg, $start-color, $stop-color);
	background: linear-gradient($deg, $start-color, $stop-color);
}




/* <<<< CLear last child >>>> */

@mixin clearLast {
	&:last-child {
		margin-bottom: 0;
	}
}

/* <<<< Clearfix >>>> */

// mixin clearfix
@mixin clearfix {
	zoom: 1;
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

// class clearfix
.clearfix {
	@include clearfix;
}

/* <<<< Mobile negative margin >>>> */

@mixin mobileStretch {

	@include media-breakpoint-down(md){
		margin-left: -10px;
    	margin-right: -10px;
	}
}

/* <<<< Desktop negative margin >>>> */

@mixin desktopStretch {

	@include media-breakpoint-up(md){
		margin-left: -10px;
    	margin-right: -10px;
	}
}





/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
CLASS
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

/* <<<< Text-aligement >>>> */

.text-center{
	text-align: center;
}
.text-left{
	text-align: left;
}
.text-right{
	text-align: right;
}

/* <<<< Position >>>> */

.position-relative {
	position: relative
}
.position-fixed {
	position: fixed
}
.position-static {
	position: static
}
.position-absolute {
	position: absolute
}
