/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Support hero

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.support-hero {
    padding-top: 56px;
    background-color: $aqua-haze;
    position: relative;

    &:before {
        @include media-breakpoint-up(lg) {
            content: " ";
            background: url("/src/img/svg/vizual2.svg") no-repeat;
            display: block;
            width: 191px;
            height: 500px;
            position: absolute;
            left: 0;
            top: 209px;
        }
    }

    &:after {
        @include media-breakpoint-up(lg) {
            content: " ";
            background: url("/src/img/svg/vizual.svg") no-repeat;
            display: block;
            width: 191px;
            height: 500px;
            position: absolute;
            right: 0;
            top: 70px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-top: 120px;
    }

    &__title {
        @include fontSize(32);
        @include lineHeight(46,32);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 24px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            @include fontSize(60);
            @include lineHeight(74,60);
            margin-bottom: 30px;
        }

        &:after {
            content: " ";
            background: url("/src/img/svg/teamviewer.svg") no-repeat center;
            display: block;
            width: 100%;
            height: 10px;
            margin-top: 16px;

            @include media-breakpoint-up(lg) {
                margin-top: 24px;
            }
        }
    }

    &__description {
        margin-bottom: 46px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 77px;
        }
    }

    &__description p {
        @include fontSize(18);
        @include lineHeight(28,18);
        color: $ebony;
        font-weight: normal;
        margin-bottom: 0;
        text-align: center;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(34,20);
        }
    }

    &__description a {
        color: $navy;
    }

    &__card {
        background-color: $white;
        border-radius: 30px;
        box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
        padding: 32px;

        @include media-breakpoint-up(lg) {
            padding: 32px 73px;
        }
    }

    &__card-title p {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $ebony;
        font-weight: 500;
        margin-bottom: 24px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
            margin-bottom: 40px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style-type: none;
	    counter-reset: li;
    }

    &__list-item {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $ebony;
        font-weight: normal;
        margin-bottom: 24px;
        padding-left: 32px;
        position: relative;

        &:before {
            counter-increment: li;
            content: counter(li, decimal-leading-zero);
            font-weight: 500;
            position: absolute;
            top: 0;
            left: 0;
        }

        &--one:before {
            color: $navy;
        }

        &--two:before {
            color: $emerald;
        }

        &--three:before {
            color: $cerise-red;
        }

        &:last-child {
            margin-bottom: 32px;
        }

        @include media-breakpoint-up(md) {
            flex-basis: 30%;
            margin-bottom: 40px;
        }
    }

    &__btn {
        margin: 0 auto;
        display: block;
        width: fit-content;
    }
}