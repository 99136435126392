/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Download Card

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.download-card {

    &__wrap {
        display: block;
        width: 100%;
        height: inherit;
        position: relative;
        background: $white;
        border-radius: 15px;
        min-height: 155px;
        padding: 34px 16px;
        box-shadow: 0 4px 8px 0 rgba(0, 66, 137, 0.15);

        &:before {
            position: absolute;
            display: block;
            content: " ";
            top: 4px;
            left: 4px;
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: -1;
            border-radius: 15px;
            box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
            transition: all 0.3s ease-in-out;

            @include media-breakpoint-up(lg){
                top: 8px;
                left: 8px;
            }
        }

        @include media-breakpoint-up(lg){
            min-height: 186px;
            padding: 42px 25px;
        }

        &:hover {

            &:before { 
                @include media-breakpoint-up(lg){
                    top: 12px;
                    left: 12px;
                }
    
            }
            .download-card__btn {
                background: $navy;
                border: none;

                &:before {
                    opacity: 0;
                }
        
                &:after {
                    opacity: 1;
                }
            }
        }
    }

    &__label {
        display: block;
        @include fontSize(12);
        @include lineHeight(12,12);
        font-weight: 500;
        color: $navy;
        text-transform: uppercase;
        margin-bottom: 8px;

        @include media-breakpoint-up(lg){
            @include fontSize(14);
            @include lineHeight(14,14);
        }
    }

    &__title {
        @include fontSize(14);
        @include lineHeight(24,14);
        font-weight: 500;
        color: $ebony;
        margin-right: 57px;
        margin-bottom: 0;

        @include media-breakpoint-up(lg){
            @include fontSize(16);
            @include lineHeight(26,16);
            margin-right: 0px;
        }
    }

    &__btn-wrap {
        position: absolute;
        bottom: 8px;
        right: 8px;

        @include media-breakpoint-up(lg){
            bottom: 12px;
            right: 12px;
        }
    }

    &__btn {
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);
    } 
}