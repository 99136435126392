/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

SITE FOOTER

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/

.site-footer {
    background-color: $colorSecondary;

    &__nav-block {
        padding: 40px 0 43px;

        @include media-breakpoint-up(lg) {
            padding: 114px 0;
        }
    }

    &__copyright-block {
        padding: 24px 0;
        box-shadow: inset 0px 1px 0px 0px rgb(27, 30, 39);

        p {
            @include fontSize(12);
            @include lineHeight(22,12);
            margin-bottom: 0;

            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }

        a {
            color: $navy;
            transition: all .25s ease-in-out;

            &:hover {
                color: $storm-gray;
            }
        }
    }
}

.footer-nav {

    &__list {
        margin-bottom: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-height: 216px;
    }

    &__item {
        margin-bottom: 28px;

        @include media-breakpoint-up (md) {
                margin-bottom: 30px;
        }
    }

    &__link {
        color: $white;
        @include fontSize(16);
        @include lineHeight(26,16);
        transition: all .25s ease-in-out;

        @include media-breakpoint-up(md) {
            @include fontSize(20);
            @include lineHeight(24,20);
        }

        &:hover {
            color: $storm-gray;
        }
    }
}

.newsletter-block {
    margin-bottom: 48px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding-left: 8%;
    }

    &__title {
        color: $white;
        margin-bottom: 8px;
        @include fontSize(20);
        @include lineHeight(30,20);

        @include media-breakpoint-up(lg) {
            @include fontSize(24);
            @include lineHeight(34,24);
        }
    }

    &__description {
        @include fontSize(14);
        @include lineHeight(20,14);
        color: #868c9d;
        margin-bottom: 24px;
    }

    &__form {
        background-color: $white;
        padding: 32px 24px 40px; 
        border-radius: 15px;

        @include media-breakpoint-up(lg) {
            padding: 24px 48px 32px;
            border-radius: 30px;
        }
    }

    &__input-wrap {
        width: 100%;
        margin-right: 17px;
        display: inline-block;
        

        // @include media-breakpoint-up(lg) {
        //     width: 63%;
        // }

        @include media-breakpoint-up(xl) {
            width: 67%;
        }
    }

    &__btn {
        width: auto;
        margin: 0 auto;
        display: block;

        @include media-breakpoint-up(xl) {
            display: inline-block;
            vertical-align: top;
        }
    }

    input[type="text"] {
        @include media-breakpoint-up(lg) {
            margin-bottom: 8px;
        }
    }

    label[for="checkbox-1"] {
        color: $storm-gray;
        margin-bottom: 24px;

        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
        }
    }
}

.social-nav {
    margin-top: 12px;

    @include media-breakpoint-up(lg) {
        margin-top: 34px;
    }

    &__title {
        @include fontSize(14);
        @include lineHeight(19,14);
        color: #868c9d;
        margin-bottom: 16px;
        font-weight: normal;
    }

    &__list {
        display: flex;
        align-items: flex-end;
    }
    &__item {
        padding: 0 20px 5px 20px;

        &:first-child {
            padding-left: 0px;
        }
    }
}


.site-footer--alt {
    .site-footer__nav-block {
        padding: 16px 0 24px;

        @include media-breakpoint-up(lg) {
            padding: 17px 0 32px;
        }
    }


    .site-footer__copyright-block p:not(:last-of-type) {
        color: #fff;
    }

    .newsletter-block {
        padding-left: 0;
        margin-bottom: 0;

        &__title {
            margin-bottom: 16px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 24px;
            }
        }

        &__form {
            @include media-breakpoint-up(lg) {
                padding: 28px 32px 38px;
            }

            form {
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                align-items: flex-start;
                justify-content: space-between;
            }
        }

        &__input-wrap {
            width: 100%;
            margin-right: 0;

            @include media-breakpoint-up(sm) {
                width: 66%;
            }
        }

        label[for="checkbox-1"] {
            @include fontSize(12);
            @include lineHeight(24,12);
        }

        &__btn {
            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }

        &__description {
            @include fontSize(14);
            @include lineHeight(24,14);
        }
    }

    .social-nav {
        margin: 20px 0;

        @include media-breakpoint-up(lg) {
            margin: 18px 0 26px;
        }
    }

    .footer-nav {
        &__list {
            display: block;
            margin-bottom: 8px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        &__item {
            display: inline-block;
            margin-bottom: 24px;

            &:not(:last-of-type) {
                margin-right: 30px;
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        &__title {
            @include fontSize(12);
            @include lineHeight(22,12);
        }

        &__link {
            @include fontSize(14);
            @include lineHeight(17,14);
        }
    }


}

.location-block {
    &__title {
        color: $white;
        margin-bottom: 16px;
        @include fontSize(20);
        @include lineHeight(30,20);

        @include media-breakpoint-up(lg) {
            @include fontSize(24);
            @include lineHeight(34,24);
            margin-bottom: 24px;
        }

        span {
            color: #F9B122;
        }
    }

    &__address-wrap {

        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
        }
    }

    &__address {
        margin-bottom: 14px;

        @include media-breakpoint-up(sm) {
            width: 48%;
            margin-bottom: 0;
        }
    }

    &__address-title {
        color: #fff;
        @include fontSize(16);
        @include lineHeight(19,16);
        margin-bottom: 5px;
    }

    &__address-content {
        @include fontSize(14);
        @include lineHeight(24,14);
        margin-bottom: 0;
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

