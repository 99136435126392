/*!
 * Bootstrap Reboot & Grid v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */

@at-root {
  @-ms-viewport { width: device-width; } // stylelint-disable-line at-rule-no-vendor-prefix
}


@import "bootstrap/functions";
@import "bootstrap/variables";

@import "mixins/breakpoints";
@import "mixins/grid-framework";
@import "mixins/grid";
@import "mixins/transitions";
@import "mixins/fonts";
@import "mixins/svg";

@import "bootstrap/reboot";
@import "bootstrap/grid";

@import "utilities/display";
@import "utilities/flex";


/* end bootstrap stuff */

// Typo
@import "typo/font-face";
@import "typo/type";


// Layout
@import "app/custom";
@import "app/styleguide";
@import "app/buttons";
@import "app/forms";
@import "app/navigation";
@import "app/footer";
@import "app/header";
@import "app/layout";
@import "app/pages";
@import "utilities/photoswipe";
@import "utilities/swiper";
@import "app/tables";


// Components
@import "components/dropdown";
@import "components/hamburger";
@import "components/lang-menu";
@import "components/gdpr";
@import "components/contact-card";
@import "components/category-card";
@import "components/product-card";
@import "components/experts-card";
@import "components/breadcrumb";
@import "components/servis-intro";
@import "components/faq";
@import "components/intro-image";
@import "components/benefits";
@import "components/clients";
@import "components/brands";
@import "components/servis-center";
@import "components/category-tabs";
@import "components/box-icon";
//@import "components/cart";
@import "components/case-study";
@import "components/pager";
@import "components/article-card";
@import "components/download-card";
@import "components/product-gallery";

// animations
@import "components/animations";

// Blocks
@import "blocks/hero";
@import "blocks/contact-block";
@import "blocks/tusmo-block";
@import "blocks/article-block";
@import "blocks/error-block";
@import "blocks/contact-form";
@import "blocks/review";
@import "blocks/products-list";
@import "blocks/sidebar";
@import "blocks/error-block";
@import "blocks/news-block";
@import "blocks/solution-block";
@import "blocks/checkout-block";
@import "blocks/cart";
@import "blocks/category-block";
@import "blocks/msg-block";
@import "blocks/about-block";
@import "blocks/support-block";
@import "blocks/products-category";
@import "blocks/related";
@import "blocks/video-block";
@import "blocks/download";
@import "blocks/features";
@import "blocks/intro-block";
@import "blocks/solution-article";
@import "blocks/experts-block";
//@import "blocks/article-card-big";
@import "blocks/article-list";
@import "blocks/overlay";
@import "blocks/faq-block";
@import "blocks/tech-specification";
@import "blocks/master-article";
@import "blocks/process-block";
@import "blocks/support-hero";
@import "blocks/search";
@import "blocks/about-intro";
@import "blocks/about-partners";
@import "blocks/about-info-block";
