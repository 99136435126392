/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

About info block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.about-info-block {
    padding: 50px 0;
    box-shadow: inset 0 1px 0 0 $light-gray, inset 0 -1px 0 0 $light-gray;
    background-color: $aqua-haze;

    @include media-breakpoint-up(lg) {
        padding: 100px 0;
    }

    &__media {
        @include media-breakpoint-up(lg) {
            padding-right: 75px;
        }
    }

    &__img {
        width: 100%;
    }

    &__text {
        dt {
            @include fontSize(20);
            @include lineHeight(32,20);
            margin-bottom: 10px;
            color: $ebony;
        }
        dd {
            @include fontSize(16);
            @include lineHeight(28,16);
            color: $storm-gray;
            margin-bottom: 0;

            a {
                color: $navy;
            }
        }

        dd + dt {
            margin-top: 30px;
        }
    }
}
