

// GDPR prompt


// color variables
$c-1: #252935;	//base color for text
$c-2: #ffffff;	//secondary color for text
$c-3: #005dc0;	//accent color headings
$c-4: #005dc0;	//accent color for button backgrounds and links
$c-5: #005dc0;	//accent color for headings
$c-6: #f9b122;	//helper color
$c-7: #d9d9d9;  //border color

//width variables
$w-1: 400px;	//prompt width

//font variables
$f-1: 'Inter', sans-serif; //font 1
// $f-2: 'GothamRounded-Bold'; //font 2


//custom font if needed


//btns
$border-rad-1: 100px;



* {
	margin: 0;
	padding: 0;
}
ul,ol,dl,p {
	margin: 0;
	padding: 0;
	list-style: none;
}

#gdpr-overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,0.7);
	z-index: 1000;
}


#gdpr_toolbar.compact {
	width: auto;
	padding: 0;
	.gdpr-msg {
		right: 0;
		bottom: 0;
		padding: 0;
		margin: 0;
	}
	.gdpr-prompt__title span,
	.gdpr-prompt__ctrl,
	.gdpr-opt,
	.gdpr-prompt__msg {
		display: none;
	}
	.gdpr-prompt__title {
		padding: 0;
		line-height: 1;
		// width: 50px;
		// height: 50px;
	}


}

#gdpr_toolbar{

	.gdpr-prompt__ctrl{
		.x_submit_necessary{
			display: block;
		}
		.x_submit_selected{
			display: none;
		}
	}
	.gdpr-prompt__ctrl.active{
		.x_submit_necessary{
			display: none;
		}
		.x_submit_selected{
			display: block;
		}
	}
}

#gdpr_toolbar.hide {
	transform: translateY(100%);
	opacity: 0;
}




.gdpr-compact {
	position: fixed;
	z-index: 10;
	right: 0;
	bottom: 0;
	line-height: 1;
	display: inline-block;
	transform: translate3d(100%,100%,0);
	transition: all .2s cubic-bezier( 0.455,  0.030,  0.515,  0.955 );
	opacity: 0;




	&.show {
		transform: translate3d(0,0,0);
		opacity: 1;
	}

	&:before {
		content: "";
		width: 120px;
		height: 120px;
		position: fixed;
		right: -60px;
		bottom: -60px;
		transform: rotate(45deg);
		background: $c-3;
		transition: all .2s cubic-bezier( 0.455,  0.030,  0.515,  0.955 );
	}
	svg {
		position: relative;
		right: 12px;
		bottom: 12px;
		width: 24px;
		height: 24px;
		display: inline-block;
		fill: $c-2;
		transition: all .2s cubic-bezier( 0.455,  0.030,  0.515,  0.955 );
	}
	&:hover {
		&:before {
			background: $c-6;
		}
		
		svg {
			fill: $c-2;
		}
		
	}
}

.gdpr-prompt {
	//animation
	transition: all .2s cubic-bezier( 0.455,  0.030,  0.515,  0.955 );

	//visual
	color: $c-1;

	//typo
	font-size: 14px;
	font-size: 1.4rem;
	line-height: 1.43;
	font-family: $f-1;

	//position
	z-index: 10000;
	position: fixed;
	right: 0;
	bottom: 0;

	//layout
	width: $w-1;
	z-index: 1000;

	@media only screen and (max-width: 400px) { 
		width: 100%;
	}


	&__title {
		color: $c-1;
		font-family: $f-1;
		font-size: 14px;
		font-size: 1.4rem;
		line-height: normal;
		text-transform: uppercase;
        padding: 40px 40px 18px 40px;
        font-weight: bold;
        margin-bottom: 0;
		svg {
			display: none;
		}
	}
	
	&__msg {
		margin: 0;
        padding: 0 40px;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 20px;
		a {
			color: $c-3;
			font-family: $f-1;
			
		}
	}


	&__ctrl {
		
		padding: 30px 40px 20px 40px;
		display: flex;
		justify-content: space-between;
		background: $c-2;
		position: relative;

		@media only screen and (max-width: 400px) { 
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			padding: 10px 40px 10px 40px;
        }
        
        &.active {
            border-top: 1px solid $c-7;
        }
	}
	&__cta {
		color: $c-1;
		font-family: $f-1;
		font-size: 14px;
		font-size: 1.4rem;
		line-height: 1;
		padding: 17px 25px 15px 0;
		transition: all .2s ease-in;
		position: relative;
		@media only screen and (max-width: 400px) {  
			margin-bottom: 20px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 16px;
		    font-size: 1.6rem;
        }
		&:after,
		&:before {
			content: "";
			position: absolute;
			right: 10px;
			top: 50%;
			width: 2px;
			height: 7px;
			transform: translateY(-50%) rotate(-45deg);
			background: $c-1;
			transition: all .2s ease-in;
		}
		&:before { 
			transform: translateY(-50%) rotate(45deg);
			right: 14px;
		}
		&.active {
            color: $c-3;
			&:after,
			&:before { 
				background: $c-3;
				transform: translateY(-50%) rotate(-135deg);
			}
			&:before { 
				transform: translateY(-50%) rotate(135deg);
			}
		}
		&:hover {
			text-decoration: none;
			color: $c-3;
			&:after,
			&:before { 
				background: $c-3;
			}
		}
		&--btn {
			font-family: $f-1;
			background: $c-3;
			color: $c-2;
			// border: 2px solid $c-3;
			border-radius: $border-rad-1;
			padding: 15px 15px;
			transition: all .2s ease-in;
			&:hover {
				background: $c-6;
				color: $c-2;
			}
			&:after,
			&:before { 
				display: none;
			}
		}
	}
}

.gdpr-msg {
	transition: all .4s cubic-bezier( 0.455,  0.030,  0.515,  0.955 );
	position: relative;
	top: 0;
	z-index: 0;
	padding-bottom: 20px;
	background: $c-2;
	&.show {
		transform: translateY(100%);
	}
}
//gdpr options | overlay/sidebar
.gdpr-opt {
	padding: 40px 0;
	position: fixed;
	z-index: -1;
	right: 0;
	top: 0;
	height: 95vh;
	width: $w-1;
	background: $c-2;
	transition: all .4s cubic-bezier( 0.455,  0.030,  0.515,  0.955 );
	transform: translateY(100%);
	overflow-y: scroll;
	
	@media only screen and (max-width: 400px) { 
		width: 100%;
		overflow-y: scroll;
	}


	&.show {
		transform: translateY(0);
    }
    // &__intro {
    //     padding: 40px 0;
    //     border: 1px solid $c-7;
    //     border-right: none;
    //     background-color: $c-2;
    //     margin-bottom: 2px;
    // }
	&__title {
		padding: 0 40px;
		color: $c-1;
		font-family: $f-1;
		font-size: 14px;
        font-size: 1.4rem;
        font-weight: bold;
		line-height: 1.36;
        text-transform: uppercase;
        margin-bottom: 18px;
	}
	&__subtitle {
        padding: 0 40px;
        font-size: 14px;
        font-size: 1.4rem;
	}
	&__list {
		margin: 40px 0 0 0;
		background: $c-2;
        padding: 40px;
        border-top: 1px solid $c-7;
        border-right: none;
		li {
			font-size: 14px;
			font-size: 1.4rem;
			line-height: 1.43;
			padding-bottom: 20px;
			margin-bottom: 22px;
			border-bottom: 1px solid $c-7;
			&:last-child { 
				border: none; 
				padding-bottom: 0;
				margin-bottom: 0;
			}
		}
		strong {
			font-size: 16px;
			font-size: 1.6rem;
			font-family: $f-1;
			display: inline-block;
			width: 100%;
			margin-bottom: 15px;
		}
	}
	&__ctrl {
		display: flex;
		justify-content: space-between;
		padding: 0 40px;
		margin-top: 40px;
	}
	label {
        cursor: pointer;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: normal;
	}
}


/* The switch - the box around the slider */
.switch {
	margin-bottom: 10px;
	position: relative;
	display: inline-block;
	width: 36px;
	height: 18px;
}
/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 12px;
	width: 12px;
	left: 3px;
	bottom: 3px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
}
.slider:after {
	position: absolute;
	content: "Off";
	color: #ccc;
	left: 45px;
	top: -1px;
	transition: all .2s ease-in;
	// right: 0;
}
input:checked + .slider {
	background-color: $c-1;
}
input:checked + .slider:after {
	content: "On";
	color: $c-1;
}
input:focus + .slider {
	// box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
	transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
