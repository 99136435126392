.news-block {
    padding: 72px 0 80px;
    box-shadow: inset 0 1px 0 0 $light-gray, inset 0 -1px 0 0 $light-gray;

    @include media-breakpoint-up(lg) {
        padding: 120px 0;
        box-shadow: inset 0 1px 0 0 $light-gray;
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 35px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            @include fontSize(32);
            @include lineHeight(42,32);
            margin-bottom: 56px;
        }
    }

    &__wrap {
        @include media-breakpoint-up(lg) {
            display: flex;
            margin-left: -17px;
            width: calc(100% + 34px);
            margin-right: -17px;
        }
    }

    &__slider {
        position: relative;
    }

    &__item {
        @include media-breakpoint-up(lg) {
            width: 33.3334%;
        
            .article-card {
                margin-left: 17px;
                margin-right: 17px;
            }

            .article-card__media {
                height: 200px;
            }

            .article-card__img {
                object-fit: cover !important;
            }
        }
    }

    &__navigation {
        background: $white;
        z-index: 5;
        top: -4px;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    &__btn-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 24px;

        @include media-breakpoint-up(lg) {
            padding-top: 40px;
        }
    }
}