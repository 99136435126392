/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Cart block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/



.cart {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: none;
    
    &.is-open {
        display: flex;
        justify-content: flex-end;
        background-color: rgba(8, 14, 28, 0.4);
    } 
    
    &__btn-close {
        position: absolute;
        top: 11px;
        right: 24px;
    }
    
    &__wrapper {
        display: block;
        background-color: $white;
        height: 100%;
        overflow-y: scroll;
        width: 100%;
    
        @include media-breakpoint-up(lg) {
            width: 50%;
        }
    }

    &__heading {
        padding: 81px 20px 16px;
    
        @include media-breakpoint-up(lg) {
            padding: 48px 32px 20px;
        }

        &--alt {
            display: flex;
            justify-content: space-between;
            position: relative;
            padding: 0;
    
            @include media-breakpoint-up(lg) {
                padding: 0;
            }
        }
    }

    &__title {
        @include fontSize(24);
        @include lineHeight(34,24);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 0;
    
        @include media-breakpoint-up(lg) {
            @include fontSize(32);
            @include lineHeight(42,32);
            margin-bottom: 16px;
        }

        &--alt {
            position: relative;
            @include fontSize(16);
            @include lineHeight(26,16);
            color: $white;
            font-weight: 500;

            @include media-breakpoint-up(lg) {
                @include fontSize(20);
                @include lineHeight(30,20);
            }
        }
    }
    
    &__empty-msg {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 60px;
    
        @include media-breakpoint-up(lg) {
            padding-top: 126px;
        }
    }
    
    &__abstract {
    
        @include media-breakpoint-up(lg) {
            width: 47%;
        }
    }
    
    &__abstract p {
        @include fontSize(16);
        @include lineHeight(26,16);
        color: $ebony;
        font-weight: 500;
    
        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(30,20);
        }
    }
    
    &__icon {
        margin-bottom: 24px;
    }

    &__items {
        margin: 0 20px;
    }

    &__checkout {
        padding: 17px 0;

        @include media-breakpoint-up(lg) {
			padding: 0px;
		}
    }

    &__toggle {
        width: 26px;
		height: 26px;
		top: 0px;
		right: 0;
		position: absolute;
		display: block;
		background: transparent;
		cursor: pointer;
		font: 0/0 a;
        border: none;
		outline: none;
		background: url('/src/img/svg/icons_12px_arrow-nav-down-icon-blue.svg') no-repeat center;

		&:focus, 
		&:active {
            outline: none;
		}
		
		@include media-breakpoint-up(lg) {
			display: none;
        }
        
        &.is-open {
            transform: rotate(180deg);
        }
    }

    &__item {
        display: block;
        padding: 8px 16px 8px 8px;
        box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;
        background-color: $white;

        @include media-breakpoint-up(lg) {
            padding: 8px 16px;
        }

        &--alt {
            @include media-breakpoint-up(lg) {
                display: flex;
                margin-right: 36px;
            }
        }
    }

    &__item-inner {
        display: flex;
        align-items: center;
    }

    &__item-details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__item-media {
        display: block;
        width: 60px;
        height: 60px;
        margin-right: 16px;
        
        @include media-breakpoint-up(lg) {
            width: 80px;
            height: 80px;
        }
    }

    &__item-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__item-details {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $navy;
        font-weight: 500;
        flex: 1;

        @include media-breakpoint-up(lg) {
            @include fontSize(16);
            @include lineHeight(26,16);
        }
    }

    &__item-name {
        color: $ebony;

        &:hover {
            color: $navy;
        }
    }

    &__item-checkout-amount {
      
    }
    
    &__item-ammount-wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        padding-left: 76px;

        @include media-breakpoint-up(lg) {
            width: auto;
            padding-left: 20px;
            flex: 1;
            padding-top: 8px;
        }
    }

    &__item-ammount-group {
        display: flex;
        width: 100%;
        margin-bottom: 16px;

        @include media-breakpoint-up(lg) {
            width: unset;
            margin-bottom: 13px;
        }
    }
    &__item-ammount-btn{
        border: solid 1px #d1deed;
        background-color: $white;
        cursor: pointer;
		font: 0/0 a;
        outline: none;
        width: 52px;
        height: 36px;
        display: block;

        &:first-child {
            border-radius: 30px 0 0 30px; 
            margin-right: -1px;
        }
        &:last-child {
            border-radius: 0 30px 30px 0; 
            margin-left: -1px;
        }
    }
    input.cart__item-amount {
        width: 54px;
        height: 36px;
        background: $white;
        border: solid 1px #d1deed;
        @include fontSize(16);
        @include lineHeight(26,16);
        text-align: center;

        &:focus,
        &:active {
            outline: none;
        }

        @include media-breakpoint-up(lg) {
            padding: 5px;
        }
    }
    &__item-remove {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $navy;
        font-weight: normal;
    }
    
    &__footer {
        position: fixed;
        display: flex;
        justify-content: center;
        width: 100%;
        bottom: 0px;
        right: 0px;
        padding: 11px 16px;
        background: $aqua-haze;

        @include media-breakpoint-up(lg) {
            justify-content: flex-end;
            width: 50%;
            padding: 12px 16px;
        }
    }
}



.cart-added-item {
    position: fixed;
    top: 82px;
    right: 50%;
    z-index: 10;
    background: white;
    padding: 16px;
    transform: translate(50%, 0px);
    border-radius: 24px;
    box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);
    @include fontSize(14);
    @include lineHeight(20,14);
    color: $ebony;
    display: none;

    &__item {
        color: $navy;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $ebony;
        }
    }

    @include media-breakpoint-up(lg) {
        top: 92px;
    }
}