@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');



$titleFont: 'Inter', sans-serif;
$textFont: 'Inter', sans-serif;

@mixin fontPrimary {
	font-family: $titleFont;
}

@mixin fontSecondary {
	font-family: $textFont;
}

@mixin fontFace($fontFamily) {
	font-family: $fontFamily, Arial, sans-serif;
	font-weight: normal;
	font-style: normal;
}

@mixin fontSize($sizeValue) {
	font-size: $sizeValue + px;
	font-size: ($sizeValue / 10) + rem;
}

@mixin lineHeight($heightValue, $fontSize) {
	line-height: ($heightValue / $fontSize) + em;
}