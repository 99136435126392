.breadcrumb {
    padding: 14px 16px 12px;
    background-color: $ebony;
    box-shadow: inset 0 1px 0 0 #080e1c;

    &__item {
        @include fontSize(12);
        font-weight: normal;
        color: $white;
        display: inline-block;
        position: relative;
        margin-right: 16px;

        &:not(:last-child) {
            &:after {
                content: ' ';
                width: 4px;
                height: 4px;
                background: $storm-gray;
                position: absolute;
                right: -12px;
                top: 7px;
            }
        }
    }

    &__link {
        color: $storm-gray;
        transition: all .25s ease-in-out;

        &:hover {
            color: $onahau;
        }
    }
}