.intro-block {
    padding: 56px 0 70px;

    @include media-breakpoint-up(lg) {
        padding: 80px 0 90px;
    }

    &__abstract {
        margin-bottom: 56px;

        @include media-breakpoint-up(lg) {
            margin-top: 16px;
        }

        span {
        	color: #f5a623;
        }
    }

    &__abstract p {
        @include fontSize(20);
        @include lineHeight(30,20);
        color: $colorSecondary;
        font-weight: normal;

        @include media-breakpoint-up(md) {
            @include fontSize(30);
            @include lineHeight(44,30);
        }
    }

    &__description p {
        @include fontSize(14);
        @include lineHeight(24,14);
        color: $storm-gray;
        font-weight: normal;

        @include media-breakpoint-up(md) {
            @include fontSize(18);
            @include lineHeight(30,18);
        }

        img {
            width: 100%;
            height: auto;
            margin-bottom: 24px;
        }

        span {
        	color: #f5a623;
        }
    }

    &__description {
        &--small p {
            padding-bottom: 20px;

            &:last-of-type {
                padding-bottom: 0px;
            }
            @include media-breakpoint-up(md) {
                @include fontSize(16);
                @include lineHeight(28,16);
            }  
        }
    }

    &__btn-share {
        margin-bottom: 8px;

        @include media-breakpoint-up(lg) {
            margin-top: 19px;
            margin-bottom: 0;
        }
    }

    &__btn-more {
        margin-top: 32px;
    }

    &--product {
        background-color: $aqua-haze;
        padding: 48px 0 80px;

        @include media-breakpoint-up(lg) {
            padding: 80px 0 106px;
        }
    }

    &--product-abstract {
        margin-bottom: 64px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 56px;
        }
    } 
}