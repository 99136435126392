.servis-center {
    padding: 0px 0 48px;

    @include media-breakpoint-up(md) {
        padding: 0px 0 150px;
    }

    &__content {
        text-align: center;
    }

    &__title {
        @include fontSize(28);
	    @include lineHeight(38, 28);
	    color: $ebony;
	    font-weight: 600;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            @include fontSize(40);
		    @include lineHeight(50, 40);
            margin-bottom: 32px;
        }
    }

    &__abstract {
        @include media-breakpoint-up(lg) {
            width: 69%;
            margin: 0 auto;
        }
    }

    &__abstract p {
        @include fontSize(18);
        @include lineHeight(28,18);
        color: $ebony;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(34,20);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin: 48px 0px 0;

        @include media-breakpoint-up(md) {
            margin: 48px 0px 0;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 110px;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
        }
    }

    &__item {
        margin: 0 35px 32px;

        @include media-breakpoint-up(md) {
            margin: 0 5px 52px;
        }
    }

    &__media {
        position: relative;
        overflow: hidden; 
    }

    &__img {
       

        @include media-breakpoint-up(lg) {
            height: 100%;
            width: 100%;
            object-fit: contain;
            width: auto;
        }
    }
}