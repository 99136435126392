/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Category block

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.category-block {
    background: #f2f5f8;
    padding: 72px 0 80px;

    @include media-breakpoint-up(lg) {
        padding: 120px 0;
    }

    &--alt {
        padding: 61px 0 80px;
        box-shadow: inset 0 1px 0 0 #d7dbe6, inset 0 -1px 0 0 #d7dbe6;

        @include media-breakpoint-up(lg) {
            padding: 80px 0 120px;
        }
    }

    &__title {
        position: relative;
        @include fontSize(24);
        @include lineHeight(34,24);
        color: $ebony;
        font-weight: 600;
        margin-bottom: 32px;
        
        @include media-breakpoint-up(lg) {
            @include fontSize(32);
            @include lineHeight(42,32);
            margin-bottom: 0px;
        }

        &--alt {
            margin-bottom: 24px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 40px;
            }
        }
    }

    &__abstract p {
        @include fontSize(20);
        @include lineHeight(30,20);
        color: $ebony;
        font-weight: normal;

        @include media-breakpoint-up(lg) {
            @include fontSize(24);
            @include lineHeight(40,24);
        }
    }

    &__slider {
        position: relative;

        .category-link {
            width: calc(25% - 25px);
            margin-right: 34px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__navigation {
        position: absolute;
        right: 0px;
        top: -43px;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }
    }

    &__slider-container {
        overflow: visible;
    }

    &__content {
        padding: 8px 0 24px;
    }

    .js-category-content {
        opacity: 0;
        display: none;

        &.is-open {
            opacity: 1;
            display: block;
        }
    }

    &__content-title {
        @include fontSize(32);
        @include lineHeight(42,32);
    }
}


.block-card {
    background: $white;
    position: relative;
    padding: 46px 24px 40px;
    border-radius: 15px;
    box-shadow: 0 16px 32px 0 rgba(0, 66, 137, 0.15);

    @include media-breakpoint-up(lg) {
        padding: 48px 0 56px;
        border-radius: 30px;
    }
    
    @include media-breakpoint-up(xl) {
        margin-right: -50px;
        margin-left: -50px;
        width: calc(100% + 100px);
    }

    &__title {
        @include fontSize(20);
        @include lineHeight(30,20);
        color: $ebony;
        font-weight: 500;
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            @include fontSize(24);
            @include lineHeight(34,24);
            margin-top: 32px;
        }
    }

    &__abstract p {
        margin-bottom: 24px;

        @include media-breakpoint-up(lg) {
            margin-bottom: 32px;
        }
    }

    &__media {
        margin-top: 48px;
    //    min-height: 217px;
        width: 100%;
        position: relative;

        @include media-breakpoint-up(lg) {
         //   min-height: 314px;
            margin-top: 0px;
            width: 313px; 
        }
    }

    &__media-wrapper {
        position: relative;
        width: 100%;
        &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover; 
    }

    .js-category-content {
        display: none;
        opacity: 0;
        transition: all 0.3s cubic-bezier(.12,.71,.78,.6);

        &.is-open {
            display: flex;
            opacity: 1;
        }
    }
}