/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Pagination

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.pagination {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	
	&__wrapper {
		display: flex;
		width: 100%;
    	justify-content: center;
    	align-items: center;
		position: relative;
		height: 54px;
		padding: 0 42px;

		@include media-breakpoint-up(lg){
			height: 70px;
			padding: 0 70px;
			width: auto;
		}
	}

	&__direction {
		display: flex;
		align-items: center;
    	justify-content: center;
		position: absolute;
		width: 42px;
		height: 42px;
		cursor: pointer;

		@include media-breakpoint-up(lg){
			width: 70px;
			height: 70px;
		}
	
		&--previous {
			left: 0px;
	
			&:before {
				content: '';
				display: inline-block;
				width: 6px;
				height: 10px;
				background: url("/src/img/svg/icons_12px_arrow-left-icon.svg") no-repeat center;
			}

			&:hover {
				&:before {
					background: url("/src/img/svg/icons_12px_arrow-left-icon-blue.svg") no-repeat center;
				}
			}
		}

		&--next {
			right: 0px;
	
			&:after {
				content: '';
				display: inline-block;
				width: 6px;
				height: 10px;
				background: url("/src/img/svg/icons_12px_arrow-right-icon.svg") no-repeat center;
			}

			&:hover {
				&:after {
					background: url("/src/img/svg/icons_12px_arrow-right-icon-blue.svg") no-repeat center;
				}
			}
		}

		span {
			font: 0/0 a;
		}
	}

	&__numbers {
		display: flex;
		height: 70px;
	}

	&__item {
		font-size: 0;
		margin: 0 4px;
		display: flex;
		height: 100%;
		width: 70px;
		align-items: center;
		justify-content: center;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&__link,
	&__current {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;

		color: $storm-gray;
		display: block;
		position: relative;
		font-size: 16px;
		line-height: 28px;
		text-align: center;
		font-weight: normal;

		span {
			display: block;
			line-height: 38px;
		}

		&:hover {
			color: $white;
			background: $navy;
			border-radius: 35px;
			box-shadow: 0 8px 16px 0 rgba(0, 66, 137, 0.15);
		}
		&--dots {

			&:hover {
				color: $storm-gray;
				background: none;
				border-radius: 0px;
				box-shadow: none;
			}
		}
	}

	&__current {
		color: $white;
		background: $navy;
		border: solid 16px $white;
		border-radius: 35px;
		box-shadow: 0 8px 16px 0 rgba(0, 66, 137, 0.15);
		width: 70px;
		height: 70px;	
	}

	&__dropdown {
		display: inline-block;
		position: relative;
		cursor: pointer;
	}

	&__dropdown-current {
		@include fontSize(14);
        @include lineHeight(24,14);
		padding: 8px 30px 6px 16px;
		border-radius: 35px;
		box-shadow: 0 8px 16px 0 rgba(0, 66, 137, 0.15);
		border: solid 8px $white;
		background-color: $navy;
		font-weight: normal;
		display: inline-block;
		position: relative;
		cursor: pointer;

		.cur {
			color: $white;
		}
		.of {
			color: $white;	
		}
		.max {
			color: $white;
		}

		&:after {
			content: '';
			position: absolute;
			display: block;
			top: 17px;
			right: 16px;
			width: 6px;
			height: 6px;
			background: url("/src/img/svg/triangle-down.svg") no-repeat center;
		}
	}

	&__select {
		position: absolute;
		opacity: 0.0000001;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}
}