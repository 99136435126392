/*
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

Dropdown

>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
*/


.nvsSel {
	position: relative;

	@include media-breakpoint-up(md){ 
		flex: 1 1 auto;
	}

	&.open {
		
		ul {
			display: block;
		}
		
		.selectField {
            
            &:after {
				transform: rotate(180deg);
			}
		}
	}

	.selectField {
		display: block;
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;

		&:after {
			content: "";
			display: block;
			position: absolute;
			top: 50%;
			right: 16px;
		}
	}

	ul {
		display: none;
		list-style-type: none;
		margin: 0;
		padding: 0;
		width: 100%;
		z-index: 100;
		position: absolute;
		left: 0;
		top: 100%;
		max-height: 500px;
		overflow-y: auto;
	}

	li {
		padding: 0px !important;
		margin: 0 !important;

		&:before {
			display: none;
		}

		a, a:not([href]):not([tabindex])  {
			display: block;
			padding: 0;
			cursor: pointer;
			text-decoration: none;
			transition: color 250ms ease-in-out;
		}
	}	
}

.default-sel {
	
	.nvsSel {
		&.open {
			.selectField {
				border-color: $navy;
			
				&:after {
					background: url("/src/img/svg/icons_12px_arrow-down-icon-blue.svg") center no-repeat;
				}
	
				.sel-placeholder {
					color: $ebony;
				}
			}
		}
	}

	.sel-placeholder {
		color: #868c9d;
	}

	.selectField {
        height: 50px;
        @include fontSize(14);
        @include lineHeight(17,14);
        font-weight: 500;
        color: $ebony;
        border: none;
        outline: none;
        background: none;
        background-color: transparent;
        padding: 17px 0 16px;
        border-radius: 0;
        border-bottom: 2px solid $ebony;

		&:after {
			width: 8px;
			height: 16px;
			background: url("/src/img/svg/icons_12px_arrow-down-icon.svg") center no-repeat;
			top: calc(50% - 8px);
		}

		@include media-breakpoint-up(md){ 
			@include fontSize(16);
			@include lineHeight(26,16);
		}
	}

	ul {
		background: $white;
		padding: 16px 0;
        min-height: 200px;
        border-radius: 0px;
        box-shadow: 0px 16px 32px 0px rgba(0, 66, 137, 0.15);

		a, a:not([href]):not([tabindex])  {
            @include fontSize(12);
            @include lineHeight(22,12);
			color: $storm-gray;
			padding: 8px 14px;
			margin: 0 1px;
			background: $white;

			&:hover {
				color: $ebony;
			}

			@include media-breakpoint-up(md){ 
				@include fontSize(14);
            	@include lineHeight(24,14);
			}
		}
    }
    
    & + label {
        color: red;
    }
}

.hidden-label {
	position:absolute;
	left:-10000px;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
}