.msg-block {
    text-align: center;
    padding: 49px 0 88px;

    @include media-breakpoint-up(lg) {
        padding: 104px 0 151px;
    }

    &__title {
        margin-bottom: 24px;
    }

    &__description p {
        @include fontSize(18);
        @include lineHeight(28,18);
        color: $ebony;

        @include media-breakpoint-up(lg) {
            @include fontSize(20);
            @include lineHeight(34,20);
        }
    }

    &__icon {
        margin: 0 auto 24px;
    }
}