.brands {
    padding-bottom: 0px;

    @include media-breakpoint-up(lg) {
        padding-bottom: 0px;
    }

    &__wrapper {
        padding: 45px 32px 56px;
        background-color: $ebony;
        border-radius: 30px;

        @include media-breakpoint-up(xl) {
            padding: 48px 0 30px;
            margin: 0 -50px;
        }
    }

    &__label {
        @include media-breakpoint-up(xl) {
            margin-left: 74px;
        }
    }

    &__list {
        margin-top: 38px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &__item {
        flex-basis: 45%;
        margin: 0 5px 40px;

        @include media-breakpoint-up(lg) {
            flex-basis: 20%;
            margin: 0 0 24px;
        }
    }

    &__media {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;
    }
}